import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewEncapsulation,
  EventEmitter,
  Output
} from "@angular/core";

import { SharedService } from "../shared.service";
import {
  HeaderInfo,
  NavItem,
  MessageNotificationModel,
  UserDocumentNotificationModel,
  NotificationsModel
} from "../models";
import { Router } from "@angular/router";
import { CommonService } from "../../platform/modules/core/services";
import { Subscription } from "rxjs";
import { LoginUser } from "../../platform/modules/core/modals/loginUser.modal";
import { MatDialog } from "@angular/material";
import { ChangePasswordComponent } from "../../platform/modules/agency-portal/change-password/change-password.component";
import { ResponseModel } from "../../super-admin-portal/core/modals/common-model";
import * as moment from "moment";
import { AuthenticationService } from "src/app/platform/modules/auth/auth.service";
import { UsersService } from "src/app/platform/modules/agency-portal/users/users.service";
@Component({
  selector: "app-layout-header",
  templateUrl: "header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() headerInfo: HeaderInfo;
  @Output() eventChangePassword: EventEmitter<any> = new EventEmitter<any>();
  userInfo: any;
  userNavigations: NavItem[];
  photopath: any;
  userLocations: Array<any>;
  currentLocationId: number;
  subscription: Subscription;
  messageNotifications: Array<MessageNotificationModel> = [];
  documentNotifications: Array<UserDocumentNotificationModel> = [];
  notificationsURL = "api/Notification/GetHeaderNotification";
  changeMessageStatusURL = "api/Message/ChangeMessageStatus";
  passwordExpiryColorCode = "Red";
  totalUnreadNotificationCount: number;
  constructor(
    private sharedService: SharedService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UsersService
  ) {
    this.userLocations = [];
    this.userInfo = {};
    this.userNavigations = [];
    this.currentLocationId = null;
  }

  ngOnChanges(changes) {
    const headerInfo = changes.headerInfo || null;
    if (headerInfo && headerInfo.currentValue) {
      this.userInfo = headerInfo.currentValue.user;
      this.userLocations = headerInfo.currentValue.userLocations;
      this.userNavigations = headerInfo.currentValue.userNavigations;
      //this.photopath =headerInfo.currentValue.user.photoPath
      console.log("phtopath:", headerInfo.currentValue)
    }
    this.getStaffProfileData();
  }

  getStaffProfileData() {
    this.userService.getStaffProfileData(this.userInfo.id).subscribe((response: ResponseModel) => {
      if (response != null && response.data != null) {
        this.photopath = response.data.photoPath;
      }
    });
  }

  onSelectUserMenu(item: NavItem) {

    switch (item.route) {
      case "/web/sign-out":
        this.authenticationService.SetUserOffline();
        this.commonService.logout();
        sessionStorage.setItem('redirectTo', '/web/login-selection');//added code to redirect on we login

        // sessionStorage.setItem("redirectTo", "");
        // this.router.navigateByUrl('/web/login');//commented code to double load
        location.reload();//added code to reload
        break;
      case "":
        this.eventChangePassword.emit();
        break;
      default:
        item.route && this.router.navigate([item.route]);
        break;
    }
  }

  onDropdownSelectionChange(value: number) {
    this.commonService.updateCurrentLoginUserInfo(value);
    this.router.navigate(["/web"]);
  }

  ngOnInit() {
    this.commonService.currentLoginUserInfo.subscribe((user: any) => {
      if (user) {
        this.currentLocationId = user.currentLocationId;
      }
    });
    this.getHeaderNotifications();
  }

  toggleSidenav() {
    this.sharedService.toggle();
  }
  // getHeaderNotifications() {
  //   this.commonService
  //     .getAll(this.notificationsURL, {})
  //     .subscribe((response: ResponseModel) => {
  //       if (
  //         response &&
  //         response.data != undefined &&
  //         response.statusCode == 200
  //       ) {
  //         this.messageNotifications =
  //           response.data.messageNotification != undefined
  //             ? response.data.messageNotification
  //             : [];
  //         this.documentNotifications =
  //           response.data.userDocumentNotification != undefined
  //             ? response.data.userDocumentNotification
  //             : [];
  //       }
  //     });
  // }
  getHeaderNotifications() {
    this.commonService
      .getAll(this.notificationsURL, {}, false)
      .subscribe((response: ResponseModel) => {
        if (
          response &&
          response.data != undefined &&
          response.statusCode == 200
        ) {
          this.onReceiveNotification(response.data);
        }
      });
  }
  onReceiveNotification(notificationResponse: any) {
    this.totalUnreadNotificationCount = notificationResponse.unReadNotificationCount != undefined ?
      notificationResponse.unReadNotificationCount.totalUnReadNotification : 0;

    this.documentNotifications =
      notificationResponse.userDocumentNotification != undefined
        ? notificationResponse.userDocumentNotification
        : [];
    this.documentNotifications.forEach(v => {
      v.timeStamp = moment
        .utc(v.timeStamp)
        .local()
        .format("YYYY-MM-DD, h:mm a");
      switch (v.type) {
        case "UserInvitation":
          v.notificationAction = v.type;
          v.message = v.message;

          //  v.message = v.patientName + " has requested appointment at ";
          break;

        case "ChatMessage":
          v.message = v.message;
          break;
      }
    });
  }

  changeNotificationStatus(messageId: number) {
    this.commonService
      .patch(
        this.changeMessageStatusURL +
        "?MessageId=" +
        messageId +
        "&Unread=" +
        false,
        {}
      )
      .subscribe((response: ResponseModel) => {
        if (response != null && response.statusCode == 200) {
          this.getHeaderNotifications();
        }
      });
  }
  openMailbox(messageId: number = null, parentMessageId = null) {
    if (messageId != null) {
      this.router.navigate(["/web/mailbox"], {
        queryParams: {
          mId: this.commonService.encryptValue(messageId, true),
          pId:
            parentMessageId != null
              ? this.commonService.encryptValue(parentMessageId, true)
              : null
        }
      });
    } else this.router.navigate(["/web/mailbox"]);
  }
  openUser(userInfo: any) {
    this.router.navigate(["/web/manage-users/user-profile"]);
  }
}

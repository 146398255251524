export class FilterModel
{
    pageNumber:number=1;
    pageSize:number=5;
    sortColumn:string='';
    sortOrder:string='';
    searchText:string=''
}

export class ResponseModel
{
    data:any=[];
    statusCode:number
    message:string=''
    appError:string=''
    meta : Metadata
}
export class Metadata
{
    totalRecords:number
    currentPage:number
    pageSize :number
    defaultPageSize :number
    totalPages :number
    pageSizeOptions:number[] = []
}
export class PodcastModel 
{
    searchKey:string='';
    startWith:string = '';
    tags:string='';
    locationIDs:string='';
    roleIds:string = '';
    pageNumber:number=1;
    pageSize:number=5;
    sortColumn:string='';
    sortOrder:string='';
    compayId:number = 0;    
}

export class videotrackmodel
{
    forEach(arg0: (e: any) => void) {
      throw new Error('Method not implemented.');
    }
    MediadetailsId:number;
    endduration:string = '';
    AccessDate:Date;
}

import { int } from "aws-sdk/clients/datapipeline";

export class FilterModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
}
export class MediaFilterModel {
  pageNumber: number = 1;
  pageSize: number = 10;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  StartWith: string = '';
  CourseType: string = '';
  CategoryType: string = '';
  MonthId: int;
  Year: string = "";
  ModuleId: int;
  CourseId: int;
}

export class TrainingFilterModel {
  PlanType: string = "";
  CourseName: string = "";
  ModuleId: int;
  MonthId: int;
  PatientId: int;
}
export class ReportModel {
  pageNumber: number = 1;
  pageSize: number = 10;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
}


export class ResponseModel {
  data: any = [];
  statusCode: number;
  message: string = "";
  appError: string = "";
  meta: Metadata;
  timeInterval: any;
}
export class Metadata {
  totalRecords: number;
  currentPage: number;
  pageSize: number;
  defaultPageSize: number;
  totalPages: number;
  pageSizeOptions: number[] = [];
}

export class ProviderListFilterModel {
  pageNumber: number = 1;
  pageSize: number = 100;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
}

export class ProviderSearchFilterModel extends ProviderListFilterModel {
  Date: string;
  Locations: string;
  Taxonomies: string;
  // Genders: string;
  Gender: string;
  Specialities: string;
  Services: string;
  Rates: string;

  MinRate: string;

  ReviewRating: string;
  SortType: string;
  ProvidersearchText: string;
  ProviderId: string;
  keywords: string;
}
export class PaymentFilterModel extends FilterModel {
  AppDate: string = "";
  PayDate: string = "";
  PatientName: string = "";
  StaffName: string = "";
  Status: string = "";
  AppointmentType: string = "";
  RangeStartDate: string = "";
  RangeEndDate: string = "";

}
export class RefundFilterModel extends FilterModel {
  AppDate: string = "";
  RefundDate: string = "";
  PatientName: string = "";
  StaffName: string = "";
}


export class StaffBasicDetailModel {
  providerBasicInfoModel: ProviderBasicInfoModel;
  staffSpecialityModel: Array<StaffSpecialityModel> = [];
}

export class ProviderBasicInfoModel {
  staffId: number;
  name: string;
  photoThumbnailPath: string
  photoPath: string;
  gender: string;
  payRate: number;
  urgentCarePayRate: number;
  ratingCount: number;
  averageRating: number;
}
export class StaffSpecialityModel {
  id: number;
  staffId: number;
  specialityId: number;
  speciality: string;
  isDeleted: boolean;
}
export class FilterMemberShipModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  companyId: number = 0;
}

export class VitalgraphMOdel {
  FromDate: string;
  ToDate: string;
  VitalName: string;
  PatientId: number = 0;
  Key: string;
}
export class FilterVideoModel {
  pageNumber: number = 1;
  pageSize: number = 5;
  sortColumn: string = "";
  sortOrder: string = "";
  searchText: string = "";
  companyId: number = 0;
  PatientId: number = 0;

}
export class CompleteAppointmentModel {
  staffId: number = 0;
  roleName: string = "";
  pageNumber: number = 1;
  pageSize: number = 5;
}
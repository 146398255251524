import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageQuizService } from '../manage-quiz.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-add-new-quiz',
  templateUrl: './add-new-quiz.component.html',
  styleUrls: ['./add-new-quiz.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNewQuizComponent implements OnInit {
  quizForm!: FormGroup;
  panelOpenState = false;
  editing: boolean = true;
  questionType: string;
  quizId: any = 0;
  quizViewForm: any;
  constructor(public dialog: MatDialog, private router: Router, private formBuilder: FormBuilder, private manageQuizService: ManageQuizService, private activeRoute: ActivatedRoute, private notifier: NotifierService) {
    // this.questionType=new MatTabChangeEvent();
    this.questionType = "multiple-choice";
  }

  @ViewChild("qusType", {}) qusType: TemplateRef<any>;
  @ViewChild("staffTraining", {}) staffTraining: TemplateRef<any>;
  @ViewChild("preview", {}) preview: TemplateRef<any>;
  @ViewChild("result", {}) result: TemplateRef<any>;

  ngOnInit() {
    this.quizForm = this.formBuilder.group({
      quizId: [this.quizId],
      quizName: ['', [Validators.required]],
      quizPercentage: ['', [Validators.required]],
      createdBy: [0],
      questionDatas: this.formBuilder.array([]),
    })
    this.activeRoute.queryParams.subscribe(params => {
      this.quizId = params['quizId'] == undefined ? 0 : params['quizId'];
      if (this.quizId > 0) {
        this.getQuizDetailsById();
      }
    })
  }

  numberOnly(val: any) {
    const charCode = (val.which) ? val.which : val.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  get quizFormControls() {
    return this.quizForm.controls;
  }

  getQuizDetailsById() {
    this.manageQuizService.getQuizDetailsById(this.quizId).subscribe(res => {
      console.log(res);
      this.quizViewForm = res.data.questionDatas;
      this.quizForm.patchValue(res.data);
      this.setArrayData(res.data.questionDatas);
    }, error => {

    })
  }
  setArrayData(dataArray: any[]) {
    const dataArrayFormArray = <FormArray>this.quizForm.controls["questionDatas"];

    dataArray.forEach((data, i) => {
      dataArrayFormArray.push(this.formBuilder.group({
        quizQuestionnaireID: [data.quizQuestionnaireID],
        questionName: [data.questionName, [Validators.required]],
        questionType: [data.questionType, Validators.required],
        questionOptionDatas: this.formBuilder.array([])
      })); // Create a new FormControl for each element

      const dataArrayFormArrayIndex = dataArrayFormArray.at(i);

      data.questionOptionDatas.forEach(obj => {
        const optionFormArray = dataArrayFormArrayIndex.get('questionOptionDatas') as FormArray;
        optionFormArray.push(this.formBuilder.group({
          questionOptionId: [obj.questionOptionId],
          questionOption: [obj.questionOption, [Validators.required]],
          answer: [obj.answer, [Validators.required]]
        }));
      })
    });
  }
  openNewQuestionDialog(): void {
    this.dialog.open(this.qusType, {
      width: '750px',
      panelClass: 'new-question',
    });
  }
  openStaffTrainingDialog(): void {
    this.dialog.open(this.staffTraining, {
      width: '278px',
      panelClass: 'staff-training',
    });
  }
  openPreviewDialog(): void {
    this.dialog.open(this.preview, {
      width: '1027px',
      panelClass: 'preview-modal',
    });
  }
  openResultDialog(): void {
    this.dialog.open(this.result, {
      width: '1027px',
      panelClass: 'result-modal',
    });
  }

  startEditing() {
    this.editing = true;
  }

  cancelEditing() {
    // If the user cancels editing, revert changes and switch back to display mode.
    this.editing = false;
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  addQuestionType() {
    const control = <FormArray>this.quizForm.controls["questionDatas"];
    control.push(this.createQuestion(this.questionType));
    this.dialog.closeAll();
    this.questionType = "multiple-choice";
  }
  get questionDatas() {
    return this.quizForm.get("questionDatas") as FormArray;
  }
  createQuestion(val: string): FormGroup {
    if (val == 'true-false')
      return this.formBuilder.group({
        quizQuestionnaireID: [0],
        questionName: ['', [Validators.required]],
        questionType: [val, Validators.required],
        questionOptionDatas: this.formBuilder.array([
          this.formBuilder.group({
            questionOptionId: [0],
            questionOption: ['True', [Validators.required]],
            answer: [false, [Validators.required]]
          }),
          this.formBuilder.group({
            questionOptionId: [0],
            questionOption: ['False', [Validators.required]],
            answer: [false, [Validators.required]]
          })
        ])
      });
    if (val == 'fill-blanks')
      return this.formBuilder.group({
        quizQuestionnaireID: [0],
        questionName: ['', [Validators.required]],
        questionType: [val, Validators.required],
        questionOptionDatas: this.formBuilder.array([
          this.formBuilder.group({
            questionOptionId: [0],
            questionOption: ['', [Validators.required]],
            answer: [true, [Validators.required]]
          })
        ])
      });
    else
      return this.formBuilder.group({
        quizQuestionnaireID: [0],
        questionName: ['', [Validators.required]],
        questionType: [val, Validators.required],
        questionOptionDatas: this.formBuilder.array([
          this.formBuilder.group({
            questionOptionId: [0],
            questionOption: ['', [Validators.required]],
            answer: [false, [Validators.required]]
          })
        ])
      });
  }
  tabClick(questionType: MatTabChangeEvent) {
    this.questionType = questionType.tab.textLabel;
  }

  addOptionsOfQuestion() {
    const control = <FormArray>this.quizForm.controls["questionDatas"];
    control.push(this.createQuestion(''));
  }
  removeQuestionItem(index: number) {
    const control = <FormArray>this.quizForm.controls["questionDatas"];
    control.removeAt(index);
  }
  addField(formGroup: FormGroup, index: number): void {
    const fields = formGroup.get('questionOptionDatas') as FormArray;
    // fields.push();
    fields.push(this.formBuilder.group({
      questionOptionId: [0],
      questionOption: ['', [Validators.required]],
      answer: ['', [Validators.required]]
    }));
  }
  removeField(formGroup: FormGroup, index: number): void {
    const fields = formGroup.get('questionOptionDatas') as FormArray;
    // fields.push();
    fields.removeAt(index);
  }

  setRadioData(value, formGroup: FormGroup, index: number): void {
    const fields = formGroup.get('questionOptionDatas') as FormArray;
    // fields.push();
    fields.controls.forEach((obj, i) => {
      if (i == index) {
        obj.patchValue({
          questionOptionId: obj.value.questionOptionId,
          questionOption: obj.value.questionOption,
          answer: true
        });
      }
      else {
        obj.patchValue({
          questionOptionId: obj.value.questionOptionId,
          questionOption: obj.value.questionOption,
          answer: false
        });
      }

    })
  }

  onSubmit() {
    console.log(this.quizForm.value);
    this.manageQuizService.creatQuizDetails(this.quizForm.value).subscribe(res => {
      this.notifier.notify('success', ' Quiz added successfully.');
      this.router.navigate(['web/manage-quiz']);
    }, error => {
      this.notifier.notify('error', error.message);
    })
  }

  onUpdate() {
    console.log(this.quizForm.value);
    this.manageQuizService.updateQuizDetails(this.quizForm.value).subscribe(res => {
      this.notifier.notify('success', ' Quiz updated successfully.');
      this.router.navigate(['web/manage-quiz']);
    }, error => {
      this.notifier.notify('error', error.message);
    })
  }

  DeleteQuizQuestion(quizId, quizQuestionnaireID) {
    this.manageQuizService.confirm(`Are you sure you want to delete the question?`).subscribe((result: any) => {
      if (result == true) {
        this.manageQuizService.deleteQuizQuestion(quizId, quizQuestionnaireID).subscribe(res => {
          this.ngOnInit();
          this.notifier.notify('success', ' Question deleted successfully.');
    
        }, error => {
          this.notifier.notify('error', error.message);
        })
      }
    });
   
  }

  DeleteQuizQuestionOption(quizId, quizQuestionnaireID, questionOptionId) {
    this.manageQuizService.confirm(`Are you sure you want to delete the option?`).subscribe((result: any) => {
      if (result == true) {
        this.manageQuizService.deleteQuizQuestionOption(quizId, quizQuestionnaireID, questionOptionId).subscribe(res => {
          this.ngOnInit();
           this.notifier.notify('success', ' Question option deleted successfully.');
         }, error => {
           this.notifier.notify('error', error.message);
         })
      }
    });
    
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AgencyPermissionGuard } from "./agency_routing_permission.guard";
import { ReviewsRatingsComponent } from "./reviews-ratings/reviews-ratings.component";
import { ManageCoursesComponent } from "./manage-courses/manage-courses.component";
import { ManageCompaniesComponent } from "./manage-companies/manage-companies.component";
import { ManageQuizComponent } from "./manage-quiz/manage-quiz.component";
import { QuizCompaniesComponent } from "./manage-quiz/quiz-companies/quiz-companies.component";
import { MembersParticipatingComponent } from "./manage-quiz/members-participating/members-participating.component";
import { QuizResultComponent } from "./manage-quiz/quiz-result/quiz-result.component";
import { AddNewQuizComponent } from "./manage-quiz/add-new-quiz/add-new-quiz.component";
import { QuestionnaireComponent } from "./manage-quiz/questionnaire/questionnaire.component";

const routes: Routes = [
    // {
    //   path: "",
    //   redirectTo: "/web/dashboard"
    // },
    {
        path: "dashboard",
        canActivate: [AgencyPermissionGuard],
        component: DashboardComponent
    },
    {
        path: "reviews-ratings",
        canActivate: [AgencyPermissionGuard],
        component: ReviewsRatingsComponent
    },
    {
        path: "manage-courses",
        canActivate: [AgencyPermissionGuard],
        component: ManageCoursesComponent
    },
    {
        path: "manage-companies",
        canActivate: [AgencyPermissionGuard],
        component: ManageCompaniesComponent
    },
    {
        path: "manage-quiz",
        canActivate: [AgencyPermissionGuard],
        component: ManageQuizComponent
    },
    {
        path: "manage-quiz-companies",
        canActivate: [AgencyPermissionGuard],
        component: QuizCompaniesComponent
    },
    {
        path: "members-participating",
        canActivate: [AgencyPermissionGuard],
        component: MembersParticipatingComponent
    },
    {
        path: "quiz-result",
        canActivate: [AgencyPermissionGuard],
        component: QuizResultComponent
    },
    {
        path: "add-new-quiz",
        canActivate: [AgencyPermissionGuard],
        component: AddNewQuizComponent
    },
    
    {
        path: "question-quiz",
        canActivate: [AgencyPermissionGuard],
        component: QuestionnaireComponent
    },

    
    // {
    //     path: "performance",
    //     canActivate: [AgencyPermissionGuard],
    //     component: PerformanceComponent
    // },
    {
        path: "Masters",
        loadChildren: "./masters/masters.module#MastersModule"
    },
    {
        path: "manage-users",
        loadChildren: "./users/users.module#UsersModule"
    },
    {
        path: "scheduling",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "../scheduling/scheduling.module#SchedulingModule"
    },
    {
        path: "encounter",
        // canActivate: [AgencyPermissionGuard],
        loadChildren: "./encounter/encounter.module#EncounterModule"
    },
    {
        path: "clearing-house",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "./clearing-house/clearing-house.module#ClearingHouseModule"
    },
    {
        path: "client",
        loadChildren: "./clients/clients.module#ClientsModule"
    },
    {
        path: "manage-ngo",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "../agency-portal/manage-ngo/manage-ngo.model#ManageNgoMdel"
    },
    {
        path: "manage-company",
        loadChildren: "../agency-portal/manage-company/manage-company.model#CompanyModel"
    },
    {
        path: "manage-videos",
        loadChildren: "../agency-portal/manage-videos/manage-videos.module#ManageVideosModule"
    },
    {
        path: "manage-podcast",
        loadChildren: "../agency-portal/manage-podcast/manage-podcast.module#ManagePodcastModule"
    },
    {
        path: "Logs",
        loadChildren: "./logs/logs.module#LogsModule"
    },
    {
        path: "Billing",
        loadChildren: "./billing/billing.module#BillingModule"
    },
    {
        path: "payers",
        loadChildren: "./payers/payers.module#PayersModule"
    },
    {
        path: "payroll",
        loadChildren: "./payroll/payroll.module#PayrollModule"
    },
    {
        path: "mailbox",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "../mailing/mailing.module#MailingModule"
    },
    {
        path: "app-config",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "./app-config/app-config.module#AppConfigModule"
    },
    {
        // path: "questionnaire",
        path: "questionnairess",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "./questionnaire/questionnaire.module#QuestionnaireModule"
    },
    {
        path: "assign-questionnaire",
        // canActivate: [AgencyPermissionGuard],
        loadChildren: "./providerquestionnaire/providerquestionnaire.module#ProviderquestionnaireModule"
    },
    {
        path: "membership",
        loadChildren: "./membership-plan/membership-plan.module#MembershipPlanModule"
    },
    // {
    //   path: "add-keyword",
    //   canActivate: [AgencyPermissionGuard],
    //   loadChildren: "./keyword/keyword.module#KeywordModule" 
    // },
    {
        path: "payment",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "./Payments/payment.module#PaymentModule"
    },
    {
        path: "educational-content",
        canActivate: [AgencyPermissionGuard],
        loadChildren: "./provider-documents/provider-documents.module#ProviderDocumentModule"
    },
    {
        path: "waiting-room/:id",
        loadChildren: "../waiting-room/waiting-room.module#WaitingRoomModule"
    },
    {
        path: "waiting-room",
        loadChildren: "../waiting-room/waiting-room.module#WaitingRoomModule"
    },
    


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AgencyRoutingModule { }

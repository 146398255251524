import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Alert } from 'selenium-webdriver';
import { CompanyService } from '../manage-company/company.service';
import { companyFormsModel, CompanyModel } from '../manage-company//manage-company.model';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteCompanyAlertComponent } from '../manage-company/delete-company-alert/delete-company-alert.component';
import { ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { UsersService } from 'src/app/platform/modules/agency-portal/users/users.service';
import { FilterModel, FilterVideoModel, MediaFilterModel, Metadata, ResponseModel } from '../../core/modals/common-model';
import * as XLSX from 'xlsx';
import { BulkUploadComponent } from '../manage-company/bulk-upload/bulk-upload.component';
import { ManagePackageComponent } from '../manage-company/manage-package/manage-package.component';
import { UploadVideoComponent } from '../manage-videos/upload-video/upload-video.component';
import { AddCourseComponent } from './add-course/add-course.component';

@Component({
  selector: 'app-manage-courses',
  templateUrl: './manage-courses.component.html',
  styleUrls: ['./manage-courses.component.css']
})
export class ManageCoursesComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();
  dataexcel: Array<any>[];
  courseList: Array<any>[];

  organizationId: number;
  metaData: any;
  searchText: string = "";
  filterModel: MediaFilterModel;
  filterVideoModel: FilterVideoModel;
  agencyData: any;
  animal: string;
  searchModel: { SearchKey: string, };
  s3: any;


  displayedColumns: Array<any> = [
    { displayName: 'Course Name', key: 'courseName', class: '', width: '35%' },
    { displayName: 'Course Category', key: 'courseCategory', class: '', width: '35%' },
    //{ displayName: 'Course Completed by Members', key: 'temp', class: '', width: '35%' },
    // { displayName: 'View', key: 'userType', class: '', width: '35%' },
    // { displayName: 'Ratings', key: 'totalRecords',  class: '', width: '25%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '15%' }
  ];

  actionButtons: Array<any> = [
    //{ displayName: 'View', key: 'view', class: 'fa-solid fa-eye' },
    //{ displayName: 'Add', key: 'add', class: 'fa-solid fa-user-plus' },
    // { displayName: 'Package', key: 'package', class: 'fa-solid fa-clipboard' },
    { displayName: 'Delete', key: 'delete', class: 'fa-solid fa-trash-can' }
  ];
  notify: any;

  name = 'This is XLSX TO JSON CONVERTER';
  willDownload = false;
  CategoryType: string = '';
  UserType: string = '';

  constructor(

    public dialog: MatDialog,
    private notifier: NotifierService,
    private router: Router,
    private userservice: UsersService,
    private _companyService: CompanyService) {
    this.filterModel = new MediaFilterModel();
    this.filterVideoModel = new FilterVideoModel();

  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  ngOnInit() {
    this.getCourseList();
  }
  onPageOrSortChange(changeState?: any) {

    this.setPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);

  }

  onTableActionClick(actionObj?: any) {
    console.log(actionObj);
    switch ((actionObj.action || '').toUpperCase()) {

      // case 'VIEW':
      //   localStorage.setItem('heading', 'View Training');
      //   this.ViewVideo(path);
      //   break
      // case 'EDIT':
      //   this.editVideo(id);
      //   localStorage.setItem('headingvalue', 'Edit Training');
      //   break;
      case 'DELETE':
        this.deleteCourse(actionObj.data.id);
        break
      default:
        break;

    }
  }

  EditCompany(id: number) {
    localStorage.setItem('heading', 'Edit Company');
    localStorage.setItem('id', id.toString());
    this._companyService.getById(id).subscribe((res: any) => {
    })
    // this.router.navigate(["/web/manage-podast/add-podcast/upload-video"]);
  }

  OnOff(value: number) {
    if (value == 1) {
      return true;
    }
    return false;
  }

  applyFilter(searchText: string = '') {
    if (searchText == "" || searchText.length > 2) {
      this.filterModel.searchText == this.searchText;
      this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);

    }

  }





  getCourseList() {
    // this.filterModel.searchText==this.searchText;
    // var id = Number(localStorage.getItem('podcastId'));
    this.userservice.getAllCourseList(this.filterModel).subscribe((response: ResponseModel) => {

      if (response && response.statusCode == 200) {
        this.agencyData = response.data;
        this.metaData = response.meta;
      }
      else {
        this.agencyData = [];
        this.metaData = new Metadata();
      }

      //this.metaData.pageSizeOptions = [5, 10, 25, 50, 100];
    });
  }


  clearFilters() {
    this.searchText = "";
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, '');
    this.getCourseList();
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.searchText = searchText;
    this.getCourseList();
  }
  addAgency(organizationId: number = null) {
    localStorage.setItem('heading', 'Add Company');
    // this.router.navigate(["/web/manage-podcast/add-podcast/upload-video"]);
  }


  deleteCourse(id: number) {
    this._companyService.confirm(`Are you sure you want to delete video?`).subscribe((result: any) => {
      if (result == true) {
        this.userservice.deleteCourseName(id).subscribe((response: string) => {
          let responsedata: any = response;
          this.notifier.notify("success", responsedata.message);
          this.getCourseList();
        });
      }
    }
    );

  }


  changedActive(id: number, num: number) {
    this._companyService.changeStatus(id, num).subscribe(res => {

    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(BulkUploadComponent, {

    });

    dialogRef.afterClosed().subscribe(result => {

      this.dataSource.sort = this.sort;
    });
  }
  openplanDialog(): void {
    const dialogRef = this.dialog.open(ManagePackageComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      if (localStorage.getItem('membershipid') != '') {

      }
    });
  }



  openDialog1(): void {
    const dialogRef = this.dialog.open(UploadVideoComponent, {
      width: '750px',
      panelClass: 'upload-video',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }
  openAddCourseDialog(): void {
    const dialogRef = this.dialog.open(AddCourseComponent, {
      width: '450px',
      panelClass: 'add-course',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }

}

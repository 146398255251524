import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from "@angular/material";
import { QuestionnaireComponent } from "./questionnaire/questionnaire.component";
import { ManageQuizService } from "./manage-quiz.service";
import { ManageAgencyModel } from "src/app/super-admin-portal/manage-agency/manage-agency.model";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";


@Component({
  selector: "app-manage-quiz",
  templateUrl: "./manage-quiz.component.html",
  styleUrls: ["./manage-quiz.component.css", "manage-quiz.component.scss"],
})
export class ManageQuizComponent implements OnInit {
  quizDetails: any;
  SearchKey: string = '';
  pageNumber: any = 1;
  pageSize: number = 10;
  length: number;
  @ViewChild("preview", {}) preview: TemplateRef<any>;
  quizViewForm: any;
  constructor(
    public dialog: MatDialog,
    private manageQuizService: ManageQuizService,
    private route: Router,
    private notifier: NotifierService
  ) { }

  ngOnInit() {
    this.GetQuizDetails();
  }

  GetQuizDetails() {
    this.manageQuizService.getQuizDetails(this.SearchKey, this.pageNumber, this.pageSize).subscribe((res) => {
      if (res && res.statusCode == 200) {
        this.quizDetails = res.data;
        this.length = res.data[0].totalRecords;

      }
    });
  }

  GetQuizDetailsById(quizId) {
    this.manageQuizService.getQuizDetailsById(quizId).subscribe((res) => {
      this.quizViewForm = res.data.questionDatas;
    });
  }

  editView(quizId) {
    this.route.navigate(["/web/add-new-quiz"], {
      queryParams: { quizId: quizId },
    });
    this.GetQuizDetails();
  }

  openDialog1(): void {
    this.route.navigate(["/web/add-new-quiz"]);
    //   const dialogRef = this.dialog.open(QuestionnaireComponent, {
    //    width: '750px',
    //    panelClass: 'upload-video',
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //  console.log(result)
    //   });
  }
  openPreviewDialog(quizId) {
    this.dialog.open(this.preview, {
      width: "1027px",
      panelClass: "preview-modal",
    });
    this.GetQuizDetailsById(quizId);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  deleteQuiz(quizId) {
    this.manageQuizService
      .confirm(`Are you sure you want to delete the option?`)
      .subscribe((result: any) => {
        if (result == true) {
          this.manageQuizService.deleteQuiz(quizId).subscribe(
            (res) => {
              this.notifier.notify("success", "Quiz deleted successfully.");
              this.GetQuizDetails();
            },
            (error) => {
              this.notifier.notify("error", error.message);
            }
          );
        }
      });
  }

  applyFilter(searchText: string = '') {

    if (searchText == "" || searchText.length > 2) {

      this.SearchKey = searchText;
      this.GetQuizDetails();
    }
  }

  onPageOrSortChange(changeState?: any) {
    this.pageNumber = changeState.pageIndex + 1;
    this.GetQuizDetails();

  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { DialogComponent } from 'src/app/shared/layout/dialog/dialog.component';
import { environment } from 'src/environments/environment';
import { FilterModel } from '../../core/modals/common-model';
import { CommonService } from '../../core/services';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  id: number;
  patientid: number;
  planid: number;
  getAllURL = 'api/Company/';
  private generatereport = "Media/GenerateAttendenceReport";
  constructor(private http: HttpClient, private dialog: MatDialog, private commonService: CommonService) { }

  getAll(filterModal: FilterModel) {
    let url = this.getAllURL + 'GetCompanies?pageNumber=' + filterModal.pageNumber + '&pageSize=' + filterModal.pageSize + '&sortColumn=' + filterModal.sortColumn + '&sortOrder=' + filterModal.sortOrder + '&ComanyName=' + filterModal.searchText;
    return this.http.get(`${environment.api_url}/${url}`, {});
  }

  getAllNoSp() {
    let url = this.getAllURL + "All";
    return this.http.get(`${environment.api_url}/${url}`)
  }

  deleteByID(id) {
    let url = this.getAllURL + "?id=";
    return this.http.delete(`${environment.api_url}/${url}` + id);
  }

  getById(id) {
    let url = this.getAllURL + "Compony/";
    return this.http.get(`${environment.api_url}/${url}` + id);
  }

  getCompanyById(id){
    let url = this.getAllURL + "GetDataCompony/";
    return this.http.get(`${environment.api_url}/${url}` + id);
  }

  addCompany(data:any){
    let url = this.getAllURL + "AddCompany/";
    return this.http.post(`${environment.api_url}/${url}`, data);
  }

  editCompnay(id: number, data: any) {
    let url = this.getAllURL + "UpdateCompany";
    return this.http.post(`${environment.api_url}/${url}`, data);
  }

  changeStatus(id: number, num: number) {
    let url = this.getAllURL + id + '?num=' + num;
    return this.http.patch(`${environment.api_url}/${url}`, '');
  }

  generateReport(companyName: any) {
    const url = this.generatereport + "?companyName=" + companyName;
    return this.commonService.getAll(url, {});
  }

  public confirm(
    message: string,
    data?: Array<any>): Observable<any> {
    const modalRef = this.dialog.open(DialogComponent, { data: data, hasBackdrop: true, backdropClass: 'no-backdrop', disableClose: false, width: 'auto', minWidth: 'auto', maxWidth: 'auto', position: { bottom: '10px', right: '10px' } });
    modalRef.componentInstance.message = message;
    return modalRef.afterClosed();
  }
}
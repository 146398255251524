import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})
export class QuestionnaireComponent implements OnInit {

  constructor(private dialoge:MatDialog, private router:Router) { }

  ngOnInit() {
  }

  closeDialog(){
 this.dialoge.closeAll();
  }

  addQuestionType(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(["/web/add-new-quiz"]);
    this.dialoge.closeAll();
  }
}

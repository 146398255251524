import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageVideosComponent } from './manage-videos.component';
import { AgencyPermissionGuard } from '../agency_routing_permission.guard';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule, MatFormFieldModule, MatRadioModule, MatSelectModule, MatTableModule } from '@angular/material';
import { PlatformMaterialModule } from 'src/app/platform/platform.material.module';
import { ChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollbarModule } from 'ngx-scrollbar';
import { FormioModule } from 'angular-formio';
import { UploadVideoComponent } from './upload-video/upload-video.component';
import { ViewVideoComponent } from './view-video/view-video.component';
import { ListVideoComponent } from './list-video/list-video.component';
import { MediaFilterComponent } from './media-filter/media-filter.component';
import { ViewMediaComponent } from './view-media/view-media.component';
import { AudioModalComponent } from './audio-modal/audio-modal.component';
import { QuestionnaireComponent } from '../manage-quiz/questionnaire/questionnaire.component';

const proRoute1: Routes = [
  {
    path: "",
    canActivate: [AgencyPermissionGuard],
    children: [
      {
        path: '',
        component: ManageVideosComponent
      },
      {
        path: 'upload-video',
        component: UploadVideoComponent
      },
      {
        path: 'view-video',
        component: ViewVideoComponent
      },
         {
        path: 'list-video',
        component: ListVideoComponent
      },
      
    ]
  }

]
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatDialogModule,
    PlatformMaterialModule,
    ChartsModule,
    NgxMatSelectSearchModule,
    ScrollbarModule,
    FormioModule,
    MatSelectModule,
    RouterModule.forChild(proRoute1),MatFormFieldModule,
    MatRadioModule,
    MatTableModule
  ],
  entryComponents: [
    MediaFilterComponent,
    ViewMediaComponent,
    AudioModalComponent,
  ],
  exports: [RouterModule,ManageVideosComponent,UploadVideoComponent, MediaFilterComponent, ViewMediaComponent, ViewVideoComponent, AudioModalComponent, QuestionnaireComponent],
  declarations: [
    ManageVideosComponent,
    UploadVideoComponent,
    ViewVideoComponent,
    ListVideoComponent,
    MediaFilterComponent,
    ViewMediaComponent,
    AudioModalComponent,
    QuestionnaireComponent
  ]
})
export class ManageVideosModule { }

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ReviewRatingModel } from '../../client-portal/review-rating/review-rating.model';
import { ResponseModel } from '../../core/modals/common-model';
import { ClientsService } from '../clients/clients.service';
import { ViewRatingModel } from './view-rating-model';

@Component({
  selector: 'app-view-ratings',
  templateUrl: './view-ratings.component.html',
  styleUrls: ['./view-ratings.component.css']
})
export class ViewRatingsComponent implements OnInit {
  reviewRatingForm: FormGroup;
  reviewModel: ViewRatingModel;
  reviewRatingId: number;
  appointmentId: number;
  
  staffId:number;
  headerText: string = "";

  submitted: boolean = false;
  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private clientService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private notifierService: NotifierService,
    private route: Router,
    private dialogModalRef: MatDialogRef<ViewRatingsComponent>
  ) {
    this.reviewModel =
      this.data.reviewRatingModel == null
        ? new ReviewRatingModel()
        : this.data.reviewRatingModel;
     this.headerText = "View Rating Details";
  }

  ngOnInit() {
   
  }
  get formControls() {
    return this.reviewRatingForm.controls;
  }
  validateForm(formGroup: FormGroup) {
    return null;
  }
  
  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { ResponseModel } from '../../../core/modals/common-model';
import { ClientModel } from '../../clients/client.model';
import { ClientsService } from '../../clients/clients.service';
import { CompanyService } from '../company.service';
import { companyFormsModel } from '../manage-company.model';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})
export class BulkUploadComponent implements OnInit {
  dataexcel: Array<any>[];
  animal: string;
  dataexcellength: number = 0;
  clientModel: ClientModel;
  url: any;
  companyFormsData: companyFormsModel = {
    id: '',
    companyName: '',
    brnNumber: '',
    taxNumber: '',
    emailId: '',
    phoneNumber: '',
    contactPerson: '',
    address: '',
    status: '',
    OraganizationId: 1,
    isLock: 1,
    planId:''
  }
  router: any;
  constructor(private notifier: NotifierService,public dialogRef: MatDialogRef<BulkUploadComponent>, private clientService: ClientsService, private _companyService: CompanyService) {
    this.clientModel = new ClientModel();
  }

  ngOnInit() {
    this.url = window.location.pathname;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onFileChange(ev) {
    this.dataexcel = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
      if (this.url == '/web/manage-company') {
        this.dataexcel = jsonData.Company;
      } else {
        this.dataexcel = jsonData.Users;
      }

      if (this.dataexcel == undefined) {
        document.getElementById("tableuser").classList.add("hide");
        document.getElementById("bulkcreate").classList.add("hide");
      }
      this.dataexcellength = this.dataexcel.length
      console.log("value", this.dataexcellength)
      if (this.dataexcellength != 0) {
        document.getElementById("tableuser").classList.remove("hide");
        document.getElementById("bulkcreate").classList.remove("hide");
      }
      //document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      //this.setDownload(dataString);
    }
    reader.readAsBinaryString(file);
  }
  bulkdispaly() {
    document.getElementById("dialogheaderbulk").classList.remove("hide");
  }
  createnewuser() {
    this.dataexcel.forEach((data: any) => {
      var gender: number = 0;
      if (data.Gender == 'Male') {
        gender = 211;
      } else {
        gender = 212;
      }
      this.clientModel.email = data.EmailID;
      this.clientModel.firstName = data.FirstName;
      this.clientModel.lastName = data.LastName;
      this.clientModel.userName = data.EmailID;
      this.clientModel.isActive = true;
      this.clientModel.isBlock = false;
      this.clientModel.dob = format(data.DateofBirth, "YYYY-MM-DDTHH:mm:ss");
      this.clientModel.company_id = this._companyService.id;
      this.clientModel.mobile_number = data.MobileNumber;
      this.clientModel.gender = gender;
      console.log(this.clientModel);
      this.clientService.create(this.clientModel).subscribe((response: any) => {
        console.log("message", response)
        if(response.statusCode == 200){
          window.location.reload;
        }
      });
      // this._companyService.addCompany(this.companyFormsData).subscribe((res: any) => {
      //   console.log("message",res)
      // })
    });
    //window.location.reload();
  }
  createnew() {
    this.dataexcel.forEach((data: any) => {
      this.companyFormsData.emailId = data.EmailId;
      this.companyFormsData.companyName = data.CompanyName;
      this.companyFormsData.brnNumber = data.BRNNumber;
      this.companyFormsData.taxNumber = data.TaxNumber;
      this.companyFormsData.status = 1;
      this.companyFormsData.isLock = 2;
      this.companyFormsData.phoneNumber = data.PhoneNumber;
      this.companyFormsData.contactPerson = data.ContactPerson;
      this.companyFormsData.address = data.Address;
      this.companyFormsData.planId = 0
      this._companyService.addCompany(this.companyFormsData).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.notifier.notify('success', res.message + ' company added successfully.');
          this.dialogRef.close();
          this.router.navigate(["/web/manage-company"]);
          return;
        }
        this.notifier.notify('error', res.message);
        this.dialogRef.close();
      })
    });
  }
}

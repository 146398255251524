import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ManageVideosService } from '../manage-videos.service';

@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.component.html',
  styleUrls: ['./view-media.component.css']
})
export class ViewMediaComponent implements OnInit {
  podcast: any;
  staff: any;
  module: any;
  month: any;
  week: any;
  title: any;
  desc: any;
  year: any;
  Course: any;
  constructor(@Inject(MAT_DIALOG_DATA) public _data: any, public dialogRef: MatDialogRef<ViewMediaComponent>, private videoService: ManageVideosService) { }

  ngOnInit() {
    this.videoService.getCourseNamebyId(this._data.courseId).subscribe(response => {
      if (response && response.statusCode == 200) {
        this.Course = response.data.courseName;
      }
    });

    if (this._data) {
      this.podcast = this._data.categoryType
      this.staff = this._data.courseType
      this.module = this._data.moduleId
      this.week = this._data.weekId
      this.title = this._data.title
      this.desc = this._data.description
      this.month = this._data.monthId
      this.year = this._data.year
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

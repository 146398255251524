import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { MediaFilterModel, Metadata, ResponseModel } from '../../../core/modals/common-model';
import { UsersService } from '../../users/users.service';
import { CommonService } from '../../../core/services';
import { Data, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ManageVideosService } from '../manage-videos.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogComponent } from 'src/app/shared/layout/dialog/dialog.component';
import { bool } from 'aws-sdk/clients/signer';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  }
};

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class UploadVideoComponent implements OnInit {

  metaData: any;
  filterModel: MediaFilterModel;
  url: any;
  status: boolean = false;
  Week4primary: any;
  Week3primary: any;
  Week2primary: any;
  Week1primary: any;
  Part1primary: any;
  Part2primary: any;
  Part3primary: any;
  Media1primary: any;
  Media2primary: any;
  WeekId: any;
  MonthId: any;
  MediaId: any;
  Media: any;
  format: any;
  duration: any;
  uploadForm: FormGroup;
  userId: number = null;
  masterDocumentTypes: any = []
  addDocumentForm: FormGroup;
  fileList: any = [];
  dataURL: any;
  submitted: boolean = false;
  uploadDate: any;
  year: any;
  minDate: Moment;
  CourseNameList: any;
  userTypeList: any = [];
  // UserTypeList= [
  //   { id: 1, type:'Staff'},
  //   { id: 2, type: 'Executive' }
  // ];
  // CourseNameList= [
  //   { id: 1, type:'Worm Up'},
  //   { id: 2, type: 'Core/Balance'},
  //   { id: 3, type: 'Speed & Quickness'},
  //   { id: 4, type: 'Cool-Down'}

  // ];
  ModuleList = [
    { id: 1, type: 'Module One' },
    { id: 2, type: 'Module Two' },
    { id: 3, type: 'Module Three' },
    { id: 4, type: 'Module Four' }
  ];
  Weeks = [
    { id: 1, type: 'Week One' },
    { id: 2, type: 'Week two' },
    { id: 3, type: 'Week Three' },
    { id: 4, type: 'Week Four' }
  ];
  MediaTypes = [
    { id: 1, type: 'Podcast' },
    { id: 2, type: 'Video' }
  ];
  QuizList: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any, private userservice: UsersService,
    private router: Router, private notifier: NotifierService, public dialogRef: MatDialogRef<UploadVideoComponent>, private formBuilder: FormBuilder, private videoService: ManageVideosService) {
  }
  @ViewChild("fileupload") fileupload;

  videoForm: FormGroup
  ngOnInit() {
    const currentYear = moment().year();
    this.minDate = moment([currentYear, 0, 1]);
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
    this.videoForm = new FormGroup({
      Title: new FormControl('', [Validators.required]),
      Duration: new FormControl('0', [Validators.required]),
      Description: new FormControl(''),
      CategoryType: new FormControl('', [Validators.required]),
      MonthId: new FormControl('', [Validators.required]),
      TypeName: new FormControl('', [Validators.required]),
      CourseId: new FormControl('', [Validators.required]),
      WeekId: new FormControl('', [Validators.required]),
      ModuleId: new FormControl('', [Validators.required]),
      QuizId: new FormControl(''),
      QuizDescription: new FormControl(''),
      AttachFile: new FormControl('')

    });
    if (this._data) {
      this.userservice.getmediadetailsById(this._data).subscribe((response) => {
        if (response.data != null) {
          this.MonthId = response.data.monthId;
          this.WeekId = response.data.weekId;
          // if (response.data.mediaType == "audio/mpeg") {
          //   response.data.fileArray = "data:audio/mp3;base64," + response.data.fileArray;
          // } else if (response.data.mediaType == "video/mp4") {
          //   response.data.fileArray = "data:video/mp4;base64," + response.data.fileArray;
          // }
          this.UpdateData(response.data);
          this.SelectMonth('Month' + response.data.monthId);
          this.SelectWeek('Week' + response.data.weekId);
          this.SelectMedia(response.data.categoryType);

        }

      });
    }
    this.QuizListDetails();
  }

  FromDate(event: any,): void {

    var NewstartDate = new Date(event.target.value);
    this.uploadDate = new Date(NewstartDate.getFullYear(), NewstartDate.getMonth(), NewstartDate.getDate(), NewstartDate.getHours(), NewstartDate.getMinutes() - NewstartDate.getTimezoneOffset()).toISOString();

  }

  UpdateData(data: any) {

    this.videoForm.patchValue({
      Title: data.title,
      TypeName: data.courseType,
      CategoryType: data.categoryType,
      MonthId: data.monthId,
      Description: data.description,
      WeekId: data.weekId,
      ModuleId: data.moduleId,
      CourseId: data.courseId,
      Year: new Date(data.year),
      QuizId: data.quizId,
      QuizDescription: data.quizDescription
      //AttachFile: data.fileArray


    })

  }
  handleImageChange(event: any) {
    var length: any;
    console.log("file:", event)

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // file.name =
      this.uploadForm.get('profile').setValue(file);
    }
    const file = event.target.files && event.target.files[0];
    var video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      length = video.duration;
      localStorage.setItem('Duration', length);
    }

    video.src = URL.createObjectURL(file);
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
        this.dataURL = file;
        // this.duration = length;
        // console.log("Duration: " + this.duration);
      }
    }
  }
  removeFile() {
    this.dataURL = null;
    this.format = null;
    this.url = null;
  }


  onWeekSelect(event: any) {
    if (event.currentTarget.name == 'Week1') {
      this.status = false;
      this.WeekId = 1;
      this.Week1primary = true;
      this.Week2primary = false;
      this.Week3primary = false;
      this.Week4primary = false;
    } else if (event.currentTarget.name == 'Week2') {
      this.status = false;
      this.WeekId = 2;
      this.Week1primary = false;
      this.Week2primary = true;
      this.Week3primary = false;
      this.Week4primary = false;
    } else if (event.currentTarget.name == 'Week3') {
      this.status = false;
      this.WeekId = 3;
      this.Week1primary = false;
      this.Week2primary = false;
      this.Week3primary = true;
      this.Week4primary = false;
    } else if (event.currentTarget.name == 'Week4') {
      this.status = !this.status;
      this.WeekId = 4;
      this.Week1primary = false;
      this.Week2primary = false;
      this.Week3primary = false;
      this.Week4primary = true;
    }
  }

  onPartSelect(event: any) {
    if (event.currentTarget.name == 'Month1') {
      this.MonthId = 1;
      this.Part1primary = true;
      this.Part2primary = false;
      this.Part3primary = false;
    } else if (event.currentTarget.name == 'Month2') {
      this.MonthId = 2;
      this.Part1primary = false;
      this.Part2primary = true;
      this.Part3primary = false;
    } else if (event.currentTarget.name == 'Month3') {
      this.MonthId = 3;
      this.Part1primary = false;
      this.Part2primary = false;
      this.Part3primary = true;
    }
  }

  onMediaSelect(event: any) {

    if (event.currentTarget.name == 'Video') {
      this.Media = 'Video';
      this.Media1primary = true;
      this.Media2primary = false;

    } else if (event.currentTarget.name == 'Podcast') {
      this.Media = 'Podcast';
      this.Media1primary = false;
      this.Media2primary = true;
    }
  }

  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment, dp: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    dp.close();
    this.year = ctrlValue.year();
    console.log(this.year);

  }


  onSubmit() {
    this.duration = localStorage.getItem("Duration");
    const formData = new FormData();
    formData.append("Id", this._data);
    formData.append("Duration", this.duration);
    formData.append("Title", this.videoForm.value.Title);
    formData.append('file', this.dataURL);
    formData.append('Description', this.videoForm.value.Description);
    formData.append('CategoryType', this.Media);
    formData.append('CourseType', this.videoForm.value.TypeName);
    formData.append('MonthId', this.MonthId.toString());
    formData.append('WeekId', this.WeekId.toString());
    formData.append('ModuleId', this.videoForm.value.ModuleId);
    formData.append('Year', this.year);
    formData.append('CourseId', this.videoForm.value.CourseId);
    formData.append('QuizId', this.videoForm.value.QuizId);
    formData.append('QuizDescription', this.videoForm.value.QuizDescription);
    this.videoService.postvideo(formData).subscribe(response => {
      if (response != null && response.statusCode == 201) {
        this.notifier.notify('success', response.message);
        this.router.navigate(["web/manage-videos"]);
      }
      else this.notifier.notify('error', response.message);
    });

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  SelectMonth(event: any) {
    if (event == 'Month1') {
      this.Part1primary = true;
    } else if (event == 'Month2') {
      this.Part2primary = true;
    } else if (event == 'Month3') {
      this.Part3primary = true;
    }
  }

  SelectWeek(event: any) {
    if (event == 'Week1') {
      this.status = false;
      this.Week1primary = true;
    } else if (event == 'Week2') {
      this.status = false;
      this.Week2primary = true;
    } else if (event == 'Week3') {
      this.status = false;
      this.Week3primary = true;
    } else if (event == 'Week4') {
      this.status = true;
      this.Week4primary = true;
    }
  }

  SelectMedia(event: any) {
    if (event == 'Video') {
      this.Media1primary = true;
    } else if (event == 'Podcast') {
      this.Media2primary = true;
    }
  }

  getCourseList() {
    //this.filterModel = new MediaFilterModel();
    this.userservice.getAllCourseList(this.filterModel).subscribe((response: ResponseModel) => {
      if (response && response.statusCode == 200) {
        this.CourseNameList = response.data;
        this.metaData = response.meta;
      }
      else {
        this.CourseNameList = [];
        this.metaData = new Metadata();
      }

    });
  }

  courseDropdown(data: any) {

    this.filterModel = new MediaFilterModel();
    // this.userTypeList = [];
    // this.userTypeList = this.CourseNameList.filter(x => x.courseCategory == data.source.value);
    this.filterModel.CourseType = data.source.value;
    this.getCourseList();
  }
  QuizListDetails() {
    this.videoService.getquizlist().subscribe(response => {
      if (response != null) {
        this.QuizList = response.data;
      }
    });
  }
}

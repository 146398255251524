import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { CommonService } from '../platform/modules/core/services';

@Injectable()
export class SharedService {
    private sidenav: MatSidenav;

    constructor(private commonService: CommonService) { }

    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    public open() {
        return this.sidenav.open();
    }


    public close() {
        return this.sidenav.close();
    }

    public toggle(): void {
        this.sidenav.toggle();
    }



    downloadFile(blob: Blob, filetype: string, filename: string) {

        var newBlob = new Blob([blob], { type: filetype });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data);
        }, 100);
    }
}

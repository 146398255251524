import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { format } from "date-fns";
import { Subscription, Subject } from "rxjs";
import { UrgentCareListingdialogComponent } from "src/app/shared/urgentcarelisting-dialog/urgentcarelisting-dialog.component";
import { Metadata } from "../../../client-portal/client-profile.model";
import { RefundFilterModel, ResponseModel } from "../../../core/modals/common-model";
import { LoginUser } from "../../../core/modals/loginUser.modal";
import { CommonService } from "../../../core/services";
import { SchedulerService } from "../../../scheduling/scheduler/scheduler.service";
import { ClientsService } from "../../clients/clients.service";
import { FilterModel } from "../../clients/medication/medication.model";
import { EncounterModel, DashboardModel, ClientStatusChartModel } from "../../dashboard/dashboard.model";
import { DashboardService } from "../../dashboard/dashboard.service";
import { CompanyService } from "../../manage-company/company.service";
import { ManageNgoService } from "../../manage-ngo/manage-ngo.service";
import { PaymentService } from "../../Payments/payment.service";
import { StaffProfileModel } from "../users.model";
import { UsersService } from "../users.service";

@Component({
  selector: 'app-view-consultant',
  templateUrl: './view-consultant.component.html',
  styleUrls: ['./view-consultant.component.css'],
  providers:[DatePipe]
})
export class ViewConsultantComponent implements OnInit {
  private getAllURL = "Staffs/GetStaffs";
  userrolename: any;
  averageStar:any;
  encFilterModel: FilterModel;
  reviewRatingFilterModel: FilterModel;
  filterModel: RefundFilterModel;
  encounterList: Array<EncounterModel> = [];
  dashboardData: DashboardModel;
  clientStatusChartData: Array<ClientStatusChartModel> = [];
  encMeta: Metadata;
  subscription: Subscription;
  isAuth: boolean = true;
  refund: any = 0;
  currentDateRefund: any = 0;
  currentMonthRefund: any = 0;
  lastMonthRefund: any = 0;
  status: boolean = false;
  passwordExpiryColorCode: string = '';
  passwordExpiryMessage: string = '';
  showMessage: boolean = true;
  accessToken = 'YOUR_ACCESS_TOKEN';
  message: Subject<any> = new Subject();
  pendingAppointmentMeta: Metadata;
  cancelledAppointmentMeta: Metadata;
  tentativeAppointmentMeta: Metadata;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  curDate: any;
  aprovedAppointmentsDisplayedColumns: Array<any>;
  aprovedAppointmentsActionButtons: Array<any>;
  pendingDisplayedColumns: Array<any>;
  pendingActionButtons: Array<any>;
  cancelledDisplayedColumns: Array<any>;
  cancelledActionButtons: Array<any>;
  pendingAptfilterModel: FilterModel;
  cancelledAptfilterModel: FilterModel;
  pendingPatientAppointment: Array<any> = [];
  cancelledPatientAppointment: Array<any> = [];
  usercountid: Array<any> = [];
  usercount: Array<any> = [];
  selectedIndex: number = 0
  //Charts
  lineChartData: Array<any> = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];
  lineChartLabels: Array<any> = [];
  lineChartType: string = 'line';
  headerText: string = 'Authorization';
  userId: string;
  staffId: number;
  urgentcareapptid: number;
  showurgentcarebtn: boolean = false;
  staffProfile: StaffProfileModel;
  firstname: string;
  dughtpieobject: any;
  ngocount: number = 0;
  companycount: number = 0;
  consultantcount: number = 0;
  consultantavlcount: number = 0;
  userscount: number = 0;
  consultantname: any;
  consultanttotalrevenu: number = 0;
  subSpecialities: any;
  collapse1tf: boolean = false;
  collapse2tf: boolean = false;
  public pieChartLabelss: string[] = ['Chrome', 'Safari'];
  public pieChartDatas: number[] = [40, 20];
  public pieChartTypes: string = 'pie';
  constructor(private dashoboardService: DashboardService, private ngoService: ManageNgoService, private _companyService: CompanyService, private datePipe: DatePipe, private route: Router, private paymentService: PaymentService, private commonService: CommonService, private schedulerService: SchedulerService, private userService: UsersService, private dialogModal: MatDialog, private clientservice: ClientsService) {
    this.encFilterModel = new FilterModel();
    this.reviewRatingFilterModel = new FilterModel();
    this.filterModel = new RefundFilterModel();
    this.encMeta = new Metadata();
    this.dashboardData = new DashboardModel();
    this.staffProfile = new StaffProfileModel()
    // this.encDisplayedColumns = [
    //   { displayName: 'Practitioner', key: 'staffName', class: '', width: '20%' },
    //   { displayName: 'Client Name', key: 'clientName', class: '', width: '20%' },
    //   { displayName: 'Day', key: 'day', width: '10%' },
    //   { displayName: 'DOS', key: 'dateOfService', width: '20%', type: 'date' },
    //   { displayName: 'Status', key: 'status', width: '20%' },
    //   { displayName: 'Actions', key: 'Actions', width: '10%' }
    // ];
    // this.encActionButtons = [
    //   { displayName: 'View', key: 'view', class: 'fa fa-eye' }
    // ];
    this.pendingAptfilterModel = new FilterModel();
    this.cancelledAptfilterModel = new FilterModel();
    this.pendingDisplayedColumns = [
      { displayName: 'Care Manager', key: 'staffName', width: '110px', sticky: true },
      { displayName: 'Member Name', key: 'fullName', width: '120px', type: 'link', url: '/web/member/profile', queryParamsColumn: 'queryParamsPatientObj', sticky: true },
      { displayName: 'Appt. Type', key: 'appointmentType', width: '80px' },
      { displayName: 'Date Time', key: 'dateTimeOfService', width: '250px', type: 'link', url: '/web/member/scheduling', queryParamsColumn: 'queryParamsObj' },
      { displayName: 'Actions', key: 'Actions', width: '80px', sticky: true }
    ]
    this.pendingActionButtons = [
      { displayName: 'Approve', key: 'approve', class: 'fa fa-check' },
      { displayName: 'Cancel', key: 'cancel', class: 'fa fa-ban' },
    ];

    this.cancelledDisplayedColumns = [
      { displayName: 'Care Manager', key: 'staffName', width: '100px', sticky: true },
      { displayName: 'Member Name', key: 'fullName', width: '120px', type: 'link', url: '/web/member/profile', queryParamsColumn: 'queryParamsPatientObj', sticky: true },
      { displayName: 'Appt. Type', key: 'appointmentType', width: '80px' },
      { displayName: 'Date Time', key: 'dateTimeOfService', width: '200px', type: 'link', url: '/web/member/scheduling', queryParamsColumn: 'queryParamsObj' },
      { displayName: 'Cancel Type', kefy: 'cancelType', width: '140px' },
      { displayName: 'Cancel Reason', key: 'cancelReason', width: '150px' },
      { displayName: 'Actions', key: 'Actions', width: '60px', sticky: true }
    ]
    this.cancelledActionButtons = [];

    this.aprovedAppointmentsDisplayedColumns = [
      { displayName: 'Practitioner', key: 'staffName', class: '', width: '20%' },
      { displayName: 'Client Name', key: 'clientName', class: '', width: '20%' },
      { displayName: 'Day', key: 'day', width: '10%' },
      { displayName: 'DOS', key: 'dateOfService', width: '20%', type: 'date' },
      { displayName: 'Status', key: 'status', width: '20%' },
      { displayName: 'Actions', key: 'Actions', width: '10%' }
    ]
    this.aprovedAppointmentsActionButtons = [];
  }

  ngOnInit() {
    this.usercountid = [];
    var id = Number(localStorage.getItem('staffid'));
    this.userService.getStaffById(id).subscribe((user: any) => {
      if (user.data) {
        console.log("value", user.data)
        this.staffId = user.data.id;
        this.averageStar=user.data.averageRating;
      //  this.getStaffReviewData();
        this.userId = user.data.userID
        this.firstname = user.data.firstName;
        this.consultantname = user.data.firstName + " " + user.data.lastName
        this.userrolename = user.data.roleName;
        this.subSpecialities = user.data.subSpecialities;
        const userRoleName =
          user.data.users3 && user.data.users3.userRoles.userType;
        if ((userRoleName || "").toUpperCase() === "PROVIDER") {
          this.getLastUrgentCareCallStatus();
        }
      }
    });

    this.dughtpieobject = [];
    const data: any = {
      pageNumber: 1,
      pageSize: 20,
      sortColumn: '',
      sortOrder: '',
      searchKey: '',
    }
    var RoleIds = '';
    this.userService.getAll(data, '', RoleIds, 156).subscribe((response: ResponseModel) => {
      response.data.forEach((rep: any) => {
        this.dughtpieobject.push(rep);
        //console.log("length",this.dughtpieobject.length)
        this.consultantcount = this.dughtpieobject.length;
      });
    });

    var interval = setInterval(() => {
      if (this.dughtpieobject != 0) {
        clearInterval(interval);
        
      }

    }, 1000);
    this.ngoService.getAll().subscribe((response: any) => {
      this.ngocount = response.length;
      console.log(response)
    });
    
    this._companyService.getAll(this.encFilterModel).subscribe((response: ResponseModel) => {
      if (response && response.statusCode == 200) {
        this.companycount = response.data.length;
        // response.data.forEach((data: any) => {
        
        // });

      }
      else {
        this.companycount = 0;
      }

    });
    const dataid: any = {
      pageNumber: 1,
      pageSize: 200000,
      sortColumn: '',
      sortOrder: '',
      searchKey: '',
    }
    var RoleIds = '';
    this.clientservice.getpatientAll(dataid, '', RoleIds, 156, 0).subscribe((response: ResponseModel) => {
      this.usercountid.push(response.data)
    });
    
    
    this.setPaginatorModel();
    this.getPayments(this.filterModel);
    this.getEncounterListForDashboard();
    this.getDashboardBasicInfo();
    this.getPasswordExpiryMessage();
    this.getStaffProfileData();
   

  }

  getPayments(filterModel: RefundFilterModel) {

    this.paymentService
      .getAppointmentRefunds(filterModel)
      .subscribe((response: any) => {

        if (response != null && response.statusCode == 302) {

          var refund = 0;
          var currentDateRefund = 0;
          var lastMonthRefund = 0;
          var currentMonthRefund = 0;
          var date = new Date();
          var curDate = format(new Date(), "MM/DD/YYYY");
          var firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), "MM/DD/YYYY");
          var lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), "MM/DD/YYYY");
          var PreMonthLastDay = format(new Date(date.getFullYear(), date.getMonth(), 0), "MM/DD/YYYY");
          var PreMonthFirstDay = format(new Date(date.getFullYear() - (date.getMonth() > 0 ? 0 : 1), (date.getMonth() - 1 + 12) % 12, 1), "MM/DD/YYYY");
          response.data.forEach(a => this.refund += a.netAmount);
          response.data.forEach(function (item: any) {
            if (format(item.startDateTime, "MM/DD/YYYY") == curDate) {
              currentDateRefund += item.netAmount;
            }
          });
          response.data.forEach(function (item: any) {
            if (format(item.startDateTime, "MM/DD/YYYY") >= firstDay && format(item.startDateTime, "MM/DD/YYYY") <= lastDay) {
              currentMonthRefund += item.netAmount;
            }
          });
          response.data.forEach(function (item: any) {
            if (format(item.startDateTime, "MM/DD/YYYY") >= PreMonthFirstDay && format(item.startDateTime, "MM/DD/YYYY") <= PreMonthLastDay) {
              lastMonthRefund += item.netAmount;
            }
          });

          this.refund = refund;
          this.lastMonthRefund = lastMonthRefund;
          this.currentMonthRefund = currentMonthRefund;

        }
      });
  }

  gotoscheduler(month: any, appttype: any, apptmode: any) {

    this.route.navigate(["/web/scheduling"], { queryParams: { calendermonth: month, appttype: appttype, apptmode: apptmode } });
  }
  gotopayments() {
    this.route.navigate(["/web/payment/payment-history"]);
  }

  gotoschedulermonth(month: any) {

    this.route.navigate(["/web/scheduling"], { queryParams: { calendermonth: month } });
  }
  gotoschedulertoday(currentdayview: any) {

    this.route.navigate(["/web/scheduling"], { queryParams: { currentdayview: currentdayview } });
  }
  gotoschedulerTotal() {

    this.route.navigate(["/web/scheduling"]);
  }

  gotopaymentslastmonth(monthstatus: any) {
    this.route.navigate(["/web/payment/payment-history"], { queryParams: { monthstatus: monthstatus } });
  }

  hideMessageClick() {
    this.showMessage = false;
  }
  changeWidget(value: string = '') {
    if (value == 'authorization') {
      this.isAuth = true;
      this.headerText = 'Authorization';
    }
    else if (value == 'clientstatus') {
      this.getClientStatusChart();
    }
  }


  onTabChanged(event: any) {
    this.selectedIndex = event.value
    if (event.index == 0) {
      // this.getDataForAppointmentLineChart(this.filterParamsForAppointent);
      this.getEncounterListForDashboard();
    } else if (event.index == 1) {
      this.getPendignPatientAppointmentList();
    } else if (event.index == 2) {
      this.getCancelledPatientAppointmentList();
    }
    // else if (event.index == 3) {
    // } else if (event.index == 4) {
    //   this.getTentativePatientAppointmentList();
    // }
  }

  getEncounterListForDashboard() {
    this.dashoboardService.getEncounterListForDashboard(this.encFilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.encounterList = response.data != null && response.data.length > 0 ? response.data : [];
        this.encMeta = response.meta;
      }
    });
  }
  getPendignPatientAppointmentList() {

    this.dashoboardService.getPendingPatientAppointment(this.pendingAptfilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.pendingPatientAppointment = response.data != null && response.data.length > 0 ? response.data : [];
        this.pendingAppointmentMeta = response.meta;
      }
      else {
        this.pendingPatientAppointment = [];
        this.pendingAppointmentMeta = null;
      }
    })
  }

  getLastUrgentCareCallStatus() {

    this.schedulerService.getLastUrgentCareCallStatus(this.userId).subscribe(response => {
      if (response.statusCode === 200) {

        if (response.data != undefined) {
          this.urgentcareapptid = response.data.id;
          //this.hasPreviousNewMeeting = response.data ? true : false;
          this.showurgentcarebtn = true;
        }
        else {
          this.showurgentcarebtn = false;
        }

      }
    });
  }

  Redirect() {
    this.route.navigate(["/web/encounter/soap"], {
      queryParams: {
        apptId: this.urgentcareapptid,
        encId: 0
      },
    });
  }


  onPendingPageOrSortChange(changeState?: any) {
    this.setPendingPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order);
    this.getPendignPatientAppointmentList();
  }
  onCancelledPageOrSortChange(changeState?: any) {
    this.setCancelledPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order);
    this.getCancelledPatientAppointmentList();
  }
  setPendingPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string) {
    this.pendingAptfilterModel.pageNumber = pageNumber;
    this.pendingAptfilterModel.pageSize = pageSize;
    this.pendingAptfilterModel.sortOrder = sortOrder;
    this.pendingAptfilterModel.sortColumn = sortColumn;
  }
  setCancelledPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string) {
    this.cancelledAptfilterModel.pageNumber = pageNumber;
    this.cancelledAptfilterModel.pageSize = pageSize;
    this.cancelledAptfilterModel.sortOrder = sortOrder;
    this.cancelledAptfilterModel.sortColumn = sortColumn;
  }
  getCancelledPatientAppointmentList() {
    this.dashoboardService.getCancelledPatientAppointment(this.cancelledAptfilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.cancelledPatientAppointment = response.data != null && response.data.length > 0 ? response.data : [];
        this.cancelledAppointmentMeta = response.meta;
      }
      else {
        this.cancelledPatientAppointment = [];
        this.cancelledAppointmentMeta = null;
      }
    })
  }
  getPasswordExpiryMessage() {
    this.subscription = this.commonService.loginUser.subscribe((user: LoginUser) => {
      if (user.passwordExpiryStatus) {
        this.passwordExpiryColorCode = user.passwordExpiryStatus.colorCode;
        this.passwordExpiryMessage = user.passwordExpiryStatus.message;
        this.status = user.passwordExpiryStatus.status;
      }
    });
  }

  getDashboardBasicInfo() {
    this.dashoboardService.getDashboardBasicInfo().subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.dashboardData = response.data != null ? response.data : new DashboardModel();
        this.consultanttotalrevenu = this.dashboardData.appointmentTotalRevenue.totalRevenue;
      }
    });8
  }
  getClientStatusChart() {
    this.dashoboardService.getClientStatusChart().subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.isAuth = false;
        this.headerText = 'Patient Status';
        this.clientStatusChartData = response.data != null ? response.data : new ClientStatusChartModel();
        if (this.clientStatusChartData.length > 0) {
          this.lineChartLabels = this.clientStatusChartData.map(({ registeredDate }) => format(registeredDate, 'MM/DD/YYYY'));
          this.lineChartData = [
            { data: this.clientStatusChartData.map(({ active }) => active), label: 'Active' },
            { data: this.clientStatusChartData.map(({ inactive }) => inactive), label: 'Inactive' },
          ];
        }
      }
    });
  }
  onEncPageOrSortChange(changeState?: any) {
    this.setEncPaginatorModel(changeState.pageNumber, this.encFilterModel.pageSize, changeState.sort, changeState.order);
    this.getEncounterListForDashboard();
  }

  onEncTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.id;
    const name = actionObj.data && actionObj.data.name,
      appointmentId = actionObj.data && actionObj.data.patientAppointmentId,
      patientEncounterId = actionObj.data && actionObj.data.id,
      isBillableEncounter = actionObj.data && actionObj.data.isBillableEncounter;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'VIEW':
        const redirectUrl = isBillableEncounter ? "/web/waiting-room/" : "/web/encounter/non-billable-soap";
        if (isBillableEncounter) {
          this.route.navigate(["/web/waiting-room/" + appointmentId]);
        } else {
          this.route.navigate([redirectUrl], {
            queryParams: {
              apptId: appointmentId,
              encId: patientEncounterId
            }
          });
        }
        break;
      default:
        break;
    }
  }
  setEncPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string) {
    this.encFilterModel.pageNumber = pageNumber;
    this.encFilterModel.pageSize = pageSize;
    this.encFilterModel.sortOrder = sortOrder;
    this.encFilterModel.sortColumn = sortColumn;
  }

  getStaffProfileData() {

    this.userService.getStaffProfileData(this.staffId).subscribe((response: ResponseModel) => {
      if (response != null && response.data != null) {

        this.staffProfile = response.data;
      }
    });
  }

  getStaffReviewData() {

    this.userService.getReviewRatingById(this.staffId, this.reviewRatingFilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.data != null) {

        //this.staffProfile = response.data;
      }
    });
  }

  opentermconditionmodal() {
    let dbModal;
    dbModal = this.dialogModal.open(UrgentCareListingdialogComponent, {
      hasBackdrop: true,
      width: '60%'
    });
    dbModal.afterClosed().subscribe((result: string) => {
      // 
      if (result != null && result != "close") {

      }
      //this.show=true;
    });
  }
  setPaginatorModel(

  ) {
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 1000;
    this.filterModel.sortOrder = "";
    this.filterModel.sortColumn = "";
    this.filterModel.PatientName = "";
    this.filterModel.RefundDate = "";
    this.filterModel.AppDate = "";
  }
  collapse1() {
    if (this.collapse1tf == false) {
      document.getElementById("collapse1").classList.remove("hide")
      document.getElementById("collapse2").classList.add("hide")
      this.collapse1tf = true
      this.collapse2tf = false
    } else {
      this.collapse1tf = false
      this.collapse2tf = false
      document.getElementById("collapse1").classList.add("hide")
      document.getElementById("collapse2").classList.add("hide")
    }

  }
  collapse2() {
    if (this.collapse2tf == false) {
      document.getElementById("collapse2").classList.remove("hide")
      document.getElementById("collapse1").classList.add("hide")
      this.collapse2tf = true
      this.collapse1tf = false
    } else {
      this.collapse2tf = false
      this.collapse1tf = false
      document.getElementById("collapse1").classList.add("hide")
      document.getElementById("collapse2").classList.add("hide")
    }

  }
  editProfile() {
    this.route.navigate(["/web/manage-users/user-profile"]);
  }
}

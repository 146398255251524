import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FilterVideoModel, MediaFilterModel, Metadata, ResponseModel } from '../../../core/modals/common-model';
import { Router } from '@angular/router';
import { UsersService } from '../../users/users.service';
import { CompanyService } from '../../manage-company/company.service';
import { S3 } from 'aws-sdk';

@Component({
  selector: 'app-list-video',
  templateUrl: './list-video.component.html',
  styleUrls: ['./list-video.component.css']
})
export class ListVideoComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  dataexcel: Array<any>[];

  organizationId: number;
  metaData: any;
  searchText: string = "";
  filterModel: MediaFilterModel;
  filterVideoModel: FilterVideoModel;
  agencyData: any;
  animal: string;
  searchModel:{SearchKey:string,};
  s3 :any;


   displayedColumns: Array<any> = [
    { displayName: 'Media Title', key: 'title', class: '', width: '35%' },
    { displayName: 'Media Type', key: 'mediaType', class: '', width: '35%' },
    // { displayName: 'Course Type', key: 'courseType', class: '', width: '35%' },
    // { displayName: 'Module', key: 'moduleId', class: '', width: '25%' },
    // { displayName: 'Week', key: 'weekId', class: '', width: '15%' }
  ];
  constructor(
    private router: Router,
    private userservice: UsersService,
    private _companyService: CompanyService) { 
       this.filterModel = new MediaFilterModel();
    this.filterVideoModel = new FilterVideoModel();
    }

     @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit() {
      this.getHistoryList();
      this.s3 = new S3({ accessKeyId: 'AKIAXKJZXKIQ4RXO6E6X', 
      secretAccessKey: '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', 
      region: 'eu-west-2' });
  }


   onPageOrSortChange(changeState?: any) {
    //this.setPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getHistoryList();
  }

  //  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
  //   this.filterModel.pageNumber = pageNumber;
  //   this.filterModel.pageSize = pageSize;
  //   this.filterModel.sortOrder = sortOrder;
  //   this.filterModel.sortColumn = sortColumn;
  //   this.filterModel.searchText = searchText;
  // }

  // applyFilter(searchText: string = '') {
  //   this.filterModel.searchText==this.searchText;
  //   if (searchText == '' || searchText.length > 2) {
  //     this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
  //     this.getHistoryList();
  //   }
  // }

   getHistoryList() {
    //this.filterModel.searchText==this.searchText;
    // var id = Number(localStorage.getItem('podcastId'));
    this.userservice.getVideo(this.filterModel).subscribe((response: ResponseModel) => {
      if (response && response.statusCode == 200) {
        
        this.agencyData = response.data;
        this.metaData = response.meta;
      }
      else {
        this.agencyData = [];
        this.metaData = new Metadata();
      }
      
      //this.metaData.pageSizeOptions = [5, 10, 25, 50, 100];
    });

  }
    clearFilters() {
    this.searchText = "";
    //this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, '');
    this.getHistoryList();
  }

}

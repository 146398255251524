import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { AppService } from "src/app/app-service.service";
import { CallInitModel, CallStatus } from "../models/callModel";
import { CommonService } from "src/app/platform/modules/core/services";
import { Router } from "@angular/router";
import { Observable, Subscription, Subject } from "rxjs";
import { DOCUMENT } from '@angular/common';
@Component({
  selector: "app-call-button",
  templateUrl: "./call-button.component.html",
  styleUrls: ["./call-button.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CallButtonComponent implements OnInit {
  callInitModel: CallInitModel;
  loggedInUserName: any;

  private subscription: Subscription;
  constructor(
    private appService: AppService,
    private commonService: CommonService,
    private router: Router,
    @Inject(DOCUMENT) private _document: Document
  ) {
    console.log(" 40 call button constructor ")
    this.callInitModel = new CallInitModel();
  }

  ngOnInit() {
    console.log(" 50 call button ngoninit")
    this.appService.call.subscribe((callInitModel: CallInitModel) => {

      this.callInitModel = callInitModel;
    });
    this.checkLoggedInClient();


  }
  checkLoggedInClient() {
    this.subscription = this.commonService.loginUser.subscribe(
      (user: any) => {

        if (user.data) {
          console.log(user.data, "data");
          localStorage.setItem('Address', user.patientInformation ? user.patientInformation.address : null);
          localStorage.setItem('categoryType', user.data.categoryType);
          localStorage.setItem('patientId', user.data.id);
          localStorage.setItem('NICNo', user.data.niC_number);
          localStorage.setItem('CompanyId', user.data.company_id);
          localStorage.setItem('UserId', user.data.userID);

          const userRoleName =
            user.data.users3 && user.data.users3.userRoles.userType;
          if ((userRoleName || "").toUpperCase() === "CLIENT" || (userRoleName || "").toUpperCase() === "PATIENT") {

            this.loggedInUserName = user.patientData ? user.patientData.firstName + " " + user.patientData.lastName : '';
          }
          else {
            this.loggedInUserName = user.data.firstName + ' ' + user.data.lastName;
          }

        }
      })
  }

  pickCall() {
    console.log("9 pick call  called from call button");

    this.callInitModel.CallStatus = CallStatus.Picked;
    this.appService.CheckCallStarted(this.callInitModel);
    this.commonService.userRole.subscribe((role) => {
      if (role.toLowerCase() == "provider" || role.toLowerCase() == "health consultant") {
        this.reloadClient();

        //this.router.navigate(["/web/waiting-room/check-in-video-call/"+this.callInitModel.AppointmentId]);
      } else {


        // this.router.navigateByUrl("/web/waiting-room/check-in-video-call/"+this.callInitModel.AppointmentId)
        // .then(()=>{this.reloadComponent()});

        this.reloadComponent();

      }
    });
  }
  reloadClient() {
    // let currentUrl = "/web/waiting-room/check-in-video-call/"+this.callInitModel.AppointmentId;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(["/web/encounter/soap"], {
      queryParams: {
        apptId: this.callInitModel.AppointmentId,
        encId: 0,
      },
    });
  }
  reloadComponent() {
    let currentUrl = "/web/waiting-room/check-in-soap/" + this.callInitModel.AppointmentId;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  ngOnDestroy() {
    // window.location.reload();
  }

  declineCall() {
    console.log("8 declient call  called from call button");
    if (this.callInitModel.AppointmentId > 0 && Number(localStorage.getItem("UserId")) > 0) {
      console.log("inside publisher method")
      this.appService
        .getEndCall(this.callInitModel.AppointmentId, Number(localStorage.getItem("UserId")))
        .subscribe((res) => {
          console.log(res);
        });
    }
    this.callInitModel.CallStatus = CallStatus.Declined;
    this.callInitModel.AppointmentId = 0;
    this.appService.CheckCallStarted(this.callInitModel);



  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-booking-type-model',
  templateUrl: './booking-type-model.component.html',
  styleUrls: ['./booking-type-model.component.css']
})
export class BookingTypeModelComponent implements OnInit {
  patientData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogModelRef: MatDialogRef<BookingTypeModelComponent>,
  ) {
    this.patientData = data;
  }

  ngOnInit() {
  }

  reDirectTo(type: string) {
    let url = '';
    // url = type === 'telehealth' ? 'telehealth' : 'secondOpinion'
    url = type === 'telehealth' ? 'telehealth' : 'telehealth'
    this.closeDialog(url);
  }
  closeDialog(action: string): void {
    this.dialogModelRef.close(action);
  }
  onCloseClick() {
    this.dialogModelRef.close();
  }
}

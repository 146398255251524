import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as XLSX from 'xlsx';
import { ResponseModel } from '../../../core/modals/common-model';
import { ClientsService } from '../../clients/clients.service';
import { CompanyService } from '../../manage-company/company.service';
import { companyFormsModel } from '../../manage-company/manage-company.model';
import { UserCreateModel } from '../users.model';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})
export class BulkUploadComponent implements OnInit {
  dataexcel: Array<any>[];
  animal: string;
  dataexcellength: number = 0;
  clientModel: UserCreateModel;
  url: any;
  constructor(public dialogRef: MatDialogRef<BulkUploadComponent>, private clientService: UsersService) {
    this.clientModel = new UserCreateModel();
  }

  ngOnInit() {
    this.url = window.location.pathname;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onFileChange(ev) {
    this.dataexcel = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
      this.dataexcel = jsonData.Consultant;

      if (this.dataexcel == undefined) {
        document.getElementById("tableuser").classList.add("hide");
        document.getElementById("bulkcreate").classList.add("hide");
      }
      this.dataexcellength = this.dataexcel.length
      console.log("value", this.dataexcellength)
      if (this.dataexcellength != 0) {
        document.getElementById("tableuser").classList.remove("hide");
        document.getElementById("bulkcreate").classList.remove("hide");
      }
      //document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      //this.setDownload(dataString);
    }
    reader.readAsBinaryString(file);
  }
  bulkdispaly() {
    document.getElementById("dialogheaderbulk").classList.remove("hide");
  }
  createnewuser() {
    this.dataexcel.forEach((data: any) => {
      this.clientModel.email = data.EmailID;
      this.clientModel.firstName = data.FirstName;
      this.clientModel.lastName = data.LastName;
      this.clientModel.userName = data.EmailID;
      this.clientModel.isActive = true;
      this.clientModel.staffName = data.FirstName + " " + data.LastName;
      this.clientModel.experience = data.Experience;
      this.clientModel.bio = data.Bio;
      this.clientModel.phoneNumber = data.MobileNumber;
      this.clientModel.roleID = 331;
      this.clientService.createstaff(this.clientModel).subscribe((response: ResponseModel) => {
        console.log("message", response)
        if(response.statusCode == 200){
          window.location.reload;
        }
      });
      
      // this._companyService.addCompany(this.companyFormsData).subscribe((res: any) => {
      //   console.log("message",res)
      // })
    });
  }
}


export class ProvideViewRatingModel {
    id: number;
    cunsultantName:string;
    corporateUserName:string; 
    rating: number; 
    review: string;
    description:string;
    isPublic:boolean;
    patientAppointmentId:number;
}

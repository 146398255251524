import { BookAppointmentComponent } from "./../book-appointment/book-appointment.component";
import { MatDatepickerInputEvent, MatDialog } from "@angular/material";
import { HomeService } from "./../home/home.service";
import { CommonService } from "src/app/platform/modules/core/services";
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { DatepickerOptions } from "ng2-datepicker";
import * as enLocale from "date-fns/locale/en";
import { ActivatedRoute, Router } from "@angular/router";
import { ProviderSearchFilterModel } from "src/app/platform/modules/core/modals/common-model";
import { LoginModelComponent } from "src/app/shared/login-model/login-model.component";
import { HomeHeaderComponent } from "src/app/front/home-header/home-header.component";
import { BookFreeAppointmentComponent } from "../book-freeappointment/book-freeappointment.component";
import { ProviderProfileModalComponent } from "../provider-profile/provider-profile.component";
import { LabelType, Options } from "@angular-slider/ngx-slider";
import { CancelationRule } from "src/app/platform/modules/agency-portal/Payments/payment.models";
import { ProviderFeeSettingsComponent } from "../provider-fee-settings/provider-fee-settings.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { isNullOrUndefined } from "util";


@Component({
  providers: [HomeHeaderComponent],
  selector: "app-doctor-list",
  templateUrl: "./filter-doctor-list.component.html",
  styleUrls: ["./filter-doctor-list.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class FilterDoctorListComponent implements OnInit, AfterViewInit {
  panelOpenState = false;
  specialityIconCheck: any;
  data: any[] = [];
  specialityIcon: any;
  showLoader: boolean = true;
  selectedSpeciality: any;
  selectedLocation: any;
  selectedDate: any;
  @ViewChild('tempselectedDate') tempselectedDate: ElementRef;
  @ViewChildren('temprating') temprating: QueryList<any>;
  providerFilterFormGroup: FormGroup;
  todayDate: Date = new Date();

  providerserachtext: string;
  doctorWidgetClass: string = "col-sm-6 grid-profile";
  options: DatepickerOptions;
  metaData: any;
  sortby: any[] = [
    { value: 1, label: "Price Low" },
    { value: 2, label: "Price High" },
    { value: 3, label: "Rating" }
  ];

  location: any[] = [];
  speciality: any[] = [];
  services: any[] = [];
  minIndex: number = 0;
  maxIndex: number = 10;
  minIndexService: number = 0;
  maxIndexService: number = 10;
  filterModel: ProviderSearchFilterModel;
  providers: any = [];
  totalRecords: number = 0;
  searchedLocation: string = "";
  masterGender: any = [];

  Slider: Options = {
    floor: 0,
    ceil: 1000,
    translate: (value: number, label: LabelType): string => {
      return "USD " + value;
    }
  };
  minrate = 0;
  maxrate = 1000;

  checkedLocations: string[] = [];
  checkedSpecialities: string[] = [];
  checkedGenders: string[] = [];
  checkedServices: string[] = [];
  checkedRates: string[] = [];
  reviewRates: string[] = [];
  @ViewChild('prosearchtext') prosearchtext: ElementRef;
  staffId: string;
  isSecondOpinion: boolean;
  typeOfAppt: any;
  list: any;
  submitted: boolean;
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private dialogModal: MatDialog,
    private headerComp: HomeHeaderComponent,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.commonService.initializeAuthData();
    let dte = new Date();
    dte.setDate(dte.getDate() - 1);
    this.options = {
      minYear: 1970,
      maxYear: 2040,
      displayFormat: "MMM D[,] YYYY",
      barTitleFormat: "MMMM YYYY",
      dayNamesFormat: "dd",
      firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
      locale: enLocale,
      minDate: dte, // Minimal selectable date
      //maxDate: new Date(Date.now()),  // Maximal selectable date
      barTitleIfEmpty: "Click to select a date",
      placeholder: "Select Date", // HTML input placeholder attribute (default: '')
      fieldId: "my-date-picker", // ID to assign to the input field. Defaults to datepicker-<counter>
      useEmptyBarTitle: false // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown
    };
    this.filterModel = new ProviderSearchFilterModel();
  }

  ngAfterViewInit(): void {
    this.searchInitilizer();
  }

  ngOnInit() {
    window.scroll(0, 0)
    this.getMasterData();
    // Filter Form
    this.providerFilterFormGroup = this.formBuilder.group({
      search: [""],
      date: [""],
      specialities: [""],
      services: [""],
      reviewRating: [""],
      rates: [""],
      gender: [""]
    });
    this.route.queryParams.subscribe(params => {
      this.staffId = params["providerId"];
      this.specialityIcon = params["searchTerm"];
      this.typeOfAppt = params["type"];
      if (
        params["loc"] != "" &&
        params["loc"] != null &&
        params["loc"] != undefined
      ) {
        this.checkedLocations.push(
          this.commonService.encryptValue(params["loc"], false)
        );
      }
      this.filterModel.Date = params["d"];
    });
  }

  // Control of filter form
  get f() {
    return this.providerFilterFormGroup.controls;
  }

  public isKeySet(obj: any, key: string): boolean {
    let isFound = false;
    obj.forEach(element => {
      if ((element as string) == key.toString()) isFound = true;
    });
    return isFound;
  }

  setPaginatorModel(
    pageNumber: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string
  ) {
    let date = null;
    if (
      this.selectedDate != undefined &&
      this.selectedDate != null &&
      this.selectedDate != ""
    ) {
      date = new Date(this.selectedDate);
      //date.setDate(date.getDate() + 1);
    }
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;

    this.filterModel.MinRate = this.minrate.toString()
    this.filterModel.ProvidersearchText = isNullOrUndefined(this.f.search.value) ? "" : this.f.search.value;
    this.filterModel.Date = isNullOrUndefined(this.f.date.value) ? "" : this.f.date.value;
    this.filterModel.Specialities = isNullOrUndefined(this.f.specialities.value) ? "" : this.f.specialities.value.toString();
    this.filterModel.Services = isNullOrUndefined(this.f.services.value) ? "" : this.f.services.value.toString();
    this.filterModel.ReviewRating = isNullOrUndefined(this.f.reviewRating.value) ? "" : this.f.reviewRating.value.toString();
    this.filterModel.Rates = isNullOrUndefined(this.f.rates.value) ? "" : this.f.rates.value;
  }
  onSpecialityChange(event) {
    this.filterModel.ProviderId = '';
    this.staffId = '';

    if (!event) {
      if (this.f.specialities.value) {
        this.getMasterData(this.f.specialities.value, 1)
        // this.applyFilter(true);
      }
      else {
        this.checkedServices = [];
        this.clearFilter();
      }
    }
  }

  onServiceChange(event) {
    this.filterModel.ProviderId = '';
    this.staffId = '';

    if (!event) {
      this.filterModel.Services = isNullOrUndefined(this.f.services.value) ? "" : this.f.services.value.toString();
      this.applyFilter(true);
    }
  }

  onGenderChange(option, event) {
    this.filterModel.ProviderId = '';
    this.staffId = '';
    // this.prosearchtext.nativeElement.value = '';
    if (event.source.checked) {
      this.checkedGenders.push(option.id.toString());
    } else {
      for (var i = 0; i < this.checkedGenders.length; i++) {
        if (this.checkedGenders[i] == option.id) {
          this.checkedGenders.splice(i, 1);
        }
      }
    }
    this.applyFilter(true);
  }

  onConsultationFeeChange(event) {

    this.filterModel.ProviderId = '';
    this.staffId = '';
    // this.prosearchtext.nativeElement.value = '';
    this.checkedRates = [this.maxrate.toString()];

    this.applyFilter(false);
  }

  onReviewRatingChange(event) {
    this.filterModel.ProviderId = '';
    this.staffId = '';

    if (!event) {
      this.filterModel.ReviewRating = isNullOrUndefined(this.f.reviewRating.value) ? "" : this.f.reviewRating.value;
      this.applyFilter(true);
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    if (!event) {
      this.f.payDate.setValue(new Date(event.value));
      this.applyFilter(true);
    }
  }

  clearFilter() {
    this.providerFilterFormGroup.reset();
    // this.prosearchtext.nativeElement.value = '';
    this.checkedGenders = [];
    this.checkedRates = [];
    this.checkedServices = [];
    this.checkedSpecialities = [];
    this.selectedSpeciality = [];
    this.reviewRates = [];
    // this.tempselectedDate['displayValue'] = '';
    this.temprating.forEach(element => {
      element.checked = false;
    });

    // To reset consultation fees slider
    this.maxrate = 1000;
    this.minrate = 0;

    this.applyFilter(false);
  }

  applyFilter(isSearching: boolean = true) {
    if (this.providerFilterFormGroup.invalid)
      return
    else {
      this.setPaginatorModel(
        this.filterModel.pageNumber,
        this.filterModel.pageSize,
        this.filterModel.sortColumn,
        this.filterModel.sortOrder
      );
      this.getProviderList(this.filterModel, isSearching);
    }
  }
  searching: boolean = false;
  clearing: boolean = false;

  getProviderList(
    filterModel: ProviderSearchFilterModel,
    Isflitering: boolean = false
  ) {
    if (Isflitering) this.searching = true;
    else this.clearing = true;
    this.homeService.getProviderList(filterModel).subscribe((response: any) => {
      this.filterModel.ProviderId = '';
      this.staffId = '';
      this.isSecondOpinion = false;
      this.providers = [];
      this.metaData = [];
      this.totalRecords = 0;
      if (response != null && response.statusCode == 200) {
        if (this.searching != false) {
          if (this.typeOfAppt && (this.typeOfAppt === 'secondOpinion = true' || this.typeOfAppt === 'secondOpinion')) {
            this.list = response.data;
            for (var i = 0; i < this.list.length; i++) {
              if (this.list[i].isSecondOpinion == true) {
                this.providers.push(this.list[i]);
                this.isSecondOpinion = true;
              }
            }
          } else {
            if (this.filterModel.Services != "" || this.filterModel.Specialities != "" || this.filterModel.Date != null || this.filterModel.searchText != "" || this.filterModel.ReviewRating != "") {
              this.providers = response.data;
            }
          }
          if (localStorage.getItem("symptoms") != null) {
            this.filterProvider(localStorage.getItem("symptoms"), response.data);
          }
          this.metaData = response.meta;
          //this.totalRecords = this.metaData.totalRecords;
          this.totalRecords = this.providers.length;
        } else {
          //this.userInvitationModel = []; this.metaData = new Metadata();
        }
      }
      this.showLoader = false;
      this.searching = false;
      this.clearing = false;
    });

  }
  filterProvider(symptoms: any, resp: any) {
    resp.forEach((value, index) => {
      if (value.keyword != null && value.keyword != undefined) {
        var arr = value.keyword.split(',').map(function (v) {
          return v.toLowerCase();
        });;
        if (!symptoms.split(',').some(ai => arr.includes(ai))) {
          this.providers.splice(index, 1);
        }
      }
      else {
        this.providers.splice(index, 1);
      }
    });
    localStorage.removeItem("symptoms");
  }

  getMasterData(globalCodeId: any[] = [], requestType: any = 0) {
    this.route.queryParams.subscribe(params => {

      var prvSelectedValue = this.commonService.encryptValue(params["sp"], false)
      var prvSelectedService = this.commonService.encryptValue(params["srvc"], false)
      if (prvSelectedService != '' && prvSelectedService != null && this.checkedServices.length == 0 && requestType == 0) {
        this.checkedServices.push(prvSelectedService);
      }
      if (prvSelectedValue != '' && prvSelectedValue != null && globalCodeId.length == 0 && requestType == 0) {
        // this.checkedSpecialities.push(prvSelectedValue);
        //globalCodeId.push(prvSelectedValue);
      }
    });


    this.homeService
      .getMasterData(
        // "masterLocation,MASTERTAXONOMY,MASTERSTAFFSERVICE,MASTERSPECIALITY,MASTERGENDER", true, this.checkedSpecialities
        "masterLocation,MASTERTAXONOMY,MASTERSTAFFSERVICE,MASTERSPECIALITY,MASTERGENDER", true, globalCodeId
      )
      .subscribe((response: any) => {
        if (response != null) {
          this.masterGender =
            response.masterGender != null ? response.masterGender : [];
          // this.speciality =
          //   response.masterTaxonomy != null ? response.masterTaxonomy : [];
          this.speciality =
            response.masterSpeciality != null ? response.masterSpeciality : [];
          this.location =
            response.masterLocation != null ? response.masterLocation : [];
          this.services =
            response.masterStaffServices != null
              ? response.masterStaffServices
              : [];

          // this.speciality.push({ id: 0, value: "More specialities can be populated on request" });
          // this.services.push({ id: 0, value: "More services can be populated on request" });

          this.route.queryParams.subscribe(params => {
            if (
              params["loc"] != "" &&
              params["loc"] != null &&
              params["loc"] != undefined
            ) {
              this.selectedLocation = +this.commonService.encryptValue(
                params["loc"],
                false
              ); //

              this.searchedLocation = this.location.find(
                x => (x.id = this.selectedLocation)
              ).locationName;
            }
            this.selectedDate = params["d"]; // this.datePipe.transform(params['d'], 'yyyy-MM-dd');
          });
        }
        if (!this.specialityIcon) {
          this.applyFilter();
        }
        else {
          this.Providersearchtext(this.specialityIcon);
        }
      });
  }
  bookAppointment(staffId: number, providerId: string) {
    let dbModal;
    let encryptedStaffId = this.commonService.encryptValue(staffId);
    if (!localStorage.getItem("access_token")) {
      dbModal = this.dialogModal.open(LoginModelComponent, {
        hasBackdrop: true,
        data: { selectedIndex: 1 }
      });
      dbModal.afterClosed().subscribe((result: any) => {

        let response = result.response;
        if (
          response.statusCode >= 400 &&
          response.statusCode < 500 &&
          response.message
        ) {
          //this.errorMessage = response.message;
          this.headerComp.loading = false;
        } else if (response.statusCode === 205) {
          //this.errorMessage = response.message;
          this.headerComp.loading = false;
        } else if (response.access_token) {

          if (this.typeOfAppt && this.typeOfAppt === 'secondOpinion') {
            this.router.navigate(['/web/client/second-opinion/booking'], { queryParams: { id: encryptedStaffId } });
          } else {
            this.openDialogBookAppointment(staffId, providerId);
          }
          //}
        } else if (this.typeOfAppt && this.typeOfAppt === 'secondOpinion') {
          this.router.navigate(['/web/client/second-opinion/booking'], { queryParams: { id: encryptedStaffId } });
        } else {
          this.headerComp.openDialogSecurityQuestion();
        }

      });
    } else if (this.typeOfAppt && this.typeOfAppt === 'secondOpinion') {
      this.router.navigate(['/web/client/second-opinion/booking'], { queryParams: { id: encryptedStaffId } });
    } else {
      this.openDialogBookAppointment(staffId, providerId);
    }
    //let staffId = this.commonService.encryptValue(id);
  }
  openDialogBookAppointment(staffId: number, providerId: string) {
    let dbModal;
    dbModal = this.dialogModal.open(BookAppointmentComponent, {
      hasBackdrop: true,
      data: {
        staffId: staffId,
        userInfo: null,
        providerId: providerId,
        locationId: this.selectedLocation || 0
      },
      width: "80%"
    });
    dbModal.afterClosed().subscribe((result: string) => {
      if (result != null && result != "close") {
        // if (result == "booked") {
        // }
        //location.reload();
      }
    });
  }

  redirectToProfilePage(staffId: number, providerId: string) {
    let dbModal;
    dbModal = this.dialogModal.open(ProviderProfileModalComponent, {
      hasBackdrop: true,
      data: { id: providerId, apptType: this.typeOfAppt ? this.typeOfAppt : '', decryptedStaffId: staffId },
      width: '70%'
    });
    dbModal.afterClosed().subscribe((result: string) => {
      if (result != null && result != "close") {

      }
    });
  }

  bookFreeAppointment(staffId: number, providerId: string) {
    let dbModal;
    if (!localStorage.getItem("access_token")) {
      dbModal = this.dialogModal.open(LoginModelComponent, {
        hasBackdrop: true,
        data: { selectedIndex: 1 }
      });
      dbModal.afterClosed().subscribe((result: any) => {
        let response = result.response;
        if (
          response.statusCode >= 400 &&
          response.statusCode < 500 &&
          response.message
        ) {
          //this.errorMessage = response.message;
          this.headerComp.loading = false;
        } else if (response.statusCode === 205) {
          //this.errorMessage = response.message;
          this.headerComp.loading = false;
        } else if (response.access_token) {

          location.reload();

        } else {
          this.headerComp.openDialogSecurityQuestion();
        }

      });
    } else {
      this.openDialogBookFreeAppointment(staffId, providerId);
    }
    //let staffId = this.commonService.encryptValue(id);
  }
  openDialogBookFreeAppointment(staffId: number, providerId: string) {
    let dbModal;
    dbModal = this.dialogModal.open(BookFreeAppointmentComponent, {
      hasBackdrop: true,
      data: {
        staffId: staffId,
        userInfo: null,
        providerId: providerId,
        locationId: this.selectedLocation || 0
      }
    });
    dbModal.afterClosed().subscribe((result: string) => {
      if (result != null && result != "close") {
        if (result == "booked") {
        }
      }
    });
  }

  onSortChange(event) {

    this.filterModel.SortType = event.value.toString();
    this.applysortFilter(true);
  }

  applysortFilter(isSearching: boolean = true) {

    this.setPaginatorModel(
      this.filterModel.pageNumber,
      this.filterModel.pageSize,
      this.filterModel.sortColumn,
      this.filterModel.sortOrder
    );
    this.getSortProviderList(this.filterModel, isSearching);
  }
  sortsearching: boolean = false;
  sortclearing: boolean = false;
  getSortProviderList(
    filterModel: ProviderSearchFilterModel,
    Isflitering: boolean = false
  ) {

    if (Isflitering) this.sortsearching = true;
    else this.sortclearing = true;

    this.homeService.getSortProviderList(filterModel).subscribe((response: any) => {
      this.filterModel.ProviderId = '';
      this.staffId = '';
      this.providers = [];
      this.metaData = [];
      this.totalRecords = 0;
      if (response != null && response.statusCode == 200) {
        //this.providers = response.data;

        this.metaData = response.meta;
        this.totalRecords = this.metaData.totalRecords;
      } else {
        //this.userInvitationModel = []; this.metaData = new Metadata();
      }
      this.showLoader = false;
      this.searching = false;
      this.clearing = false;
    });
  }


  searchInitilizer() {
    // fromEvent(this.prosearchtext.nativeElement, 'keyup').pipe(

    //   map((event: any) => {
    //     return event.target.value;
    //   })
    //   , filter(res => res.length > 2 || res.length == 0)
    //   , debounceTime(1000)
    //   , distinctUntilChanged()
    // ).subscribe((text: string) => {

    //   this.Providersearchtext(text);
    // })
  }

  Providersearchtext(text?: string) {

    // this.filterModel.ProvidersearchText = text;
    this.filterModel.ProvidersearchText = isNullOrUndefined(this.f.search.value) ? "" : this.f.search.value;
    this.filterModel.ProviderId = '';
    this.homeService.getsearchtextProviderList(this.filterModel).subscribe((response: any) => {
      this.providers = [];
      this.metaData = [];
      this.totalRecords = 0;
      if (response != null && response.statusCode == 200) {
        if (this.typeOfAppt && (this.typeOfAppt === 'secondOpinion = true' || this.typeOfAppt === 'secondOpinion')) {
          this.list = response.data;
          for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].isSecondOpinion == true) {
              this.providers.push(this.list[i]);
              this.isSecondOpinion = true;
            }
          }
        } else {
          if (this.filterModel.Services != "" || this.filterModel.Specialities != "" || this.filterModel.Date != null || this.filterModel.searchText != "" || this.filterModel.ReviewRating != "") {
            this.providers = response.data;
          }
        }
        // this.providers = response.data;

        this.metaData = response.meta;
        // this.totalRecords = this.metaData.totalRecords;
        this.totalRecords = this.providers.length;
      } else {
        //this.userInvitationModel = []; this.metaData = new Metadata();
      }
      this.showLoader = false;
      this.searching = false;
      this.clearing = false;
    });
  }

  openCancellationRulesPopup(rules) {
    rules = rules ? rules : [];
    const dbModal = this.dialogModal.open(ProviderFeeSettingsComponent, {
      hasBackdrop: true,
      data: rules as CancelationRule[],
      width: '400px',
      maxWidth: '400px',
      minWidth: '40%'
    });
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: true,
   //api_url: "https://mscore.stagingsdei.com:9163"   //local
   // api_url: "https://mscore.stagingsdei.com:9161" //staging
   api_url: "https://api.letsbe.me" //production
   //api_url: "http://localhost:5002" //Local
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

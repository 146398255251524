import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-manage-package',
  templateUrl: './manage-package.component.html',
  styleUrls: ['./manage-package.component.css']
})
export class ManagePackageComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ManagePackageComponent>,
  ) { }

  ngOnInit() {
  }
  onNoClick(): void {
    localStorage.setItem('membershipid', '')
    this.dialogRef.close();
  }
  basicplan() {
    localStorage.setItem('membershipid', '1');
    this.dialogRef.close();
  }
  silverplan() {
    localStorage.setItem('membershipid', '2');
    this.dialogRef.close();
  }
  goldplan() {
    localStorage.setItem('membershipid', '3');
    this.dialogRef.close();
  }
}

import { SchedulingRoutingModule } from './../../scheduling/scheduling-routing.module';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { FilterModel, ResponseModel } from '../../../../super-admin-portal/core/modals/common-model';
import { EncounterModel, AuthorizationModel, DashboardModel, ClientStatusChartModel } from './dashboard.model';
import { FilterMemberShipModel, Metadata } from '../../core/modals/common-model';
import { Router } from '@angular/router';
import { CommonService } from '../../core/services';
import { DatePipe } from "@angular/common";
import { merge, Subscription, Subject } from 'rxjs';

import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { format, isSameMonth } from 'date-fns';
import { AuthenticationService } from '../../../../super-admin-portal/auth/auth.service';
import { LoginUser } from '../../core/modals/loginUser.modal';
import { SchedulerService } from '../../scheduling/scheduler/scheduler.service';
import { UsersService } from '../users/users.service';
import { StaffProfileModel } from '../users/users.model';
import { UrgentCareListingdialogComponent } from 'src/app/shared/urgentcarelisting-dialog/urgentcarelisting-dialog.component';
import { RefundFilterModel } from "../../core/modals/common-model";
import { PaymentService } from "./../../agency-portal/Payments/payment.service";
import { CalendarDayViewHourSegmentComponent } from 'angular-calendar/modules/day/calendar-day-view-hour-segment.component';
import * as Chart from 'chart.js';
import { utils } from 'protractor';
import { ManageNgoService } from '../manage-ngo/manage-ngo.service';
import { CompanyService } from '../manage-company/company.service';
import { ClientsService } from '../clients/clients.service';
import { MembershipService } from '../membership-plan/membership.service';
import { AppointmentGraphService } from 'src/app/shared/appointment-graph/appointment-graph.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe]

})
export class DashboardComponent implements OnInit {
  private getAllURL = "Staffs/GetStaffs";
  userrolename: any;
  membershipcount: number = 0;
  averageStar: any;
  encFilterModel: FilterModel;
  reviewRatingFilterModel: FilterModel;
  filterModel: RefundFilterModel;
  filtermemberModel: FilterMemberShipModel;
  encounterList: Array<EncounterModel> = [];
  dashboardData: DashboardModel;
  clientStatusChartData: Array<ClientStatusChartModel> = [];
  encMeta: Metadata;
  subscription: Subscription;
  isAuth: boolean = true;
  refund: any = 0;
  currentDateRefund: any = 0;
  currentMonthRefund: any = 0;
  lastMonthRefund: any = 0;
  status: boolean = false;
  passwordExpiryColorCode: string = '';
  passwordExpiryMessage: string = '';
  showMessage: boolean = true;
  accessToken = 'YOUR_ACCESS_TOKEN';
  message: Subject<any> = new Subject();
  pendingAppointmentMeta: Metadata;
  cancelledAppointmentMeta: Metadata;
  tentativeAppointmentMeta: Metadata;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  curDate: any;
  aprovedAppointmentsDisplayedColumns: Array<any>;
  aprovedAppointmentsActionButtons: Array<any>;
  pendingDisplayedColumns: Array<any>;
  pendingActionButtons: Array<any>;
  cancelledDisplayedColumns: Array<any>;
  cancelledActionButtons: Array<any>;
  pendingAptfilterModel: FilterModel;
  cancelledAptfilterModel: FilterModel;
  pendingPatientAppointment: Array<any> = [];
  filterParamsForAppointents: {
    statusIds: Array<string>;
    appointmentTimeIntervalId: number;
    //isCheckTotalEnrollments: boolean
    CareManagerIds: Array<number>;
  };
  cancelledPatientAppointment: Array<any> = [];
  usercountid: Array<any> = [];
  usercount: Array<any> = [];
  selectedIndex: number = 0
  //Charts
  lineChartData: Array<any> = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];
  lineChartLabels: Array<any> = [];
  lineChartType: string = 'line';
  headerText: string = 'Authorization';
  userId: string;
  staffId: number;
  urgentcareapptid: number;
  showurgentcarebtn: boolean = false;
  staffProfile: StaffProfileModel;
  firstname: string;
  dughtpieobject: any;
  todaysapp: number = 0;
  upcomingapp: number = 0;
  pastapp: number = 0;
  cancelledapp: number = 0;
  ngocount: number = 0;
  companycount: number = 0;
  consultantcount: number = 0;
  consultantavlcount: number = 0;
  userscount: number = 0;
  consultantname: any;
  photoPath: any;
  consultanttotalrevenu: number = 0;
  subSpecialities: any;
  collapse1tf: boolean = false;
  collapse2tf: boolean = false;
  public pieChartLabelss: string[] = ['Chrome', 'Safari'];
  public pieChartDatas: number[] = [40, 20];
  public pieChartTypes: string = 'pie';
  constructor(private appointmentGraphService: AppointmentGraphService, private membership: MembershipService, private dashoboardService: DashboardService, private ngoService: ManageNgoService, private _companyService: CompanyService, private datePipe: DatePipe, private route: Router, private paymentService: PaymentService, private commonService: CommonService, private schedulerService: SchedulerService, private userService: UsersService, private dialogModal: MatDialog, private clientservice: ClientsService) {
    this.encFilterModel = new FilterModel();
    this.reviewRatingFilterModel = new FilterModel();
    this.filterModel = new RefundFilterModel();
    this.encMeta = new Metadata();
    this.dashboardData = new DashboardModel();
    this.staffProfile = new StaffProfileModel()
    this.filtermemberModel = new FilterMemberShipModel();
    // this.encDisplayedColumns = [
    //   { displayName: 'Practitioner', key: 'staffName', class: '', width: '20%' },
    //   { displayName: 'Client Name', key: 'clientName', class: '', width: '20%' },
    //   { displayName: 'Day', key: 'day', width: '10%' },
    //   { displayName: 'DOS', key: 'dateOfService', width: '20%', type: 'date' },
    //   { displayName: 'Status', key: 'status', width: '20%' },
    //   { displayName: 'Actions', key: 'Actions', width: '10%' }
    // ];
    // this.encActionButtons = [
    //   { displayName: 'View', key: 'view', class: 'fa fa-eye' }
    // ];
    this.pendingAptfilterModel = new FilterModel();
    this.cancelledAptfilterModel = new FilterModel();
    this.pendingDisplayedColumns = [
      { displayName: 'Care Manager', key: 'staffName', width: '110px', sticky: true },
      { displayName: 'Member Name', key: 'fullName', width: '120px', type: 'link', url: '/web/member/profile', queryParamsColumn: 'queryParamsPatientObj', sticky: true },
      { displayName: 'Appt. Type', key: 'appointmentType', width: '80px' },
      { displayName: 'Date Time', key: 'dateTimeOfService', width: '250px', type: 'link', url: '/web/member/scheduling', queryParamsColumn: 'queryParamsObj' },
      { displayName: 'Actions', key: 'Actions', width: '80px', sticky: true }
    ]
    this.pendingActionButtons = [
      { displayName: 'Approve', key: 'approve', class: 'fa fa-check' },
      { displayName: 'Cancel', key: 'cancel', class: 'fa fa-ban' },
    ];

    this.cancelledDisplayedColumns = [
      { displayName: 'Care Manager', key: 'staffName', width: '100px', sticky: true },
      { displayName: 'Member Name', key: 'fullName', width: '120px', type: 'link', url: '/web/member/profile', queryParamsColumn: 'queryParamsPatientObj', sticky: true },
      { displayName: 'Appt. Type', key: 'appointmentType', width: '80px' },
      { displayName: 'Date Time', key: 'dateTimeOfService', width: '200px', type: 'link', url: '/web/member/scheduling', queryParamsColumn: 'queryParamsObj' },
      { displayName: 'Cancel Type', kefy: 'cancelType', width: '140px' },
      { displayName: 'Cancel Reason', key: 'cancelReason', width: '150px' },
      { displayName: 'Actions', key: 'Actions', width: '60px', sticky: true }
    ]
    this.cancelledActionButtons = [];

    this.aprovedAppointmentsDisplayedColumns = [
      { displayName: 'Practitioner', key: 'staffName', class: '', width: '20%' },
      { displayName: 'Client Name', key: 'clientName', class: '', width: '20%' },
      { displayName: 'Day', key: 'day', width: '10%' },
      { displayName: 'DOS', key: 'dateOfService', width: '20%', type: 'date' },
      { displayName: 'Status', key: 'status', width: '20%' },
      { displayName: 'Actions', key: 'Actions', width: '10%' }
    ]
    this.aprovedAppointmentsActionButtons = [];
    this.filterParamsForAppointents = {
      statusIds: [],
      appointmentTimeIntervalId: null,
      CareManagerIds: []
    };
  }

  ngOnInit() {
    this.usercountid = [];
    this.commonService.loginUser.subscribe((user: LoginUser) => {
      if (user.data) {
        console.log("value", user.data)
        this.staffId = user.data.id;
        this.averageStar = user.data.averageRating;
        //  this.getStaffReviewData();
        this.userId = user.data.userID
        //this.photoPath = user.data.photoPath;
        this.firstname = user.data.firstName;
        this.consultantname = user.data.firstName + " " + user.data.lastName
        this.userrolename = user.data.userRoles.roleName;
        this.subSpecialities = user.data.subSpecialities;
        const userRoleName =
          user.data.users3 && user.data.users3.userRoles.userType;
        if ((userRoleName || "").toUpperCase() === "PROVIDER") {
          this.getLastUrgentCareCallStatus();
        }
      }
    });

    this.dughtpieobject = [];
    const data: any = {
      pageNumber: 1,
      pageSize: 200000,
      sortColumn: '',
      sortOrder: '',
      searchKey: '',
    }
    var RoleIds = '';
    var avlcount = [];
    this.userService.getAll(data, '', RoleIds, 156).subscribe((response: ResponseModel) => {

      if (response && response.statusCode == 200) {
        response.data.forEach((rep: any) => {

          this.dughtpieobject.push(rep);
          this.consultantcount = this.dughtpieobject.length;
          if (rep.isActive == true) {
            avlcount.push(rep);
            this.consultantavlcount = avlcount.length;
          } else {
            this.consultantavlcount = 0
          }

        });
      } else {
        this.consultantavlcount = 0
      }
    });

    // var interval = setInterval(() => {
    //   if (this.dughtpieobject != 0) {
    //     clearInterval(interval);

    //   }

    // }, 1000);
    var datango = [];
    this.ngoService.getAll().subscribe((response: any) => {
      response.forEach((datares: any) => {
        if (datares.isActive == true) {
          datango.push(datares)
          this.ngocount = datango.length;
        } else {
          this.ngocount = 0;
        }
      });
    });
    var datangos = [];
    this.encFilterModel.pageSize = 20000;
    this._companyService.getAll(this.encFilterModel).subscribe((response: any) => {
      if (response && response.statusCode == 200) {
        // response.data.forEach((data: any) => {
        // });
        response.data.forEach((dataress: any) => {
          if (dataress.status == true) {
            datangos.push(dataress)
            this.companycount = datangos.length;
          } else {
            this.companycount = 0;
          }
        });

      }

    });
    const dataid: any = {
      pageNumber: 1,
      pageSize: 200000,
      sortColumn: '',
      sortOrder: '',
      searchKey: '',
    }
    var RoleIds = '';
    this.clientservice.getpatientAll(dataid, '', RoleIds, 156, 0).subscribe((response: ResponseModel) => {

      if (response.statusCode == 200) {
        this.usercountid = response.data
      } else {
        this.usercountid = [];
      }

    });
    var counta = 0;

    var intervalid = setInterval(() => {
      console.log("userid", this.usercountid.length, counta)
      if (typeof this.usercountid != undefined && this.usercountid != null && this.usercountid.length != null && this.usercountid.length > 0) {
        clearInterval(intervalid);
        this.userscount = this.usercountid.length
        // for (var i = 0; i <= (this.usercountid.length-1); i++) {
        //   this.usercountid[i].forEach((data: any) => {
        //     this.usercount.push(data);
        //     console.log(this.usercount)
        //     this.userscount = this.usercount.length;
        //   });
        // }
        var a = [];
        var b = [];
        var c = [];
        var d = 0;
        var age1 = 0;
        var age2 = 0;
        var age3 = 0;
        var age4 = 0;
        var age5 = 0;
        var age6 = 0;
        this.usercountid.forEach((user: any) => {
          if (user.isActive == true) {
            a.push(user);
          } else {
            b.push(user);
          }
          if (user.isBlock == true) {
            c.push(user);
          }
          if (user.age <= 17) {
            age1 = user.step + age1;
          } else if (user.age <= 25 && user.age >= 18) {
            age2 = user.step + age2;
          } else if (user.age <= 35 && user.age >= 26) {
            age3 = user.step + age3;
            console.log(user)
          } else if (user.age <= 45 && user.age >= 36) {
            age4 = user.step + age4;
          } else if (user.age >= 45 && user.age <= 65) {
            age5 = user.step + age5;
          } else {
            age6 = user.step + age6;
          }
        });
        d = a.length + b.length + c.length;
        var e = (a.length * 100 / d).toFixed(2);
        var f = (b.length * 100 / d).toFixed(2);
        var g = (c.length * 100 / d).toFixed(2);
        const datad = {
          labels: [
            e + '% Active User',
            f + '% Inactive User',
            g + '% Locked User'
          ],
          borderJoinStyle: ['round'],
          datasets: [{
            data: [a.length, b.length, c.length],
            backgroundColor: [
              '#00897B',
              '#65ABF3',
              '#A4D24F'
            ],
            borderWidth: 0,
            hoverOffset: 1,
          }],
        };
        new Chart('chart_0', {
          type: 'RoundedDoughnut',
          data: datad,
          options: {
            cutoutPercentage: 74,
            //rotation: 30,
            animation: {
              animateScale: true
            }
          }
        });
        const datapie = {
          labels: [
            'CAVAL Be',
            'CAVAL Track'
          ],
          borderJoinStyle: ['round'],
          datasets: [{
            data: [25, 75],
            backgroundColor: [
              '#67CED5',
              '#0094C7',
            ],
            borderWidth: 0,
            hoverOffset: 1,
          }],
        };
        new Chart('chart_1', {
          type: 'pie',
          data: datapie,
          options: {
            rotation: 180,
            animation: {
              animateScale: true
            }
          }
        });
        const databar = {
          labels: [
            '0-17',
            '18-25',
            '26-35',
            '36-45',
            '46-65',
            '66-80'
          ],
          datasets: [{
            label: 'Steps',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 40,
            minBarLength: 2,
            data: [age1, age2, age3, age4, age5, age6],
            backgroundColor: [
              'rgba(255, 99, 132)',
              'rgba(255, 159, 64)',
              'rgba(255, 205, 86)',
              'rgba(75, 192, 192)',
              'rgba(54, 162, 235)',
              'rgba(153, 102, 255)'
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)'
            ],
            borderWidth: 0
          }]
        };
        new Chart('chart_2', {
          type: 'bar',
          data: databar,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          }
        });
      } else if (counta == 6) {
        clearInterval(intervalid);
        var d = 0;
        var age1 = 0;
        var age2 = 0;
        var age3 = 0;
        var age4 = 0;
        var age5 = 0;
        var age6 = 0;
        this.userscount = 0
        const datad = {
          labels: [
            '0% Active User',
            '0% Inactive User',
            '0% Locked User'
          ],
          borderJoinStyle: ['round'],
          datasets: [{
            data: [0, 0, 0],
            backgroundColor: [
              '#00897B',
              '#65ABF3',
              '#A4D24F'
            ],
            borderWidth: 0,
            hoverOffset: 1,
          }],
        };
        new Chart('chart_0', {
          type: 'RoundedDoughnut',
          data: datad,
          options: {
            cutoutPercentage: 74,
            //rotation: 30,
            animation: {
              animateScale: true
            }
          }
        });
        const datapie = {
          labels: [
            'CAVAL Be',
            'CAVAL Track'
          ],
          borderJoinStyle: ['round'],
          datasets: [{
            data: [25, 75],
            backgroundColor: [
              '#67CED5',
              '#0094C7',
            ],
            borderWidth: 0,
            hoverOffset: 1,
          }],
        };
        new Chart('chart_1', {
          type: 'pie',
          data: datapie,
          options: {
            rotation: 180,
            animation: {
              animateScale: true
            }
          }
        });
        const databar = {
          labels: [
            '0-17',
            '18-25',
            '26-35',
            '36-45',
            '46-65',
            '66-80'
          ],
          datasets: [{
            label: 'Steps',
            barPercentage: 0.5,
            barThickness: 30,
            maxBarThickness: 40,
            minBarLength: 2,
            data: [age1, age2, age3, age4, age5, age6],
            backgroundColor: [
              'rgba(255, 99, 132)',
              'rgba(255, 159, 64)',
              'rgba(255, 205, 86)',
              'rgba(75, 192, 192)',
              'rgba(54, 162, 235)',
              'rgba(153, 102, 255)'
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)'
            ],
            borderWidth: 0
          }]
        };
        new Chart('chart_2', {
          type: 'bar',
          data: databar,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          }
        });
      } else {
        counta++;
      }
    }, 1000);
    this.membership.getAll(this.filtermemberModel).subscribe((response: ResponseModel) => {
      this.membershipcount = response.data.length;
    });
    this.setPaginatorModel();
    this.getPayments(this.filterModel);
    this.getEncounterListForDashboard();
    this.getDashboardBasicInfo();
    this.getPasswordExpiryMessage();
    this.getStaffProfileData();
    Chart.defaults.RoundedDoughnut = Chart.helpers.clone(Chart.defaults.doughnut);
    Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
      draw: function (ease) {
        var ctx = this.chart.ctx;
        var easingDecimal = ease || 1;
        var arcs = this.getMeta().data;
        Chart.helpers.each(arcs, function (arc, i) {
          arc.transition(easingDecimal).draw();

          var pArc = arcs[i === 0 ? arcs.length - 1 : i - 1];
          var pColor = pArc._view.backgroundColor;

          var vm = arc._view;
          var radius = (vm.outerRadius + vm.innerRadius) / 2;
          var thickness = (vm.outerRadius - vm.innerRadius) / 2;
          var startAngle = Math.PI - vm.startAngle - Math.PI / 2;
          var angle = Math.PI - vm.endAngle - Math.PI / 2;

          ctx.save();
          ctx.translate(vm.x, vm.y);

          ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor;
          ctx.beginPath();
          ctx.arc(radius * Math.sin(startAngle), radius * Math.cos(startAngle), thickness, 0, 2 * Math.PI);
          ctx.fill();

          ctx.fillStyle = vm.backgroundColor;
          ctx.beginPath();
          ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
          ctx.fill();

          ctx.restore();
        });
      }
    });

    this.getDataForAppointmentdetailChart();
  }
  getDataForAppointmentdetailChart() {
    var a = 0;
    var b = 0;
    var c = 0;
    var d = 0;
    this.filterParamsForAppointents.statusIds = ['375090', '375091', '375092', '375093', '375094', '375095', '375230', '375225'];
    this.filterParamsForAppointents.appointmentTimeIntervalId = 375119;
    this.filterParamsForAppointents.CareManagerIds = [];
    this.appointmentGraphService
      .getLineChartDataForAppointments(this.filterParamsForAppointents)
      .subscribe((response: ResponseModel) => {
        response.data.forEach((appdata: any) => {
          if (appdata.appointmentStatus == "Approved") {
            a = appdata.appointmentCount + a;
            this.todaysapp = a;
          } else if (appdata.appointmentStatus == "Declined") {
            b = appdata.appointmentCount + b;
            this.upcomingapp = b;
          } else if (appdata.appointmentStatus == "Pending") {
            c = appdata.appointmentCount + c;
            this.pastapp = c;
          } else if (appdata.appointmentStatus == "Cancelled") {
            d = appdata.appointmentCount + d;
            this.cancelledapp = d;
          }
        });

      });
  }
  getPayments(filterModel: RefundFilterModel) {

    this.paymentService
      .getAppointmentRefunds(filterModel)
      .subscribe((response: any) => {

        if (response != null && response.statusCode == 302) {

          var refund = 0;
          var currentDateRefund = 0;
          var lastMonthRefund = 0;
          var currentMonthRefund = 0;
          var date = new Date();
          var curDate = format(new Date(), "MM/DD/YYYY");
          var firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), "MM/DD/YYYY");
          var lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), "MM/DD/YYYY");
          var PreMonthLastDay = format(new Date(date.getFullYear(), date.getMonth(), 0), "MM/DD/YYYY");
          var PreMonthFirstDay = format(new Date(date.getFullYear() - (date.getMonth() > 0 ? 0 : 1), (date.getMonth() - 1 + 12) % 12, 1), "MM/DD/YYYY");
          response.data.forEach(a => this.refund += a.netAmount);
          response.data.forEach(function (item: any) {
            if (format(item.startDateTime, "MM/DD/YYYY") == curDate) {
              currentDateRefund += item.netAmount;
            }
          });
          response.data.forEach(function (item: any) {
            if (format(item.startDateTime, "MM/DD/YYYY") >= firstDay && format(item.startDateTime, "MM/DD/YYYY") <= lastDay) {
              currentMonthRefund += item.netAmount;
            }
          });
          response.data.forEach(function (item: any) {
            if (format(item.startDateTime, "MM/DD/YYYY") >= PreMonthFirstDay && format(item.startDateTime, "MM/DD/YYYY") <= PreMonthLastDay) {
              lastMonthRefund += item.netAmount;
            }
          });

          this.refund = refund;
          this.lastMonthRefund = lastMonthRefund;
          this.currentMonthRefund = currentMonthRefund;

        }
      });
  }

  gotoscheduler(month: any, appttype: any, apptmode: any) {

    this.route.navigate(["/web/scheduling"], { queryParams: { calendermonth: month, appttype: appttype, apptmode: apptmode } });
  }
  gotopayments() {
    this.route.navigate(["/web/payment/payment-history"]);
  }

  gotousers() {
    this.route.navigate(["/web/manage-company/manage-corperate-user"]);
    localStorage.setItem('id', '0');
  }
  gotohealthconsultant() {

    this.route.navigate(["/web/manage-users"]);
  }
  gotongo() {

    this.route.navigate(["/web/manage-ngo"]);
  }
  gotocompany() {

    this.route.navigate(["/web/manage-company"]);
  }
  gotomembership() {

    this.route.navigate(["/web/membership"]);
  }
  gotoschedulerTotal() {

    this.route.navigate(["/web/scheduling"]);
  }

  gotopaymentslastmonth(monthstatus: any) {
    this.route.navigate(["/web/payment/payment-history"], { queryParams: { monthstatus: monthstatus } });
  }

  hideMessageClick() {
    this.showMessage = false;
  }
  changeWidget(value: string = '') {
    if (value == 'authorization') {
      this.isAuth = true;
      this.headerText = 'Authorization';
    }
    else if (value == 'clientstatus') {
      this.getClientStatusChart();
    }
  }


  onTabChanged(event: any) {
    this.selectedIndex = event.value
    if (event.index == 0) {
      // this.getDataForAppointmentLineChart(this.filterParamsForAppointent);
      this.getEncounterListForDashboard();
    } else if (event.index == 1) {
      this.getPendignPatientAppointmentList();
    } else if (event.index == 2) {
      this.getCancelledPatientAppointmentList();
    }
    // else if (event.index == 3) {
    // } else if (event.index == 4) {
    //   this.getTentativePatientAppointmentList();
    // }
  }

  getEncounterListForDashboard() {
    this.dashoboardService.getEncounterListForDashboard(this.encFilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.encounterList = response.data != null && response.data.length > 0 ? response.data : [];
        this.encMeta = response.meta;
      }
    });
  }
  getPendignPatientAppointmentList() {

    this.dashoboardService.getPendingPatientAppointment(this.pendingAptfilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.pendingPatientAppointment = response.data != null && response.data.length > 0 ? response.data : [];
        this.pendingAppointmentMeta = response.meta;
      }
      else {
        this.pendingPatientAppointment = [];
        this.pendingAppointmentMeta = null;
      }
    })
  }

  getLastUrgentCareCallStatus() {

    this.schedulerService.getLastUrgentCareCallStatus(this.userId).subscribe(response => {
      if (response.statusCode === 200) {

        if (response.data != undefined) {
          this.urgentcareapptid = response.data.id;
          //this.hasPreviousNewMeeting = response.data ? true : false;
          this.showurgentcarebtn = true;
        }
        else {
          this.showurgentcarebtn = false;
        }

      }
    });
  }

  Redirect() {
    this.route.navigate(["/web/encounter/soap"], {
      queryParams: {
        apptId: this.urgentcareapptid,
        encId: 0
      },
    });
  }


  onPendingPageOrSortChange(changeState?: any) {
    this.setPendingPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order);
    this.getPendignPatientAppointmentList();
  }
  onCancelledPageOrSortChange(changeState?: any) {
    this.setCancelledPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order);
    this.getCancelledPatientAppointmentList();
  }
  setPendingPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string) {
    this.pendingAptfilterModel.pageNumber = pageNumber;
    this.pendingAptfilterModel.pageSize = pageSize;
    this.pendingAptfilterModel.sortOrder = sortOrder;
    this.pendingAptfilterModel.sortColumn = sortColumn;
  }
  setCancelledPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string) {
    this.cancelledAptfilterModel.pageNumber = pageNumber;
    this.cancelledAptfilterModel.pageSize = pageSize;
    this.cancelledAptfilterModel.sortOrder = sortOrder;
    this.cancelledAptfilterModel.sortColumn = sortColumn;
  }
  getCancelledPatientAppointmentList() {
    this.dashoboardService.getCancelledPatientAppointment(this.cancelledAptfilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.cancelledPatientAppointment = response.data != null && response.data.length > 0 ? response.data : [];
        this.cancelledAppointmentMeta = response.meta;
      }
      else {
        this.cancelledPatientAppointment = [];
        this.cancelledAppointmentMeta = null;
      }
    })
  }
  getPasswordExpiryMessage() {
    this.subscription = this.commonService.loginUser.subscribe((user: LoginUser) => {
      if (user.passwordExpiryStatus) {
        this.passwordExpiryColorCode = user.passwordExpiryStatus.colorCode;
        this.passwordExpiryMessage = user.passwordExpiryStatus.message;
        this.status = user.passwordExpiryStatus.status;
      }
    });
  }

  getDashboardBasicInfo() {
    this.dashoboardService.getDashboardBasicInfo().subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.dashboardData = response.data != null ? response.data : new DashboardModel();
        this.consultanttotalrevenu = this.dashboardData.appointmentTotalRevenue.totalRevenue;
      }
    });
  }
  getClientStatusChart() {
    this.dashoboardService.getClientStatusChart().subscribe((response: ResponseModel) => {
      if (response != null && response.statusCode == 200) {
        this.isAuth = false;
        this.headerText = 'Patient Status';
        this.clientStatusChartData = response.data != null ? response.data : new ClientStatusChartModel();
        if (this.clientStatusChartData.length > 0) {
          this.lineChartLabels = this.clientStatusChartData.map(({ registeredDate }) => format(registeredDate, 'MM/DD/YYYY'));
          this.lineChartData = [
            { data: this.clientStatusChartData.map(({ active }) => active), label: 'Active' },
            { data: this.clientStatusChartData.map(({ inactive }) => inactive), label: 'Inactive' },
          ];
        }
      }
    });
  }
  onEncPageOrSortChange(changeState?: any) {
    this.setEncPaginatorModel(changeState.pageNumber, this.encFilterModel.pageSize, changeState.sort, changeState.order);
    this.getEncounterListForDashboard();
  }

  onEncTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.id;
    const name = actionObj.data && actionObj.data.name,
      appointmentId = actionObj.data && actionObj.data.patientAppointmentId,
      patientEncounterId = actionObj.data && actionObj.data.id,
      isBillableEncounter = actionObj.data && actionObj.data.isBillableEncounter;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'VIEW':
        const redirectUrl = isBillableEncounter ? "/web/waiting-room/" : "/web/encounter/non-billable-soap";
        if (isBillableEncounter) {
          this.route.navigate(["/web/waiting-room/" + appointmentId]);
        } else {
          this.route.navigate([redirectUrl], {
            queryParams: {
              apptId: appointmentId,
              encId: patientEncounterId
            }
          });
        }
        break;
      default:
        break;
    }
  }
  setEncPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string) {
    this.encFilterModel.pageNumber = pageNumber;
    this.encFilterModel.pageSize = pageSize;
    this.encFilterModel.sortOrder = sortOrder;
    this.encFilterModel.sortColumn = sortColumn;
  }

  getStaffProfileData() {

    this.userService.getStaffProfileData(this.staffId).subscribe((response: ResponseModel) => {
      if (response != null && response.data != null) {
        this.staffProfile = response.data;
        this.photoPath = response.data.photoPath;
        console.log("Profile", this.staffProfile)
      }
    });
  }

  getStaffReviewData() {

    this.userService.getReviewRatingById(this.staffId, this.reviewRatingFilterModel).subscribe((response: ResponseModel) => {
      if (response != null && response.data != null) {

        //this.staffProfile = response.data;
      }
    });
  }

  opentermconditionmodal() {
    let dbModal;
    dbModal = this.dialogModal.open(UrgentCareListingdialogComponent, {
      hasBackdrop: true,
      width: '60%'
    });
    dbModal.afterClosed().subscribe((result: string) => {
      // 
      if (result != null && result != "close") {

      }
      //this.show=true;
    });
  }
  setPaginatorModel(

  ) {
    this.filterModel.pageNumber = 1;
    this.filterModel.pageSize = 1000;
    this.filterModel.sortOrder = "";
    this.filterModel.sortColumn = "";
    this.filterModel.PatientName = "";
    this.filterModel.RefundDate = "";
    this.filterModel.AppDate = "";
  }
  collapse1() {
    if (this.collapse1tf == false) {
      document.getElementById("collapse1").classList.remove("hide")
      document.getElementById("collapse2").classList.add("hide")
      this.collapse1tf = true
      this.collapse2tf = false
    } else {
      this.collapse1tf = false
      this.collapse2tf = false
      document.getElementById("collapse1").classList.add("hide")
      document.getElementById("collapse2").classList.add("hide")
    }

  }
  collapse2() {
    if (this.collapse2tf == false) {
      document.getElementById("collapse2").classList.remove("hide")
      document.getElementById("collapse1").classList.add("hide")
      this.collapse2tf = true
      this.collapse1tf = false
    } else {
      this.collapse2tf = false
      this.collapse1tf = false
      document.getElementById("collapse1").classList.add("hide")
      document.getElementById("collapse2").classList.add("hide")
    }

  }
  editProfile() {
    this.route.navigate(["/web/manage-users/user-profile"]);
  }
}

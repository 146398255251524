import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSelect, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { format } from 'date-fns';
import { Metadata } from '../../client-portal/client-profile.model';
import { ReviewRatingComponent } from '../../client-portal/review-rating/review-rating.component';
import { ReviewRatingModel } from '../../client-portal/review-rating/review-rating.model';
import { ResponseModel } from '../../core/modals/common-model';
import { FilterModel, ReviewFilterModel } from '../clients/medication/medication.model';
import { UsersService } from '../users/users.service';
import { ViewRatingModel } from '../view-ratings/view-rating-model';
import { ViewRatingsComponent } from '../view-ratings/view-ratings.component';
import { ReviewsAndRatingsService } from './reviews-ratings.service';

@Component({
    selector: 'app-reviews-ratings',
    templateUrl: './reviews-ratings.component.html',
    styleUrls: ['./reviews-ratings.component.css']
})
export class ReviewsRatingsComponent implements OnInit {

    dataSource = new MatTableDataSource<any>();
    dataexcel: Array<any>[];
    loaderImage = "../../../../../assets/loader.gif";
    isDataLoading: boolean;


    organizationId: number;
    metaData: Metadata;
    searchText: string = "";
    filterModel: ReviewFilterModel;
    agencyData: any;
    animal: string;
    selectedOfficeLocations: Array<any>;
    officeLocations: Array<any>;

    displayedColumns: Array<any> = [
        { displayName: 'Consultant Name', key: 'cunsultantName', isSort: true, class: '', width: '20%' },
        { displayName: 'Corporate User Name', key: 'patientName', class: '', width: '20%' },
        { displayName: 'Date', key: 'createdDate', class: '', width: '10%' },
        { displayName: 'Ratings', key: 'rating', type: 'rating', class: '', width: '10%', permission: true },
        { displayName: 'Make Public', key: 'isPublic', class: '', width: '10%', type: 'togglespan', permission: true },
        { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }
    ];

    actionButtons: Array<any> = [
        { displayName: 'View', key: 'view', class: 'fa-solid fa-eye' },
        { displayName: 'Delete', key: 'delete', class: 'fa-solid fa-trash-can' }
    ];
    notify: any;

    staffID: any;
    UserID: any;
    PatientID: any;
    RoleID: any;
    UserName: any;

    constructor(
        public _userService: UsersService,
        public dialog: MatDialog,
        private notifier: NotifierService,
        private router: Router,
        private _ratingReviewService: ReviewsAndRatingsService) {
        this.filterModel = new ReviewFilterModel();
    }
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    ngOnInit() {
        this.getLoggedInUser();
        this.isDataLoading = true;
        this.selectedOfficeLocations = [];
        this.getAllCompaniesDataList();
        this.getAllReviewDataList();
        this.dataSource.sort = this.sort;
    }
    getLoggedInUser(): any {
        const token = localStorage.getItem("access_token");
        if (!token) return null;
        const splittedTokem = atob(token.split(".")[1]);
        const JsonToken = JSON.parse(splittedTokem);
        this.RoleID = JsonToken.RoleID;
        this.UserName = JsonToken.UserName;
        this.staffID = JsonToken.StaffID;
        this.UserID = JsonToken.UserID;
        this.PatientID = JsonToken.PatientID;
    }
    onDropdownSelectionChange(event: any): void {
        const source: MatSelect = event.source,
            value: any = event.value;

        this.selectedOfficeLocations = (value || []).map(
            (obj) => obj
        );

        this.isDataLoading = true;
        this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, '', this.selectedOfficeLocations);
        this.getAllReviewDataList();
    }

    getAllCompaniesDataList() {
        this._ratingReviewService.getAllCompany().subscribe((response: ResponseModel) => {
            if (response != null) {
                // if (response && response.statusCode == 200) {
                //     debugger;
                // this.agencyData = response.data;
                response.data = response;
                this.officeLocations = (response.data).map(
                    (obj: any) => {
                        // if (obj.id === this.currentLocationId)
                        //   this.selectedOfficeLocations.push(obj.id);
                        return {
                            id: obj.id,
                            value: obj.companyName,
                        };
                    }
                );
                this.metaData = response.meta;

                console.log(response)
            }
            else {
                this.agencyData = [];
                //this.metaData = new Metadata();
            }
            this.isDataLoading = false;
        });
        console.log(this.agencyData);

    }

    onPageOrSortChange(changeState?: any) {
        this.isDataLoading = true;
        this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText, this.selectedOfficeLocations);
        this.getAllReviewDataList();
    }

    applyFilter(searchText: string = '') {
        
        if (searchText == '' || searchText.length > 2) {
            this.isDataLoading = true;
            this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText, this.selectedOfficeLocations);
            this.getAllReviewDataList();
        }
    }

    setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string,
        searchText: string, selectedOfficeLocations: Array<any>) {
        this.filterModel.pageNumber = pageNumber;
        this.filterModel.pageSize = pageSize;
        this.filterModel.sortOrder = sortOrder;
        this.filterModel.sortColumn = sortColumn;
        this.filterModel.searchText = searchText;
        this.filterModel.selectedOfficeLocations = selectedOfficeLocations.length > 0 ? selectedOfficeLocations : [];
    }

    getAllReviewDataList() {
        if (this.RoleID == 331) {
            this.filterModel.staffId = Number(this.staffID);
        }
        this._ratingReviewService.getAll(this.filterModel).subscribe((response: ResponseModel) => {
            if (response && response.statusCode == 200) {

                this.agencyData = response.data;
                this.agencyData.map(x => {
                    x.createdDate =
                        format(x.createdDate, "MM/DD/YYYY");
                    return x;
                });

                this.metaData = response.meta;
                console.log(response)
            }
            else {
                this.agencyData = [];
                this.metaData = new Metadata();
            }
            this.isDataLoading = false;
        });
    }

    onTableActionClick(actionObj?: any) {

        const id = actionObj.data.id;
        switch ((actionObj.action || '')) {
            case 'delete':
                this.deleteReview(id);
                this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, '', this.selectedOfficeLocations);
                this.getAllReviewDataList();
                //this.agencyData.slice
                break
            case "toggle":
                this._ratingReviewService.confirm(`Are you sure you want to save your changes?`).subscribe((result: any) => {
                    if (result == true) {
                        this.isDataLoading = true;
                        this._ratingReviewService.changeStatus(actionObj.data.id).subscribe((response: ResponseModel) => {
                            this.isDataLoading = false;
                            this.router.navigate(["/web/reviews-ratings"]);
                            this.notifier.notify('success', 'Status ha been chnaged successfully.');
                            this.getAllReviewDataList();
                        });
                    }
                });
                break;
            case 'view':
                this.createDialogReviewRating(actionObj.data);
                break;
            default:
                break;

        }
    }
    createDialogReviewRating(data: any) {
        var reviewRatingModel = new ViewRatingModel();
        reviewRatingModel.id = data.id;
        reviewRatingModel.cunsultantName = data.cunsultantName;
        reviewRatingModel.corporateUserName = data.patientName;
        reviewRatingModel.rating = data.rating;
        reviewRatingModel.isPublic = data.isPublic;
        reviewRatingModel.description = data.review;
        let dbModal;
        dbModal = this.dialog.open(ViewRatingsComponent, {
            data: { reviewRatingModel }
        });
        dbModal.afterClosed().subscribe((result: string) => {

        });

    }


    deleteReview(id: number) {
        this._ratingReviewService.confirm(`Are you sure you want to delete review?`).subscribe((result: any) => {
            if (result == true) {
                this.isDataLoading = true;
                this._ratingReviewService.deleteByID(id).subscribe((response: string) => {
                    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, '', this.selectedOfficeLocations);
                    this.getAllReviewDataList();
                    this.notifier.notify('success', 'Review has been deleted successfully.');
                    this.isDataLoading = false;
                });
            }
        }
        );
    }

}

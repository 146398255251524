import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SchedulerService } from 'src/app/platform/modules/scheduling/scheduler/scheduler.service';

@Component({
  selector: 'app-prescription-notes',
  templateUrl: './prescription-notes.component.html',
  styleUrls: ['./prescription-notes.component.css']
})
export class PrescriptionNotesComponent implements OnInit {

  appointmentId:any;
  patientEncounterNotesList: any[];
  patientId: any;
  loading:boolean=false;
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  private schedulerService: SchedulerService ,
  private patientencounternotesModalRef: MatDialogRef<PrescriptionNotesComponent>, ) {
   
  console.log(data);
  
  this.appointmentId=data.appointmentId;
  this.patientId=data.patientId
  
  }
  
    ngOnInit() {
     this. getPatientEncounterNotesByApptId() ; 
    }
  
    getPatientEncounterNotesByApptId() {
      this.loading=true;
        this.schedulerService
          .getPatientEncounterNotesByApptId(this.patientId,this.appointmentId)
          .subscribe((response) => {
            if (response != null && response.data != null && response.statusCode == 200){
              this.patientEncounterNotesList = response.data;   
             
            }else{
              this.patientEncounterNotesList =[]
            }
            this.loading=false; 
          });    
    }
  
    closeDialog(action: string): void {
      this.patientencounternotesModalRef.close(action);
    }

}

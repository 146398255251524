import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { UsersService } from '../../users/users.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {
  courseFormGroup!: FormGroup;
  plantype = ['Staff', 'Executive'];
  constructor(public dialogRef: MatDialogRef<AddCourseComponent>,
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private notifier: NotifierService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.createFormGroup();
  }

  createFormGroup(): void {
    this.courseFormGroup = this.formBuilder.group({

      course: new FormControl("", Validators.required),
      typeName: new FormControl("", Validators.required)
    })

  }

  onSubmit() {


    this.courseFormGroup.markAsTouched();

    const postDataModel = {
      'courseName': this.courseFormGroup.value.course,
      'courseCategory': this.courseFormGroup.value.typeName
    }

    // if (this.heading != 'Edit Podcast') {
    // console.log(this.companyFormsData);
    this.userService.addupdateNewCourse(postDataModel).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.notifier.notify('success', ' Course added successfully.');
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(["/web/manage-courses"]);
        this.onNoClick();

        return;
      }
      else {
        this.notifier.notify('error', res.message);
      }

    })
    // }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { DialogComponent } from 'src/app/shared/layout/dialog/dialog.component';
import { environment } from 'src/environments/environment';
import { ReviewRatingComponent } from '../../client-portal/review-rating/review-rating.component';
import { ReviewRatingModel } from '../../client-portal/review-rating/review-rating.model';
import { FilterModel, ReviewFilterModel } from '../clients/medication/medication.model';
import { ViewRatingModel } from '../view-ratings/view-rating-model';
import { ViewRatingsComponent } from '../view-ratings/view-ratings.component';

@Injectable({
    providedIn: 'root'
})
export class ReviewsAndRatingsService {
    id: number;
    getAllURL = 'ReviewRatings/';
    constructor(private http: HttpClient, private dialog: MatDialog) { }

  getAllCompany(){
    let url = 'api/Company/' + "All";
    return this.http.get(`${environment.api_url}/${url}`)
  }

    getAll(filterModal: ReviewFilterModel) {
      //filterModal.selectedOfficeLocations= [];
        let url = this.getAllURL + 'GetAllReviewRating?pageNumber=' + filterModal.pageNumber
            + '&pageSize=' + filterModal.pageSize + '&searchText=' + filterModal.searchText + 
            '&selectedCompany=' +  filterModal.selectedOfficeLocations+'&staffId='+filterModal.staffId;
        return this.http.get(`${environment.api_url}/${url}`, {});
    }

    deleteByID(id) {
        let url = this.getAllURL + "?id=";
        return this.http.delete(`${environment.api_url}/${url}` + id);
    }

    changeStatus(id: number) {
        let url = this.getAllURL + id;
        return this.http.patch(`${environment.api_url}/${url}`, '');
    }
    public confirm(
        message: string,
        data?: Array<any>): Observable<any> {
        const modalRef = this.dialog.open(DialogComponent, { data: data, hasBackdrop: true, backdropClass: 'no-backdrop', disableClose: false, width: 'auto', minWidth: 'auto', maxWidth: 'auto', position: { bottom: '10px', right: '10px' } });
        modalRef.componentInstance.message = message;
        return modalRef.afterClosed();
    }

    
}
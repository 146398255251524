import {
  FilterVideoModel,
  PaymentFilterModel,
  RefundFilterModel,
  VitalgraphMOdel,
} from "./../core/modals/common-model";
import { Injectable } from "@angular/core";
import { CommonService } from "../core/services/common.service";
import { FilterModel } from "../core/modals/common-model";
import { Observable } from "rxjs";
import { AllergyModel } from "./history/allergies/allergy-model.model";
import { MedicationModel } from "../agency-portal/clients/medication/medication.model";
import { SurgicalModel } from "./history/surgical-history/surgical.Model";
import { PodcastModel } from "src/app/super-admin-portal/core/modals/common-model";

@Injectable({
  providedIn: "root",
})
export class ClientsService {
  id: number;
  private getClientByIdURL = "Patients/GetPatientById";
  public getClientRewardsByIdURL = "Patients/GetPatientRewardsById";
  private getPatientRewardsForNgoByIdURL =
    "Patients/GetPatientRewardsForNgoById";
  private sendPointToNgoURL = "Patients/SendPointToNgo";
  private GetPatientsDetailedInfo = "Patients/GetPatientsDetailedInfo";
  private getMasterDataByNameURL = "api/MasterData/MasterDataByName";
  private getClientProfileInfoURL = "Patients/GetPatientsDetails";
  private getPatientCCDAURL = "patients/GetPatientCCDA";
  private getAllergyListURL = "PatientsAllergy/GetAllergies";
  private getAllAuthorizationsForPatientURL =
    "Authorizations/GetAllAuthorizationsForPatient";
  private getImmunizationListURL = "PatientsImmunization/GetImmunization";
  private getPatientMedicalFamilyHistoryListURL =
    "PatientMedicalFamilyHistory/GetPatientMedicalFamilyHistoryById";
  private getPatientInsurances = "PatientsInsurance/GetPatientInsurances";
  private getPatientAppointment =
    "api/PatientAppointments/GetPatientAppointmentList";
  //chat
  private getChatHistoryURL = "Chat/GetChatHistory";
  private getPaymentUrl = "AppointmentPayment/ClientPayments";
  private getRefundUrl = "AppointmentPayment/ClientRefunds";
  //review/ratings
  private getAllReviewRatingURL = "ReviewRatings/GetAllReviewRatings";
  private getReviewRatingURL = "ReviewRatings/GetReviewRatingById";
  private saveReviewRatingURL = "ReviewRatings/SaveUpdateReviewRating";
  private getClientNetAppointmentPaymentUrl =
    "AppointmentPayment/ClientNetAppointmentPayment";
  private getPatientsDashboardDetailsURL =
    "Patients/GetPatientsDashboardDetails";

  //Vitals URL
  private createVitalURL = "PatientsVitals/SaveVital";
  private getVitalListURL = "PatientsVitals/GetVitals";
  private getVitalByIdURL = "PatientsVitals/GetVitalById";
  private deleteVitalURL = "PatientsVitals/DeleteVital";
  private GetVitalURL = "PatientsVitals/GetVitalsForPatient";

  //Patient Encounters
  private getPatientEncountersListURL = "patient-encounter/GetPatientEncounter";

  //Patient Allergies
  private getAllergyByIdURL = "PatientsAllergy/GetAllergyById";
  private createAllergyURL = "PatientsAllergy/SaveAllergy";
  private deleteAllergyURL = "PatientsAllergy/DeleteAllergy";

  //Medication URL
  private createMedicationURL = "PatientsMedication/SaveMedication";
  private getMedicationListURL = "PatientsMedication/GetMedication";
  private getMedicationByIdURL = "PatientsMedication/GetMedicationById";
  private deleteMedicationURL = "PatientsMedication/DeleteMedication";

  //Surgical URL
  private createSurgicalHistoryURL =
    "PatientSurgicalHistory/SavePatientSurgicalHistory";
  private getSurgicalHistoryByIdURL =
    "PatientSurgicalHistory/GetPatientSurgicalHistoryById";
  private getSurgicalHistoryListURL =
    "PatientSurgicalHistory/GetPatientSurgicalHistory";
  private deleteSurgicalHistoryURL =
    "PatientSurgicalHistory/DeletePatientSurgicalHistory";

  //Medical History
  private getPatientMedicalHistoryByIdURL =
    "PatientMedicalHistory/GetPatientMedicalHistoryById";
  private getPatientMedicalHistoryListURL =
    "PatientMedicalHistory/GetPatientMedicalHistory";
  private savePatientMedicalHistoryURL =
    "PatientMedicalHistory/SavePatientMedicalHistory";
  private deletePatientMedicalHistoryURL =
    "PatientMedicalHistory/DeletePatientMedicalHistory";
  y;

  private getvitalsforgraph = "PatientsVitals/GetVitalsDataFromGraph";

  //Training
  private saveVideoTrackingDataURL = "Media/SaveVideoTrackingData";
  private getVideoTrackingList = "Media/GetVideoTrackingList";
  private getmediadetailsbyId = "Media/GetMediaDetailsById";
  private getTrainingbyPlan = "Media/GetTrainingbyPlan";
  private getVideoTrackingdatabyId = "Media/GetVideoTrackingdatabyId";
  private saveQuizTrackingUrl = "Media/SaveQuizTracking";
  private GetQuizTrackingByIdUrl = "Media/GetQuizTrackingById";
  private savePatientNIC = "Media/SavePatientsNICNo";
  private GetQuizDetailsByPatientId = "Media/GetQuizDetailsPatientById";
  public getAssignCourseName = "Media/AssignCourseName"
  private getvitalSleepforgraph ="PatientsVitals/GetSleepDataForGraph";

  constructor(private commonService: CommonService) { }
  saveVideoTrackingData(data: any) {
    return this.commonService.post(this.saveVideoTrackingDataURL, data);
  }
  getAllVideoTracking() {
    let url = this.getVideoTrackingList;
    return this.commonService.getAll(url, {});
  }

  getQuizDetailsByPatientId(id: any) {
    return this.commonService.getById(
      this.GetQuizDetailsByPatientId + "?PatientId=" + id,

      {}
    );
  }
  getMasterData(value: string = "") {
    return this.commonService.post(this.getMasterDataByNameURL, {
      masterdata: value,
    });
  }

  saveQuizTracking(value) {
    return this.commonService.post(this.saveQuizTrackingUrl, value);
  }

  getPatientAppointmentList(locationId: number, id: number) {
    let url = `?locationIds=${locationId}&patientIds=${id}`;
    return this.commonService.getById(this.getPatientAppointment + url, {});
  }
  getQuizTrackingById(locationId: number, quizId: number) {
    let url = `?patientId=${locationId}&quizId=${quizId}`;
    return this.commonService.getById(this.GetQuizTrackingByIdUrl + url, {});
  }
  getClientProfileInfo(id: number) {
    return this.commonService.getById(
      this.getClientProfileInfoURL + "?id=" + id,
      {}
    );
  }
  GetVitalForPatient(id:number){
    return this.commonService.getById(
      this.GetVitalURL + "?id=" + id,
      {}
    );
  }
  
  GetPatientsDetailedInfoo(id: number) {
    return this.commonService.getById(
      this.GetPatientsDetailedInfo + "?patientId=" + id,
      {}
    );
  }

  GetPatientsRewardPointsInfo(id: number) {
    return this.commonService.getById(
      this.getClientRewardsByIdURL + "?patientId=" + id,
      {}
    );
  }

  GetPatientsRewardPointsInfoForNgo(id: number) {
    return this.commonService.getById(
      this.getPatientRewardsForNgoByIdURL + "?patientId=" + id,
      {}
    );
  }

  sendPointToNgo(toggleUpdateModel: any) {
    return this.commonService.post(this.sendPointToNgoURL, toggleUpdateModel);
  }

  getClientById(id: number) {
    return this.commonService.getById(
      this.getClientByIdURL + "?patientId=" + id,
      {}
    );
  }
  getClientProfileDashboardInfo(id: number) {
    return this.commonService.getById(
      this.getPatientsDashboardDetailsURL + "?id=" + id,
      {}
    );
  }
  getPatientCCDA(patientId: number) {
    return this.commonService.download(
      this.getPatientCCDAURL + "?id=" + patientId,
      {}
    );
  }
  //Patient Inurance
  getPatientInsurance(clientId: number) {
    return this.commonService.getById(
      this.getPatientInsurances + "?patientId=" + clientId,
      {}
    );
  }

  downloadFile(blob: Blob, filetype: string, filename: string) {
    var newBlob = new Blob([blob], { type: filetype });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 100);
  }
  getImmunizationList(clientId: number) {
    let url = this.getImmunizationListURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }
  getPatientMedicalFamilyHistoryList(clientId: number) {
    let url =
      this.getPatientMedicalFamilyHistoryListURL + "?patientid=" + clientId;
    return this.commonService.getAll(url, {});
  }
  //Allergy Method
  createAllergy(data: AllergyModel) {
    return this.commonService.post(this.createAllergyURL, data);
  }

  getAllergyList(clientId: number) {
    let url = this.getAllergyListURL + "?patientid=" + clientId;
    return this.commonService.getAll(url, {});
  }
  getAllergyById(id: number) {
    return this.commonService.getById(this.getAllergyByIdURL + "?id=" + id, {});
  }
  deleteAllergy(id: number) {
    return this.commonService.patch(this.deleteAllergyURL + "?id=" + id, {});
  }
  //Medication Method
  getMedicationList(data: any) {
    let url = this.getMedicationListURL;
    return this.commonService.post(url,data);
  }
  createMedication(data: MedicationModel) {
    return this.commonService.post(this.createMedicationURL, data);
  }
  getMedicationById(id: number) {
    return this.commonService.getById(
      this.getMedicationByIdURL + "?id=" + id,
      {}
    );
  }
  deleteMedication(id: number) {
    return this.commonService.patch(this.deleteMedicationURL + "?id=" + id, {});
  }

  // Surgical History Methods
  getSurgicalById(id: number) {
    return this.commonService.getById(
      `${this.getSurgicalHistoryByIdURL}?id=${id}`,
      {}
    );
  }
  getSurgicalList(clientId: number) {
    return this.commonService.getAll(
      `${this.getSurgicalHistoryListURL}?patientId=${clientId}`,
      {}
    );
  }
  createSurgical(data: SurgicalModel) {
    return this.commonService.post(this.createSurgicalHistoryURL, data);
  }
  deleteSurgical(id: number) {
    return this.commonService.delete(
      `${this.deleteSurgicalHistoryURL}?id=${id}`
    );
  }

  getAllAuthorization(clientId: number) {
    let url = this.getAllAuthorizationsForPatientURL + "?patientId=" + clientId;
    return this.commonService.getAll(url, {});
  }
  //chat
  getChatHistory(fromUserId: number, toUserId: number) {
    return this.commonService.getAll(
      `${this.getChatHistoryURL}?FromUserId=${fromUserId}&ToUserId=${toUserId}`,
      {}
    );
  }
  getAppointmentPayments(postData: PaymentFilterModel): Observable<any> {
    return this.commonService.post(this.getPaymentUrl, postData, true);
  }
  getClientNetAppointmentPayment(clientId: any): Observable<any> {
    return this.commonService.getAll(
      `${this.getClientNetAppointmentPaymentUrl}?clientId=${clientId}`,
      {}
    );
  }
  getAppointmentRefunds(postData: RefundFilterModel): Observable<any> {
    return this.commonService.post(this.getRefundUrl, postData, true);
  }
  getReviewRatingById(id: number) {
    return this.commonService.getById(
      this.getReviewRatingURL + "?id=" + id,
      {}
    );
  }
  saveUpdateReviewRating(data: any) {
    return this.commonService.post(this.saveReviewRatingURL, data);
  }
  getReviewRatings() {
    return this.commonService.getAll(this.getAllReviewRatingURL, {});
  }
  getVitalList(clientId: number, filterModel: FilterModel) {
    let url =
      this.getVitalListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&PatientId=" +
      clientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder;
    return this.commonService.getAll(url, {});
  }

  //client encounter Methods
  getClientEncounters(
    filterModel: any,
    clientId: number,
    appointmentType: string,
    staffName: string,
    status: string,
    fromDate: string,
    toDate: string
  ) {
    let url =
      this.getPatientEncountersListURL +
      "?pageNumber=" +
      filterModel.pageNumber +
      "&pageSize=" +
      filterModel.pageSize +
      "&patientId=" +
      clientId +
      "&SortColumn=" +
      filterModel.sortColumn +
      "&SortOrder=" +
      filterModel.sortOrder;
    if (appointmentType && appointmentType.length > 0)
      url = url + "&appointmentType=" + appointmentType;
    if (staffName && staffName != "") url = url + "&staffName=" + staffName;
    if (status && status != "") url = url + "&status=" + status;
    if (fromDate && fromDate != "") url = url + "&fromDate=" + fromDate;
    if (toDate && toDate != "") url = url + "&toDate=" + toDate;
    return this.commonService.getAll(url, {});
  }

  getUserScreenActionPermissions(moduleName: string, screenName: string): any {
    return this.commonService.getUserScreenActionPermissions(
      moduleName,
      screenName
    );
  }

  getPatientMedicalHistoryById(id: any) {
    return this.commonService.getById(
      this.getPatientMedicalHistoryByIdURL + "?id=" + id,
      {}
    );
  }

  getPatientMedicalHistoryList(id: any) {
    return this.commonService.getById(
      this.getPatientMedicalHistoryListURL + "?patientId=" + id,
      {}
    );
  }

  savePatientMedicalHistory(data: any) {
    return this.commonService.post(this.savePatientMedicalHistoryURL, data);
  }
  deletePatientMedicalHistoryById(id: any) {
    return this.commonService.delete(
      this.deletePatientMedicalHistoryURL + "?id=" + id
    );
  }
  getVitalById(id: number) {
    return this.commonService.getById(this.getVitalByIdURL + "?id=" + id, {});
  }
  deleteVital(id: number) {
    return this.commonService.patch(this.deleteVitalURL + "?id=" + id, {});
  }
  getmediadetailbyId(id: number) {
    return this.commonService.getById(
      this.getmediadetailsbyId + "?id=" + id,
      {}
    );
  }
  getVideoTrackingdata(data: any) {
    return this.commonService.get(
      this.getVideoTrackingdatabyId +
      "?patientId=" +
      data[0] +
      "&mediadetailsId=" +
      data[1]
    );
  }
  getvitalsdetails(VitalgraphMOdel: VitalgraphMOdel) {
    let url =
      this.getvitalsforgraph +
      "?FromDate=" +
      VitalgraphMOdel.FromDate +
      "&ToDate=" +
      VitalgraphMOdel.ToDate +
      "&VitalName=" +
      VitalgraphMOdel.VitalName +
      "&Key=" +
      VitalgraphMOdel.Key +
      "&PatientId=" +
      VitalgraphMOdel.PatientId;
    return this.commonService.get(url);
  }

  GetTrainingbyPlan(data: any) {
    let queryParams = `?CourseCategory=${data.PlanType}&CourseName=${data.CourseName}&ModuleId=${data.ModuleId}&MonthId=${data.MonthId}&PatientId=${data.PatientId}`;
    let url = this.getTrainingbyPlan + queryParams;
    return this.commonService.get(url);
  }

  savePatientNICNo(formValues: any) {
    let queryParams = `?PatientId=${formValues.PatientId}&NICNo=${formValues.NICNo}`;
    let url = this.savePatientNIC + queryParams;
    return this.commonService.post(url, {});
  }
  getassigncourse(data: any) {
    let url = this.getAssignCourseName + '?Category=' + data[0] + '&CompanyId=' + data[1];
    return this.commonService.get(url);
  }
  getVitalSleepsDetails(VitalgraphMOdel: VitalgraphMOdel){
    let url =
      this.getvitalSleepforgraph +
      "?FromDate=" +
      VitalgraphMOdel.FromDate +
      "&ToDate=" +
      VitalgraphMOdel.ToDate +
      "&VitalName=" +
      VitalgraphMOdel.VitalName +
      "&Key=" +
      VitalgraphMOdel.Key +
      "&PatientId=" +
      VitalgraphMOdel.PatientId ;
    return this.commonService.get(url);
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  @Input() lineChartData: any[];
  @Input() lineChartLabels: any[];
  // @Input() demoLineChartDatass: any[];
  // @Input() demoLineChartLabelsss: any[];

  public lineChartType: string = 'line';
  constructor() {
    this.lineChartData= [];
    this.lineChartLabels= [];
    // this.demoLineChartDatass= [];
    // this.demoLineChartLabelsss= [];    
   }
  ngOnInit() {
    console.log("lineChartData",this.lineChartData);
    console.log("lineChartLabels",this.lineChartLabels);

  }
}

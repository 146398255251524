import { Injectable } from '@angular/core';
import { CommonService } from '../../core/services';
import { PodcastModel } from 'src/app/super-admin-portal/core/modals/common-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageVideosService {
  private uploadUserDocumentURL = "aws3/UploadVideo";
  private getallpodcast = "Podcast/GetAll";
  private deletepodcastbyid = "Podcast/Delete";
  private uploadvideo = "/aws3/UploadVideo";
  private uploadvideolist = "aws3/GetUploadedVideoList";
  private getQuizlist = "Media/GetQuizDetails";
  private getCoursebyId = "Media/GetCoursebyId";
  constructor(private http: HttpClient, private commonService: CommonService) { }
  uploadProviderEducationalDocuments(data: any) {
    return this.commonService.post(this.uploadUserDocumentURL, data);
  }
  getAllPodcast(podcastmodel: PodcastModel) {
    let url = this.getallpodcast + '?searchKey=' + podcastmodel.searchKey + '&startWith=' + podcastmodel.startWith + '&tags=' + podcastmodel.tags + '&locationIDs=' + podcastmodel.locationIDs + '&roleIds=' + podcastmodel.roleIds + '&pageNumber=' + podcastmodel.pageNumber + '&pageSize=' + podcastmodel.pageSize + '&sortColumn=' + podcastmodel.sortColumn + '&sortOrder=' + podcastmodel.sortOrder + '&compayId=' + podcastmodel.compayId;
    return this.commonService.getAll(url, {});
  }
  deletepodcast(id: number) {
    return this.commonService.put(this.deletepodcastbyid + "?id=" + id, {});
  }
  postvideo(data: FormData) {
    const url = this.uploadvideo;
    let headers = new HttpHeaders();
    headers = headers.append('enctype', 'multipart/form-data');
    headers = headers.append('Accept', 'multipart/form-data');
    //let input = new FormData();
    //input.append('url', data);   // "url" as the key and "data" as value
    return this.http.post(`${environment.api_url}${url}`, data, { headers: headers }).pipe(map((resp: any) => resp));
  }
  getvideo() {
    const url = this.uploadvideolist;
    return this.http.get(url).pipe(map((resp: any) => resp));
  }
  getquizlist() {
    const url = this.getQuizlist;
    return this.commonService.getAll(url, {});
  }
  getCourseNamebyId(id: any) {
    const url = this.getCoursebyId + "?CourseId=" + id;
    return this.commonService.getAll(url, {});
  }

}

import { Injectable } from "@angular/core";
import { CommonService } from "../../../core/services";
import { FilterModel } from "../../../core/modals/common-model";
// import { FilterModel } from "../core/modals/common-model";

@Injectable({
  providedIn: "root",
})
export class AddCompanyService {
  private getCompanyNameDetails = "Media/GetCompanyDetailsList";
  private CreateCompany = "Media/CreateCompany";
  private getCompanyList = "Media/GetAssignTrainingList";
  constructor(private commonService: CommonService) { }

  getCompanyName() {
    let url = this.getCompanyNameDetails;
    return this.commonService.getAll(url, {});
  }
  creatCompany(companyData: any) {
    return this.commonService.post(this.CreateCompany, companyData);
  }

  GetCompanyList(filterModal1: FilterModel) {


    let url = this.getCompanyList;

    url += `?pageNumber=${filterModal1.pageNumber} &pageSize=${filterModal1.pageSize}

     &sortColumn=${filterModal1.sortColumn}

       &sortOrder=${filterModal1.sortOrder}

         &SearchText=${filterModal1.searchText}

         

     `;

    return this.commonService.getAll(url, {});
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientsService } from '../../../client-portal/clients.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { CommonService } from '../../../core/services';
import { FilterModel } from '../../../core/modals/common-model';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PatientRewardPointsModel, ToggleUpdateModel } from '../../../client-portal/client-profile.model';
import { LoginUser } from '../../../core/modals/loginUser.modal';
import { format } from 'date-fns';
import { ManageNgoMdel } from '../../manage-ngo/manage-ngo.model';
import { DownloadCertificateComponent } from '../../../client-portal/reward-points/download-certificate/download-certificate.component';

@Component({
  selector: 'app-admin-reward-point',
  templateUrl: './admin-reward-point.component.html',
  styleUrls: ['./admin-reward-point.component.css']
})
export class AdminRewardPointComponent implements OnInit {
  



  onNoClick(): void {
    //localStorage.setItem('membershipplanid', '')
    this.dialogRef.close();
  }



  ////////////////////////////

  organizationId: number;
  metaData: any;
  searchText: string = "";
  patientId: number = 0;
  filterModel: FilterModel;
  ngoData: PatientRewardPointsModel[] = [];
  logo: string = "../../../../../assets/blueLeftBoder.png";
  rewardLogo: string = "../../../../../assets/images/reward.svg";
  loaderImage = "../../../../../assets/loader.gif";
  isDataLoading: boolean;
  certificateAgeGroup:string;
  certificateSteps:string;
  certificateName:string;
  binded:boolean=false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  readonly displayedColumns: string[] = ['date', 'points', 'steps','expiresOn', 'forPatient', 'downloadCertificate'];

  dataSource!: MatTableDataSource<PatientRewardPointsModel>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdminRewardPointComponent>,private clientsService: ClientsService, private notifier: NotifierService,
    private router: Router, private dialog: MatDialog,
    commonService: CommonService) {
    this.filterModel = new FilterModel();

    
    // commonService.loginUser.subscribe((user: LoginUser) => {
    //   if (user.data) {
    //     this.patientId = user.data.id;
    //   }
    // });


  }

  ngOnInit() {
    
    this.isDataLoading=true;
this.patientId = this.data.data;
console.log(this.patientId,"this.patientId")
    this.getRewardPointsDataList();

  }

  // changedActive(event: any, id: number) {
  //   const model: toggleUpdateModel =
  //   {
  //     ngoId: id,
  //     isActive: event.checked
  //   }

  //   this.isDataLoading=true;
  //   this.ngoService.updateTogglesIsActive(model).subscribe((response: boolean) => {
  //     console.log(response);

  //     if (response == true) {
  //       this.notifier.notify('success', 'Status updated successfully.');
  //       this.getAgencyDataList();
  //     } else {
  //       this.notifier.notify('error', 'Not able to update status.')
  //     }
  //   });


  // }

  sendPointToNgo(event: any, element: PatientRewardPointsModel) {
    const model: ToggleUpdateModel =
    {
      patientId: this.patientId,
      isSendNGo: event.checked,
      patientRewardPointId: element.patientRewardPointId
    }
    this.isDataLoading = true;
    this.clientsService.sendPointToNgo(model).subscribe((response: boolean) => {

      if (response == true) {
        this.notifier.notify('success', 'Point sent successfully.');
        this.getRewardPointsDataList();
      } else {
        this.notifier.notify('error', 'Not able to sent point.');
        this.isDataLoading = false;
      }
    });

  }

  ngoRewardPoints() {
    this.router.navigate(["/web/client/ngo-reward-points"]);
  }

  getRewardPointsDataList() {
    this.clientsService.GetPatientsRewardPointsInfo(this.patientId).subscribe((response: PatientRewardPointsModel) => {
      console.log(response);
      this.ngoData = (response.data || []);
      this.ngoData.map(x => {
        x.createdDate =
          format(x.createdDate, "MM/DD/YYYY");
        return x;
      });
      this.setDataSourceAttributes(this.ngoData);
      this.isDataLoading = false;
    });
  }




  applyFilter() {
    this.dataSource.filter = `${this.searchText}`;
    //this.dataSource.filterPredicate = this.gridfilterPredicate;
  }

  private gridfilterPredicate = (data: ManageNgoMdel, filter: string): boolean => {
    const matchingText = (this.searchText || '').toLocaleLowerCase().trim();
    // const portalType = Number(this.portalType || 0);

    if ((matchingText.length === 0
      || ((data.ngoName || '').toLocaleLowerCase().indexOf(matchingText) !== -1)))
      return true;

    return false;
  }

  private setDataSourceAttributes(clinicSubscriptions: PatientRewardPointsModel[]) {



    console.log(clinicSubscriptions, 'clinicSubscriptions')
    this.dataSource = new MatTableDataSource<PatientRewardPointsModel>(clinicSubscriptions);
    console.log(this.dataSource, 'this.dataSource')
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    //this.dataSource.filterPredicate = this.gridfilterPredicate;
  }


  openDialog(element: any) {
    // Create data object from element
    const data = new PatientRewardPointsModel();
    data.age = element.age;
    data.steps = element.steps;
    data.planType = element.planType;
    data.createdDate = element.createdDate;
    data.fullName=element.name;
    data.userImage=element.logoUrl

    // Open dialog with data and set width
    const dialogRef = this.dialog.open(DownloadCertificateComponent, {
        data: { data },
        width: '100px'
    });

    // Subscribe to afterClosed to handle dialog closing
    dialogRef.afterClosed().subscribe((result: any) => {
        console.log("Dialog closed with result:", result);
    });
}

  clearFilters() {
    this.searchText = "";
    this.setPaginatorModel(this.filterModel.searchText);
    this.getRewardPointsDataList();
  }


  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(this.filterModel.searchText);
    this.getRewardPointsDataList();
  }





  setPaginatorModel(searchText: string) {
    this.filterModel.searchText = searchText;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/platform/modules/core/modals/common-model';
import { CommonService } from 'src/app/platform/modules/core/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageNgoService {
  getAllURL = 'api/Organization/GetNgos';
  getAllAgeGroupDataURL = 'api/Organization/getAllAgeGroupData';
  getAllCausesURL = 'api/Organization/GetCauses';
  LoginAsNgo = 'Login/ngologin';
  getByIdURL = "api/Organization/GetNgoById";
  createURL = "api/Organization/SaveNgo";
  UpdateToggleIsActiveURL = "api/Organization/UpdateTogglesIsActive";
  UpdateToggleIsActiveForAgeGroupURL = "api/Organization/UpdateTogglesIsActiveForAgeGroup";
  AddAgeGroupURL = "api/Organization/AddAgeGroup";
  AddPonitsAndStepURL = "api/Organization/AddPonitsAndStep";
  UpdateToggleIsDefaultURL = "api/Organization/UpdateToggleIsDefault";
  UpdateTogglesIsDeleteURL = "api/Organization/UpdateTogglesIsDeleted";
  deleteURL = "api/Organization/DeleteNgo";
  deleteAgeGroupURL = "api/Organization/DeleteAgeGroup";
  getMasterDataURL = 'api/MasterData/MasterDataByName';
  getRewardPointsDataURL = 'api/Organization/GetAllRewardPoints';
  getRewardPointsDataByNgoIdURL = 'api/Organization/GetAllRewardPointByNgoId';
  getAllCompanyURL = 'api/Organization/GetAllCompanies';


  constructor(private commonService: CommonService,
  ) { }

  
  pgetAllCompany(){
    let url = 'api/Company/' + "All";
    //return this.http.get(`${environment.api_url}/${url}`)
  }

  getAllCompany() {
    let url = 'api/Company/' + "All";
    //let url = this.getAllCompanyURL;
    return this.commonService.getAll(url, {});
  }

  getAllRewardPoints(ngoId:number) {
    let url = this.getRewardPointsDataURL;
    return this.commonService.getAll(url + "?userId=" + ngoId, {});
  }

  getAllRewardPointsByNgoId(ngoId:number, selectedOfficeLocations: any, selectedDates : any) {
    let url = this.getRewardPointsDataByNgoIdURL;
    return this.commonService.getAll(url + "?ngoId=" + ngoId + 
    "&selectedCompany=" + selectedOfficeLocations + "&selectedDates=" + selectedDates, {});
  }

  getAll() {
    let url = this.getAllURL;
    return this.commonService.getAll(url, {});
  }

  getAllAgeGroupData() {
    let url = this.getAllAgeGroupDataURL;
    return this.commonService.getAll(url, {});
  }

  getAllCauses() {
    let url = this.getAllCausesURL;
    return this.commonService.getAll(url, {});
  }

  ngoLogin(postdata: any) {
    let url = this.LoginAsNgo;
    return this.commonService.post(url, postdata);
  }


  getById(id: number) {
    return this.commonService.getById(this.getByIdURL + "?Id=" + id, {});
  }

  deleteNgo(id: number) {
    return this.commonService.put(this.deleteURL + "?Id=" + id, {});
  }

  deleteAgeGroup(id: number) {
    return this.commonService.put(this.deleteAgeGroupURL + "?Id=" + id, {});
  }

  getMasterData(masterData: any): Observable<any> {
    return this.commonService.post(this.getMasterDataURL, masterData);
  }
  save(agencyModel: any) {
    return this.commonService.post(this.createURL, agencyModel);
  }

  updateTogglesIsActive(toggleUpdateModel: any) {
    return this.commonService.post(this.UpdateToggleIsActiveURL, toggleUpdateModel);
  }

  updateTogglesIsActiveForAgeGroup(toggleUpdateModel: any) {
    return this.commonService.post(this.UpdateToggleIsActiveForAgeGroupURL, toggleUpdateModel);
  }

  addAgeGroup(toggleUpdateModel: any) {
    return this.commonService.post(this.AddAgeGroupURL, toggleUpdateModel);
  }

  addStepAndPoints(toggleUpdateModel: any) {
    return this.commonService.post(this.AddPonitsAndStepURL, toggleUpdateModel);
  }

  updateTogglesIsDefault(toggleUpdateModel: any) {
    return this.commonService.post(this.UpdateToggleIsDefaultURL, toggleUpdateModel);
  }

  updateTogglesIsDeleted(toggleUpdateModel: any) {
    return this.commonService.post(this.UpdateTogglesIsDeleteURL, toggleUpdateModel);
  }
}

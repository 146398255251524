import { FormControl } from '@angular/forms';

export interface ValidationResult {
    [key: string]: boolean;
}

export class PasswordValidator {

    public static strong(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSpecialChar = /[$&+,:;=?@#|'<>.^*()%!-]/.test(control.value);
        let minLength = control.value.length >= 8 ? true : false;
        // console.log('Num, Upp, Low, spc, min', hasNumber, hasUpper, hasLower, hasSpecialChar, minLength);
        const valid = hasNumber && hasUpper && hasLower && hasSpecialChar && minLength;
        if (!valid) {
            // return what´s not valid
            return { strong: true };
        }
        return null;
    }
}
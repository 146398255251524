import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-quiz-companies',
  templateUrl: './quiz-companies.component.html',
  styleUrls: ['./quiz-companies.component.css']
})
export class QuizCompaniesComponent implements OnInit {

  constructor(public dialog: MatDialog,) { }

  
  @ViewChild("addCompany", { }) addCompany: TemplateRef<any>;

  ngOnInit() {
  }

  openAddCompanyDialog(): void {
    this.dialog.open(this.addCompany, {
     width: '340px',
     panelClass: 'add-company',
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}

import { Injectable } from '@angular/core';
import { FilterMemberShipModel } from '../../core/modals/common-model';
import { CommonService } from '../../core/services';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {
  private getAllPlan = "Membership/GetMembership";
  private savePlan = "Membership/SaveMemberShip";
  private getPlanByIdURL = "Membership/GetMembershipId";
  private deletePlanURL = "Membership/DeleteMemberShip";
  private getplans = "Membership/GetPlanType";
  private getplansdesc = "Membership/GetPlanDescription";
  private saveplandesc = "Membership/SaveMemberPlanDescription";
  
  constructor(private commonService: CommonService) { }
  getAll(filterModal: FilterMemberShipModel) {
    let url = this.getAllPlan + '?pageNumber=' + filterModal.pageNumber + '&pageSize=' + filterModal.pageSize + '&sortColumn=' + filterModal.sortColumn + '&sortOrder=' + filterModal.sortOrder + '&companyId=';
    return this.commonService.getAll(url, {});
  }
  getplan() {
    let url = this.getplans;
    return this.commonService.getAll(url, {});
  }
  addPlan(data: any) {
    return this.commonService.post(this.savePlan, data);
  }
  getplandesc(Id: number) {
    return this.commonService.getById(this.getplansdesc + "?planId=" + Id, {});
  }
  getPlanById(Id: number) {
    return this.commonService.getById(this.getPlanByIdURL + "?id=" + Id, {});
  }
  getPlanId(Id: number) {
    return this.commonService.getById(this.getPlanByIdURL + "?id=" + Id, {});
  }
  deletePlan(Id: number) {
    return this.commonService.patch(this.deletePlanURL + '?id=' + Id, {});
  }
  savedescPlan(data: any) {
    return this.commonService.post(this.saveplandesc, data);
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Alert } from "selenium-webdriver";
import { CompanyService } from "../manage-company/company.service";
import {
  companyFormsModel,
  CompanyModel,
} from "../manage-company//manage-company.model";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DeleteCompanyAlertComponent } from "../manage-company/delete-company-alert/delete-company-alert.component";
import { ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { stat } from "fs";
import { UsersService } from "src/app/platform/modules/agency-portal/users/users.service";
import {
  FilterModel,
  FilterVideoModel,
  MediaFilterModel,
  Metadata,
  ResponseModel,
} from "../../core/modals/common-model";
import * as XLSX from "xlsx";
import { ManagePackageComponent } from "../manage-company/manage-package/manage-package.component";
import { AddCompanyModalComponent } from "./add-company-modal/add-company-modal.component";
import { FormGroup } from "@angular/forms";
import { AddCompanyService } from "./add-company-modal/add-company.service";
declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: "app-manage-companies",
  templateUrl: "./manage-companies.component.html",
  styleUrls: ["./manage-companies.component.css"],
})
export class ManageCompaniesComponent implements OnInit {
  loaderImage = "../../../../../assets/loader.gif";
  isDataLoading: boolean;
  dataSource = new MatTableDataSource<any>();
  dataexcel: Array<any>[];

  organizationId: number;
  metaData: any;
  searchText: string = "";
  filterModel: MediaFilterModel;
  filterVideoModel: FilterVideoModel;
  filterModel1: FilterModel;
  agencyData: any;
  animal: string;
  searchModel: { SearchKey: string };

  ExportButtonDisable: boolean = false;
  spinnerExport: boolean = false;
  isLoading = false;

  displayedColumns: Array<any> = [
    { displayName: "Companies", key: "comName", class: "", width: "25%" },
    {
      displayName: "Assign Date",
      key: "createdDate",
      type: "date",
      class: "",
      width: "20%",
    },
    {
      displayName: "Course Category",
      key: "courseType",
      class: "",
      width: "20%",
    },
    { displayName: "Course Name", key: "courseName", class: "", width: "25%" },

    { displayName: "Actions", key: "Actions", class: "", width: "35%" },
  ];

  actionButtons: Array<any> = [

    //   //{ displayName: 'Add', key: 'add', class: 'fa-solid fa-user-plus' },
    //   // { displayName: 'Package', key: 'package', class: 'fa-solid fa-clipboard' },
    { displayName: "Edit", key: "edit", class: "fa fa-pencil" },
    { displayName: "Delete", key: "delete", class: "fa-solid fa-trash-can" },
    { displayName: "Report", key: "Report", class: "fa fa-file-excel-o" },
  ];
  notify: any;

  name = "This is XLSX TO JSON CONVERTER";
  willDownload = false;
  CategoryType: string = "";
  UserType: string = "";

  FormData: FormGroup;
  constructor(
    private addCompanyService: AddCompanyService,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private router: Router,
    private userservice: UsersService,
    private _companyService: CompanyService
  ) {
    this.filterModel = new MediaFilterModel();
    this.filterVideoModel = new FilterVideoModel();
    this.filterModel1 = new FilterModel();
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  ngOnInit() {
    // this.agencyData = [];
    this.GetListOfCompany();
    this.isDataLoading = true;
    setTimeout(() => {
      this.isDataLoading = false;
    }, 300);

    // this.getVideoList();

    // this.dataSource.sort = this.sort;
  }
  GetListOfCompany() {
    this.filterModel1.pageSize = 10;
    this.addCompanyService.GetCompanyList(this.filterModel1).subscribe(
      (res) => {

        this.agencyData = res.data;
        this.metaData = res.meta;
        this.isDataLoading = false;
      }
    );
    setTimeout(() => {
      this.isDataLoading = false;
    }, 300);
  }
  onPageOrSortChange(changeState?: any) {
    this.isDataLoading = true;
    this.setPaginatorModel(
      changeState.pageNumber,
      changeState.pageSize,
      changeState.sort,
      changeState.order,
      this.filterModel.searchText
    );
    this.GetListOfCompany();
  }

  onTableActionClick(actionObj?: any) {
    const companyname = actionObj.data.comName;

    switch ((actionObj.action || "").toUpperCase()) {
      // case "VIEW":
      //   localStorage.setItem("heading", "View Training");
      //   this.ViewVideo(path);
      //   break;
      case "EDIT":
        this.editAssignCompany(actionObj.data.id);
        localStorage.setItem("headingvalue", "Edit Company");
        localStorage.setItem('id', actionObj.data.id);
        break;
      case "REPORT":
        this.exportExcel(companyname);
        break;
      case "DELETE":
        this.deleteAssignCompany(actionObj.data.id);
        break;
      default:
        break;
    }
  }

  EditCompany(id: number) {
    localStorage.setItem("heading", "Edit Company");
    localStorage.setItem("id", id.toString());
    this.isDataLoading = true;
    this._companyService.getById(id).subscribe((res: any) => { });
    // this.router.navigate(["/web/manage-podast/add-podcast/upload-video"]);
  }

  OnOff(value: number) {
    if (value == 1) {
      return true;
    }
    return false;
  }

  applyFilter(searchText: string = "") {
    if (searchText == "" || searchText.length > 2) {
      this.isDataLoading = true;
      this.setPaginatorModel(
        1,

        this.filterModel.pageSize,
        this.filterModel.sortColumn,
        this.filterModel.sortOrder,
        searchText
      );
      this.GetListOfCompany();
    }
  }
  showResults() {
    this.isDataLoading = true;
    this.filterModel.CategoryType = this.CategoryType;
    // this.filterModel.UserType=this.UserType;
    this.GetListOfCompany();
  }


  clearFilters() {
    this.isDataLoading = true;
    this.searchText = "";
    this.setPaginatorModel(
      1,
      this.filterModel.pageSize,
      this.filterModel.sortColumn,
      this.filterModel.sortOrder,
      ""
    );
    this.GetListOfCompany();
  }

  setPaginatorModel(
    pageNumber: number,
    pageSize: number = 10,
    sortColumn: string,
    sortOrder: string,
    searchText: string
  ) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.searchText = searchText;
    this.filterModel1.pageNumber = pageNumber;
    this.filterModel1.pageSize = pageSize;
    this.filterModel1.sortOrder = sortOrder;
    this.filterModel1.sortColumn = sortColumn;
    this.filterModel1.searchText = searchText;
  }
  addAgency(organizationId: number = null) {
    localStorage.setItem("heading", "Add Company");
    // this.router.navigate(["/web/manage-podcast/add-podcast/upload-video"]);
  }



  editAssignCompany(id: number) {
    const dialogRef = this.dialog.open(AddCompanyModalComponent, {
      data: { id },
      panelClass: "add-company-model"
    });
    dialogRef.afterClosed().subscribe(result => {
      // if (result) {
      this.GetListOfCompany();
      this.dataSource.sort = this.sort;
      // console.log(result);
      this.isDataLoading = false;
      // }
    });
  }

  deleteAssignCompany(id: number) {
    this._companyService.confirm(`Are you sure you want to delete video?`).subscribe((result: any) => {
      if (result == true) {
        this.userservice.deleteAssignCourse(id).subscribe((response: string) => {
          let responsedata: any = response;
          this.notifier.notify("success", responsedata.message);
          this.GetListOfCompany();
        });
      }
    }
    );
  }

  changedActive(id: number, num: number) {
    this._companyService.changeStatus(id, num).subscribe((res) => { });
  }


  openplanDialog(): void {
    const dialogRef = this.dialog.open(ManagePackageComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (localStorage.getItem("membershipid") != "") {
        this.basicplan();
      }
    });
  }
  companyFormsData: companyFormsModel = {
    id: "",
    companyName: "",
    brnNumber: "",
    taxNumber: "",
    emailId: "",
    phoneNumber: "",
    contactPerson: "",
    address: "",
    status: "",
    OraganizationId: 1,
    isLock: 1,
    planId: "",
  };

  basicplan() {
    var companyid = Number(localStorage.getItem("id"));
    this._companyService.getById(companyid).subscribe((res: any) => {
      this.companyFormsData.id = companyid;
      this.companyFormsData.companyName = res.companyName;
      this.companyFormsData.brnNumber = res.brnNumber;
      this.companyFormsData.taxNumber = res.taxNumber;
      this.companyFormsData.emailId = res.emailId;
      this.companyFormsData.phoneNumber = res.phoneNumber;
      this.companyFormsData.contactPerson = res.contactPerson;
      this.companyFormsData.address = res.address;
      this.companyFormsData.isLock = res.isLock;
      this.companyFormsData.status = res.status;
      this.companyFormsData.planId = Number(
        localStorage.getItem("membershipid")
      );
    });
    console.log(this.companyFormsData);
    var interval = setInterval(() => {
      if (
        this.companyFormsData.companyName != "" &&
        this.companyFormsData.planId ==
        Number(localStorage.getItem("membershipid"))
      ) {
        clearInterval(interval);
        this._companyService
          .editCompnay(companyid, this.companyFormsData)
          .subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.notifier.notify(
                "success",
                res.message + " company updated successfully."
              );
              // this.router.navigate(["/webadmin/company"]);

              var a = 1;
              var interval = setInterval(() => {
                if (a == 2) {
                  clearInterval(interval);
                  this.GetListOfCompany();
                } else {
                  a++;
                }
              }, 500);
              return;
            }
            this.notifier.notify("error", res.message);
          });
      }
    }, 500);
  }
  openAddCompanyDialog(): void {
    this.isDataLoading = true;
    const dialogRef = this.dialog.open(AddCompanyModalComponent, {
      // width: "700px",
      panelClass: "add-company-model",

    });
    dialogRef.afterClosed().subscribe(result => {
      // if (result) {
      this.GetListOfCompany();
      this.dataSource.sort = this.sort;
      // console.log(result);
      this.isDataLoading = false;
      // }
    });
  }

  exportExcel(companyName: any) {

    this._companyService.generateReport(companyName).subscribe(response => {
      if (response.data != null || response.statusCode == 200) {
        var reportList: any = [];
        reportList = response.data;
        var exceldata: any = [];
        exceldata = reportList.map(function (obj: {
          companyName: any, memberName: any, nicNumber: any, courseName: any, loginDate: any, logoutDate: any, percentageOfCompletion: any
        }) {
          return {
            CompanyName: obj.companyName, MemberName: obj.memberName,
            NICNumber: obj.nicNumber, CourseName: obj.courseName, LoginDate: obj.loginDate,
            LogoutDate: obj.logoutDate, PercentageOfCompletion: obj.percentageOfCompletion
          }
        });
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(exceldata); // Sale Data
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "task");
        });
        this.isLoading = false;
        this.ExportButtonDisable = false;
        this.spinnerExport = false;
      } else {
        this.isLoading = false;
        this.ExportButtonDisable = false;
        this.spinnerExport = false;
      }
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSave => {
      let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });

      FileSaver.saveAs(data, "AttendenceReport" + EXCEL_EXTENSION);
      this.ExportButtonDisable = false;
    });
  }

}


import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { ViewRatingsComponent } from "../../../agency-portal/view-ratings/view-ratings.component";
import { ClientsService } from "../../clients.service";
import html2pdf from "html2pdf.js";
import { DatePipe } from "@angular/common";
import { CommonService } from "../../../core/services";
import { getDate } from "date-fns";
import { toDate } from "@angular/common/src/i18n/format_date";

@Component({
  selector: "app-download-certificate",
  templateUrl: "./download-certificate.component.html",
  styleUrls: ["./download-certificate.component.css"],
})

export class DownloadCertificateComponent implements OnInit {
  @Input() quizData: any = null;
  certificateAgeGroup: string;
  certificateSteps: string;
  certificatePlan: string;
  certificateName: string;
  certificatMonth: string;
  certificatYear: string;
  certificateUserImage: string;
  binded: boolean = false;
  currentDateTime: any;
  options = {
    margin: 1,
    filename: "certificate.pdf",
    image: {
      type: "jpeg",
      quality: "0.90",
    },
    html2canvas: {
      scale: 2,
    },
    jsPDF: {
      unit: "in",
      format: "letter",
      orientation: "landscape",
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private clientService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private notifierService: NotifierService,
    private route: Router,
    private dialogModalRef: MatDialogRef<DownloadCertificateComponent>,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {

    this.currentDateTime = this.datepipe.transform(new Date(), "MM/dd/yyyy");

    var king = this.datepipe.transform(new Date(), "MM");
    var king = this.datepipe.transform(new Date(), "yyyy");
    var currentuser = this.commonService.getLoginUserInfo();
    //alert((formatdate(this.currentDateTime)).getFullYear());
    //alert((toDate(this.currentDateTime)).getMonth());
    this.certificateAgeGroup = this.data.data.age;
    this.certificateSteps = this.data.data.steps;
    this.certificatePlan = this.data.data.planType;
    this.certificatMonth = this.getMonth(
      Number(data.data.createdDate.split("/")[0])
    );
    this.certificatYear = this.data.data.createdDate.split("/")[2];
    if(currentuser.data.users1.userRoles.roleName === 'Admin'){
      this.certificateName=this.data.data.fullName
      this.certificateUserImage=this.data.data.userImage
    }else{
    this.certificateName =
      currentuser.patientData.firstName +
      " " +
      currentuser.patientData.lastName;
    this.certificateUserImage =
      currentuser.patientData.photoThumbnailPath != ""
        ? currentuser.patientData.photoThumbnailPath
        : "../../../../../assets/img/noimage3.png";
    }
  }

  getMonth(month: number) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month - 1];
  }

  ngOnInit() { }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }

  download() {
    // this.certificateAgeGroup = data.age;
    // this.certificateSteps = data.steps;
    // this.certificateName = data.ngoName;
    // const certificateAgeGroup = document.getElementsByClassName('certificateAgeGroup')[0] as HTMLElement;
    // certificateAgeGroup.textContent="20-30"//data.age;
    // const certificateSteps = document.getElementsByClassName('certificateSteps')[0] as HTMLElement;
    // certificateSteps.textContent="5000"//data.steps;
    // const certificateName = document.getElementsByClassName('certificateName')[0] as HTMLElement;
    // certificateName.textContent="Mohsin Khan"//data.ngoName;
    this.downLoadCerificate();
  }

  downLoadCerificate() {
    var element = document.getElementById("downloadCertificate");
    //element.style.display='block'
    html2pdf().from(element).set(this.options).save();
    //element.style.display='none'
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewEncapsulation,
  EventEmitter,
  Output
} from "@angular/core";

import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LoginUser } from "../../../platform/modules/core/modals/loginUser.modal";
import { MatDialog } from "@angular/material";
import { ChangePasswordComponent } from "../../../platform/modules/agency-portal/change-password/change-password.component";
import { ResponseModel } from "../../../super-admin-portal/core/modals/common-model";
import { SharedService } from "../../shared.service";
import { CommonService } from "../../../platform/modules/core/services";
import * as moment from "moment";
import {
  HeaderInfo,
  NavItem,
  MessageNotificationModel,
  UserDocumentNotificationModel
} from "../../models";
import { debug } from "util";
import { BookingTypeModelComponent } from "../../booking-type-model/booking-type-model.component";
import { UsersService } from "src/app/platform/modules/agency-portal/users/users.service";

@Component({
  selector: "app-client-header-layout",
  templateUrl: "./client-header-layout.component.html",
  styleUrls: ["./client-header-layout.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ClientHeaderLayoutComponent implements OnInit, OnChanges {
  @Input() headerInfo: HeaderInfo;
  @Output() eventChangePassword: EventEmitter<any> = new EventEmitter<any>();
  documentNotifications: Array<UserDocumentNotificationModel> = [];
  notificationsURL = "api/Notification/GetHeaderNotification";
  changeMessageStatusURL = "api/Message/ChangeMessageStatus";
  totalUnreadNotificationCount: number;
  userInfo: any;
  patientData: any;
  userNavigations: NavItem[];
  //userLocations: Array<any>;
  //currentLocationId: number;
  subscription: Subscription;
  photoPath: any;

  constructor(
    private sharedService: SharedService,
    private commonService: CommonService,
    private dailog: MatDialog,
    private router: Router,
    private userService:UsersService
  ) {
    //this.userLocations = [];
    this.userInfo = {};
    this.userNavigations = [];
    //this.currentLocationId = null;
  }

  ngOnChanges(changes) {
    const headerInfo = changes.headerInfo || null;
    if (headerInfo && headerInfo.currentValue) {
        this.userInfo = headerInfo.currentValue.user;
      //this.userLocations = headerInfo.currentValue.userLocations;
      localStorage.setItem('organizationID', this.userInfo.organizationID);
       
      this.userNavigations = headerInfo.currentValue.userNavigations;


    }
  }

  onSelectUserMenu(item: NavItem) {
    switch (item.route) {
      case "/web/sign-out":
        this.commonService.logout();
        //sessionStorage.setItem('redirectTo','/web/client-login');
        sessionStorage.setItem("redirectTo", "/web/login-selection");
        this.router.navigateByUrl('/web/login-selection');
        //location.reload();
        break;
      case "":
        this.eventChangePassword.emit();
        break;
      default:
        item.route && this.router.navigate([item.route]);
        break;
    }
  }

  ngOnInit() {

    this.commonService.loginUser.subscribe((user: LoginUser) => {
      if (user) {
        this.patientData = user.patientData;
      }
    });
    this.getHeaderNotifications();
  } 
  toggleSidenav() {
    this.sharedService.toggle();
  }

  getHeaderNotifications() {
     
    this.commonService
      .getAll(this.notificationsURL, {}, false)
      .subscribe((response: ResponseModel) => {
        if (
          response &&
          response.data != undefined &&
          response.statusCode == 200
        ) {
          this.onReceiveNotification(response.data);
        }
      });
  }
  onReceiveNotification(notificationResponse: any) {
    this.totalUnreadNotificationCount = notificationResponse.unReadNotificationCount != undefined ?
      notificationResponse.unReadNotificationCount.totalUnReadNotification : 0;

    this.documentNotifications =
      notificationResponse.userDocumentNotification != undefined
        ? notificationResponse.userDocumentNotification
        : [];
    this.documentNotifications.forEach(v => {
      v.timeStamp = moment
        .utc(v.timeStamp)
        .local()
        .format("YYYY-MM-DD, h:mm a");
      switch (v.type) {
        case "UserInvitation":
          v.notificationAction = v.type;
          v.message = v.message;

          //  v.message = v.patientName + " has requested appointment at ";
          break;

        case "ChatMessage":
          v.message = v.message;
          break;
      }
    });
  }

  changeNotificationStatus(messageId: number) {
    this.commonService
      .patch(
        this.changeMessageStatusURL +
        "?MessageId=" +
        messageId +
        "&Unread=" +
        false,
        {}
      )
      .subscribe((response: ResponseModel) => {
         
        if (response != null && response.statusCode == 200) {
          this.getHeaderNotifications();
        }
      });
  }

  onBookNewClick() {
    this.router.navigate(['/doctor-list'])
  }
}

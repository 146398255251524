import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { TermsConditionModalComponent } from 'src/app/front/terms-conditions/terms-conditions.component';
import { AuthenticationService } from '../../platform/modules/auth/auth.service';
import { SubDomainService } from 'src/app/subDomain.service';

@Component({
  selector: 'app-login-selection',
  templateUrl: './login-selection.component.html',
  styleUrls: ['./login-selection.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginSelectionComponent implements OnInit {
  loginForm: FormGroup;
  domainName: any = '';
  loading = false;
  submitted = false;
  returnUrl: string;
  subDomainInfo: any;
  errorMessage: string = null;
  ipAddress: string;
  isLogin: boolean = false;
  iSignUp: boolean = false;
  organizationModel: string;
  hostName: string;
  constructor(

    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private dialogModal: MatDialog,
    private subDomainService: SubDomainService
  ) {
    this.isLogin = true;
  }

  ngOnInit() {
    this.hostName = window.location.host;
    this.organizationModel = sessionStorage.getItem("logo");
    if (this.hostName === "admin.letsbe.me" || this.hostName === "members.letsbe.me" || this.hostName === "healthcoach.letsbe.me" || this.hostName === "ngo.letsbe.me") {
      const portal = this.subDomainService.getSubDomainUrl().toLowerCase();
      this.setportalType(portal);
    } else {
      const portal = this.subDomainService.getSubdomain().toLowerCase();
    }

  }
  setportalType(portal: any) {
    const portalname = portal;
    if (portalname == 'healthcoach') {
      //window.location.href = "https://consultant.localhost:4200/web/consultant-login"
      //window.location.href = "https://consultant.letsbe.me/web/consultant-login"
      this.router.navigate(['/web/consultant-login']);
    } else if (portalname == 'members') {
      //window.location.href = "https://members.localhost:4200/web/client-login"
      //window.location.href = "https://members.letsbe.me/web/client-login"
      this.router.navigate(['/web/client-login']);
    } else if (portalname == 'ngo') {
      //window.location.href = "https://ngo.localhost:4200/web/ngo-login"
      //window.location.href = "https://ngo.letsbe.me/web/ngo-login"
      this.router.navigate(['/web/ngo-login']);
    } else {
      //window.location.href = "https://admin.localhost:4200/web/login"
      //window.location.href = "https://admin.letsbe.me/web/login"
      this.router.navigate(['/web/login']);
    }

  }
  ngAfterViewChecked() {
    if (sessionStorage.getItem("logo")) {
      this.organizationModel = sessionStorage.getItem("logo");
    }
  }

  reDirectTo(type: string) {
    let url = '';
    if (this.isLogin) {
      url = type === 'patient' ? '/web/client-login' : '/web/login'
    }
    else if (this.iSignUp) {
      url = type === 'patient' ? '/web/client-signup' : '/web/provider-signup'
    }
    this.redirect(url);
  }
  reDirectToconultant() {
    this.router.navigate(['/web/consultant-login']);
  }
  reDirectToNgo() {
    this.router.navigate(['/web/ngo-login']);
  }

  redirect(path) {
    this.router.navigate([path]);
  }

  changeSideImg(type) {
    if (type === 'patient')
      this.authenticationService.updateSideScreenImgSrc("../../../../../assets/login-patient.png");
    else if (type === 'provider')
      this.authenticationService.updateSideScreenImgSrc("../../../../../assets/login-doc.png");
  }
  opentermconditionmodal() {
    let dbModal;
    dbModal = this.dialogModal.open(TermsConditionModalComponent, {
      hasBackdrop: true,
      width: '70%'
    });
    dbModal.afterClosed().subscribe((result: string) => {
      if (result != null && result != "close") {

      }
    });
  }
}

export class MedicationModel {
    id: number;
    patientId: number;
    medicine: string;
    dose: string;
    strength: string;
    startDate: any;
    endDate: any;
  frequencyID: number;
  
}
export class FilterModel {
    pageNumber: number = 1;
    pageSize: number = 5;
    sortColumn: string = "";
    sortOrder: string = "";
    searchText: string = "";
    selectedOfficeLocations: Array<any>;
  }

  export class ReviewFilterModel {
    pageNumber: number = 1;
    pageSize: number = 5;
    sortColumn: string = "";
    sortOrder: string = "";
    staffId:any;
    searchText: string = "";
    selectedOfficeLocations: Array<any>;
  }

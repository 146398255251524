import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ManageQuizService } from "../../manage-quiz/manage-quiz.service";
import { AddCompanyService } from "./add-company.service";
import { UsersService } from '../../users/users.service';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CompanyService } from "../../manage-company/company.service";
import { NotifierService } from "angular-notifier";
import { MediaFilterModel, Metadata, ResponseModel } from '../../../core/modals/common-model';

@Component({
  selector: "app-add-company-modal",
  templateUrl: "./add-company-modal.component.html",
  styleUrls: ["./add-company-modal.component.css"],
})
export class AddCompanyModalComponent implements OnInit {
  companyNames: string[];
  AddForm: FormGroup;
  selectedCompanyName: string;
  loaderImage = "../../../../../assets/loader.gif";
  isDataLoading: boolean;
  isLoadingClients: any;
  filterModel: MediaFilterModel;
  CourseNameList: any;
  metaData: any;
  companyData: any;
  companyForm: any;
  YearModuleList = [
    { id: 1, type: "2019" },
    { id: 2, type: "2020" },
    { id: 3, type: "2021" },
    { id: 4, type: "2022" },
    { id: 5, type: "2023" },
    { id: 6, type: "2024" },
    { id: 7, type: "2025" },
    { id: 8, type: "2026" },
    { id: 9, type: "2027" },
    { id: 10, type: "2028" },
    { id: 11, type: "2029" },
    { id: 12, type: "2030" },
  ];
  CourseList = [
    { id: 1, type: "Staff" },
    { id: 2, type: "Executive" },
  ];
  id: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddCompanyModalComponent>,
    private addCompanyService: AddCompanyService,
    private formBuilder: FormBuilder,
    private route: Router,
    private _companyService: CompanyService,
    private notifier: NotifierService,
    private userservice: UsersService
  ) {
    this.AddForm = new FormGroup({
      Id: new FormControl(0),
      selectedCompanyName: new FormControl("", [Validators.required]),
      selectCourse: new FormControl("", [Validators.required]),
      selectYear: new FormControl("", [Validators.required]),
      CourseName: new FormControl("", [Validators.required]),
    });
  }
  heading: string = "Add Company";
  editable: boolean = false;

  ngOnInit(): void {

    this.GetDataById(this.data.id);
    // const key = String(this.data.id);
    // var id = Number(localStorage.getItem(this.data.id));
    // if (this.heading == 'Add Company') {
    //   this.GetDataById(id);
    //   this.editable = true;
    // }
    this.addCompanyService.getCompanyName().subscribe(
      (res) => {
        // console.log("this.companyNames", this.companyNames);
        this.companyNames = res.data;
      },
      (error) => {
        console.error("Error fetching company names:", error);
      }
    );
    this.isDataLoading = true;
    setTimeout(() => {
      this.isDataLoading = false;
    }, 3000);
  }

  GetDataById(id: number) {
    this._companyService.getCompanyById(id).subscribe((response: any) => {
      if (response != null) {
        this.companyData = response;
        this.editable = true;
        setTimeout(() => {
          this.AddForm.patchValue({
            Id: this.companyData.id,
            selectedCompanyName: this.companyData.companyId + ',' + this.companyData.comName,
            selectCourse: this.companyData.courseType,
            selectYear: this.companyData.year,
            CourseName: this.companyData.courseName,
          });
        }, 5000);
      }
    });

  }

  onSave(): void {
    this.isDataLoading = true;
    if (this.AddForm.valid) {
      const companyData = {
        Id: this.AddForm.controls.Id.value,
        companyId: this.AddForm.controls.selectedCompanyName.value.split(',')[0],
        CourseType: this.AddForm.controls.selectCourse.value,
        Year: this.AddForm.controls.selectYear.value,
        CourseName: this.AddForm.controls.CourseName.value,
        comName: this.AddForm.controls.selectedCompanyName.value.split(',')[1],
      };

      // console.log(companyData);
      this.isLoadingClients = true;
      if (this.data.id == null) {
        this.addCompanyService.creatCompany(companyData).subscribe((response: ResponseModel) => {
          if (response && response.statusCode == 200) {
            this.notifier.notify('success', response.message);
            this.AddForm.reset();
            this.dialogRef.close();
            this.isDataLoading = false;
          }
          else {
            this.notifier.notify('error', response.message);
            this.dialogRef.close();
          }
        });
      }
      else {
        this.addCompanyService.creatCompany(companyData).subscribe((response: ResponseModel) => {
          if (response && response.statusCode == 200) {
            this.notifier.notify('success', response.message);
            this.AddForm.reset();
            this.dialogRef.close();
            this.isDataLoading = false;
          }
          else {
            this.notifier.notify('error', response.message);
            this.dialogRef.close();
          }
        });
      }

    } else {
      console.log("Form is not valid.");
    }
  }
  courseDropdown(data: any) {
    this.filterModel = new MediaFilterModel();
    this.filterModel.CategoryType = data.source.value;
    this.getCourseList();
  }

  getCourseList() {
    this.userservice.getAllCourseList(this.filterModel).subscribe((response: ResponseModel) => {
      if (response && response.statusCode == 200) {
        this.CourseNameList = response.data;
        this.metaData = response.meta;
      }
      else {
        this.CourseNameList = [];
        this.metaData = new Metadata();
      }

    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

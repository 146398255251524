import { Component, Inject, OnInit } from '@angular/core';
import { FilterVideoModel, ResponseModel } from '../../../core/modals/common-model';
import { UsersService } from '../../users/users.service';
import { ClientsService } from '../../../client-portal/clients.service';
import { PodcastModel } from 'src/app/super-admin-portal/core/modals/common-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.css']
})
export class ViewVideoComponent implements OnInit {
  id: any;
  filterVideoModel: FilterVideoModel;
  filterVideoModell: PodcastModel;
  agencyData: any;
  videotitle: any;
  videodesc: any;
  trackdata: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ViewVideoComponent>, private userservice: UsersService, private clientService: ClientsService) {
    this.filterVideoModel = new FilterVideoModel();
    this.filterVideoModell = new PodcastModel();
  }

  ngOnInit() {
    this.id = Number(localStorage.getItem('mediaDetailsId'));
    this.getvideo();

    let A = this.data;
    // var a = 0;
    // var interval = setInterval(() => {
    //   if (a == 3) {
    //     clearInterval(interval);

    //   } else {
    //     a++
    //   }

    // }, 500);
  }

  togglePlay() {
    // Select elements here
    const video: any = document.getElementById('video');
    const videoControls: any = document.getElementById('video-controls');

    const videoWorks = !!document.createElement('video').canPlayType;
    if (videoWorks) {
      video.controls = false;
      videoControls.classList.remove('hidden');
    }

    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  }
  clickplay() {
    const playButton: any = document.getElementById('play');
    playButton.addEventListener('click', this.togglePlay);
  }
  getvideo() {
    this.agencyData = [];
    this.userservice.getAllVideo(this.filterVideoModel).subscribe((response: ResponseModel) => {
      response.data.forEach((data: any) => {
        if (data.mediaDetailsId == this.id) {
          this.agencyData = "https://letsbe-dev-test.s3.eu-west-2.amazonaws.com/" + data.mediaPath;
          this.videotitle = data.title
          this.videodesc = data.description
          this.trackvideo(this.id)
        }
      });
    });

  }
  trackvideo(id: any) {
    var videoId = id;
    this.trackdata = [];
    this.clientService.getAllVideoTracking().subscribe(data => {
      data.data.forEach(ele => {
        ele.trackduration = ele.endDuration - ele.startDuration;

        this.trackdata.push(ele);
      });
    });
  }
  playPause() {
    var myVideo: any = document.getElementById("video");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }
  playvideo() {
    var video: any = document.getElementById("video");
    video.play();

  }
  pausevideo() {
    var video: any = document.getElementById("video");

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

import { Injectable } from '@angular/core';
import { CommonService } from '../../core/services';
import { Observable } from 'rxjs';
import { DialogComponent } from 'src/app/shared/layout/dialog/dialog.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ManageQuizService {
  private getQuizDetailsURL = 'Media/GetQuizDetails';
  private getQuizDetailsByIdURL = 'Media/GetQuizDetailsById';
  private CreateQuizDetailsURL = 'Media/CreateQuiz';
  private UpdateQuizDetailsURL = 'Media/UpdateQuiz';
  private deleteQuizURL = 'Media/DeleteQuiz';
  private deleteQuizQuestionURL = 'Media/DeleteQuizQuestion';
  private deleteQuizQuestionOptionURL = 'Media/DeleteQuizQuestionOption';
  private checkQuizDetails3 = 'Media/CheckQuizDone';
  constructor(private commonService: CommonService, private dialog: MatDialog) {
  }
  getQuizDetails(SearchKey: any, pageNumber: any, pageSize: any) {
    return this.commonService.getAll(this.getQuizDetailsURL + '?SearchKey=' + SearchKey + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize, {});
  }

  creatQuizDetails(quizData: any) {
    return this.commonService.post(this.CreateQuizDetailsURL, quizData);
  }

  updateQuizDetails(quizData: any) {
    return this.commonService.post(this.UpdateQuizDetailsURL, quizData, true);
  }

  deleteQuizQuestion(quizId, quizQuestionnaireID) {
    return this.commonService.post(this.deleteQuizQuestionURL + '?QuizId=' + quizId + '&quizQuestionnaireID=' + quizQuestionnaireID, {});
  }

  deleteQuizQuestionOption(quizId, quizQuestionnaireID, questionOptionId) {
    return this.commonService.post(this.deleteQuizQuestionOptionURL + '?QuizId=' + quizId + '&quizQuestionnaireID=' + quizQuestionnaireID + '&questionOptionId=' + questionOptionId, {});
  }

  getQuizDetailsById(quizId) {
    return this.commonService.getAll(this.getQuizDetailsByIdURL + '?QuizId=' + quizId, {});
  }


  checkgetQuiz(patientId: number, quizId: number) {
    let url = `?patientId=${patientId}&quizId=${quizId}`;
    return this.commonService.getById(this.checkQuizDetails3 + url, {});
  }

  deleteQuiz(quizId) {
    return this.commonService.post(this.deleteQuizURL + '?QuizId=' + quizId, {});

  }
  public confirm(
    message: string,
    data?: Array<any>): Observable<any> {
    const modalRef = this.dialog.open(DialogComponent, { data: data, hasBackdrop: true, backdropClass: 'no-backdrop', disableClose: false, width: 'auto', minWidth: 'auto', maxWidth: 'auto', position: { bottom: '10px', right: '10px' } });
    modalRef.componentInstance.message = message;
    return modalRef.afterClosed();
  }
}

import { Component, Inject, TemplateRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Alert } from 'selenium-webdriver';
import { CompanyService } from '../manage-company/company.service';
import { companyFormsModel, CompanyModel } from '../manage-company//manage-company.model';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteCompanyAlertComponent } from '../manage-company/delete-company-alert/delete-company-alert.component';
import { ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { stat } from 'fs';
import { UsersService } from 'src/app/platform/modules/agency-portal/users/users.service';
import { FilterModel, FilterVideoModel, MediaFilterModel, Metadata, ResponseModel } from '../../core/modals/common-model';
import * as XLSX from 'xlsx';
import { BulkUploadComponent } from '../manage-company/bulk-upload/bulk-upload.component';
import { ManagePackageComponent } from '../manage-company/manage-package/manage-package.component';
import { UploadVideoComponent } from './upload-video/upload-video.component';
import { S3 } from 'aws-sdk';
import { ViewVideoComponent } from './view-video/view-video.component';
import { MediaFilterComponent } from './media-filter/media-filter.component';
import { ViewMediaComponent } from './view-media/view-media.component';
import { AudioModalComponent } from './audio-modal/audio-modal.component';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  }
};

@Component({
  selector: 'app-manage-videos',
  templateUrl: './manage-videos.component.html',
  styleUrls: ['./manage-videos.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]

})
export class ManageVideosComponent implements OnInit {
  dataexcel: Array<any>[];
  videos: any = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  organizationId: number;
  metaData: any;
  searchText: string = "";
  filterModel: MediaFilterModel;
  filterVideoModel: FilterVideoModel;
  //VideoFilterModel:VideoFilterModel;
  agencyData: any;
  animal: string;
  searchModel: { SearchKey: string, };
  s3: any;
  userId: any;
  year: any;
  selectmonth: any;
  selectmodule: any;
  CourseNameList: any;
  UserTypeList = [
    { id: 1, type: 'Staff' },
    { id: 2, type: 'Executive' }
  ];

  displayedColumns: string[] = ['Title', 'Type', 'weekId', 'Action'];

  // actionButtons: Array<any> = [
  //   { displayName: 'View', key: 'view', class: 'fa-solid fa-eye' },
  //   //{ displayName: 'Add', key: 'add', class: 'fa-solid fa-user-plus' },
  //   // { displayName: 'Package', key: 'package', class: 'fa-solid fa-clipboard' },
  //   { displayName: 'Edit', key: 'edit', class: 'fa fa-pencil' },
  //   { displayName: 'Delete', key: 'delete', class: 'fa-solid fa-trash-can' },
  //   { displayName: 'OpenVideo', key: 'OpenVideo', class: 'fa-solid fa-eye' }
  // ];
  notify: any;

  name = 'This is XLSX TO JSON CONVERTER';
  willDownload = false;
  CategoryType: string = '';
  UserType: string = '';
  CourseType: string = '';
  MonthId: number;
  videoForm: FormGroup
  constructor(

    public dialog: MatDialog,
    private notifier: NotifierService,
    private router: Router,
    private userservice: UsersService,
    private _companyService: CompanyService) {
    this.filterModel = new MediaFilterModel();
    this.filterVideoModel = new FilterVideoModel();

  }
  @ViewChild("deleteDialog", {}) deleteDialog: TemplateRef<any>;
  @ViewChild("mediaFilter", {}) mediaFilter: TemplateRef<any>;
  @ViewChild("userFormDialog", {})
  userFormDialog: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  ngOnInit() {

    this.videoForm = new FormGroup({
      TypeName: new FormControl('', [Validators.required])
    });
    //this.agencyData = [];
    this.getVideoList();
    this.dataSource.sort = this.sort;
    this.s3 = new S3({
      accessKeyId: 'AKIAXKJZXKIQ4RXO6E6X',
      secretAccessKey: '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',
      region: 'eu-west-2'
    });


  }
  // onPageOrSortChange(changeState?: any) {
  //   //this.setPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
  //   this.getVideoList();
  // }

  // onTableActionClick(actionObj?: any) {
  //   console.log("-----"+actionObj);
  //   const id = actionObj.data.mediaDetailsId;
  //   const path = actionObj.data.mediaPath;
  //   switch ((actionObj.action || '').toUpperCase()) {

  //     case 'VIEW':
  //       localStorage.setItem('heading', 'View Training');
  //       this.ViewVideo(actionObj.data);
  //       break
  //       case 'EDIT':
  //       this.editVideo(id);
  //       localStorage.setItem('headingvalue', 'Edit Training');
  //       break;
  //     case 'DELETE':
  //       this.deleteVideo(id);
  //       break
  //     case 'OPENVIDEO':
  //       this.OpenVideos(actionObj.data.mediaPath);
  //       break
  //     default:
  //       break;

  //   }
  // }

  OpenVideos(path: string) {
    const params = {
      Bucket: 'letsbe-dev-test',
      Key: path, Expires: 1500
    };

    this.s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) { console.error('Error getting video URL', err); }
      else {
        console.log('Video URL', url);
        const dialogRef = this.dialog.open(ViewVideoComponent, { data: url });

        dialogRef.afterClosed().subscribe(result => {
        });
      }

    });
  }

  EditCompany(id: number) {
    localStorage.setItem('heading', 'Edit Company');
    localStorage.setItem('id', id.toString());
    this._companyService.getById(id).subscribe((res: any) => {
    })
    // this.router.navigate(["/web/manage-podast/add-podcast/upload-video"]);
  }

  OnOff(value: number) {
    if (value == 1) {
      return true;
    }
    return false;
  }

  // applyFilter(searchText: string = '') {
  //   this.filterModel.searchText==this.searchText;
  //   if (searchText == '' || searchText.length > 2) {
  //     this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
  //     this.getVideoList();
  //   }
  // }
  // showResults(){
  //   //this.filterModel.CategoryType=this.CategoryType;
  //   this.filterModel.CourseType=this.CourseType;
  //   this.filterModel.MonthId=this.MonthId;
  //   this.getVideoList()
  // }

  getModuleId1() {
    this.selectmodule = 1;
    this.getVideoList();
  }
  getModuleId2() {
    this.selectmodule = 2;
    this.getVideoList();
  }
  getModuleId3() {
    this.selectmodule = 3;
    this.getVideoList();
  }
  getModuleId4() {
    this.selectmodule = 4;
    this.getVideoList();
  }
  getMonthId(value: any) {
    this.selectmonth = value;
    this.getVideoList();
  }
  getCoursetype(value: any) {
    this.filterModel.CategoryType = value;
    this.getCourseList();
    this.getVideoList();
  }
  getCourseId(value: any) {
    this.filterModel.CourseId = value;
    this.getVideoList();
  }

  getVideoList() {

    //this.filterModel.searchText==this.searchText;
    this.filterModel.Year = this.year;
    this.filterModel.ModuleId = this.selectmodule;
    this.filterModel.MonthId = this.selectmonth;
    this.userservice.getVideo(this.filterModel).subscribe((response: ResponseModel) => {
      if (response && response.statusCode == 200) {

        this.dataSource = new MatTableDataSource<any>();
        this.videos = response.data;
        this.dataSource = new MatTableDataSource(this.videos);
        // this.metaData = response.meta;
        //  this.dataSource = new MatTableDataSource(this.videos);
        // this.dataSource.paginator = this.paginator;
        //   this.dataSource.sort = this.sort;
        //  console.log(this.dataSource);
      }
      else {
        this.videos = [];
        this.metaData = new Metadata();
      }

      //this.metaData.pageSizeOptions = [ 10, 25, 50, 100];
    });

  }


  // getAgencyDataList() {
  //   this._companyService.getAllNoSp().subscribe((response: any) => {
  //     console.log(response)
  //     this.agencyData = (response || []);
  //     this.dataSource = response;
  //     console.log(this.dataSource);
  //   });
  // }

  // clearFilters() {
  //   this.searchText = "";
  //   this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, '');
  //   this.getVideoList();
  // }

  // setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
  //   this.filterModel.pageNumber = pageNumber;
  //   this.filterModel.pageSize = pageSize;
  //   this.filterModel.sortOrder = sortOrder;
  //   this.filterModel.sortColumn = sortColumn;
  //   this.filterModel.searchText = searchText;
  // }
  addAgency(organizationId: number = null) {
    localStorage.setItem('heading', 'Add Company');
    // this.router.navigate(["/web/manage-podcast/add-podcast/upload-video"]);
  }

  ViewVideo(dataitem: any) {
    const dialogRef = this.dialog.open(ViewMediaComponent, {
      width: '666px',
      panelClass: 'view-media',
      data: dataitem
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });

  }
  editVideo(id: number) {
    const dialogRef = this.dialog.open(UploadVideoComponent, {
      width: '666px',
      panelClass: 'edit-video',
      data: id
    });

  }

  deleteVideo(id: number) {
    // localStorage.setItem('id', id.toString());
    this._companyService.confirm(`Are you sure you want to delete video?`).subscribe((result: any) => {
      if (result == true) {
        this.userservice.deleteawsvideo(id).subscribe((response: string) => {
          this.notifier.notify('', response + this.dataSource);
          this.getVideoList();
        });
      }
      // this.openDialog('0ms', '0ms')
    }

      // openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
      //   this.dialog.open(DeleteCompanyAlertComponent, {
      //     width: '250px',
      //   }
    );
  }

  // applyFilter() {
  //   this.dataSource.filter = `${this.searchText}`;
  //   this.dataSource.filterPredicate = this.gridfilterPredicate;
  // }
  // private gridfilterPredicate = (data: any, filter: string): any => {
  //   const matchingText = (this.searchText || '').toLocaleLowerCase().trim();
  // }


  changedActive(id: number, num: number) {
    this._companyService.changeStatus(id, num).subscribe(res => {

    })
  }
  onFileChange(ev) {
    this.dataexcel = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
      this.dataexcel = jsonData.Users;
      //document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      //this.setDownload(dataString);
    }
    reader.readAsBinaryString(file);
  }

  // setDownload(data) {
  //   this.willDownload = true;
  //   setTimeout(() => {
  //     const el = document.querySelector("#download");
  //     el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
  //     el.setAttribute("download", 'xlsxtojson.json');
  //   }, 1000)
  // }

  openDialog(): void {
    const dialogRef = this.dialog.open(BulkUploadComponent, {

    });

    dialogRef.afterClosed().subscribe(result => {
      this.getVideoList();
      this.dataSource.sort = this.sort;
    });
  }
  openplanDialog(): void {
    const dialogRef = this.dialog.open(ManagePackageComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      if (localStorage.getItem('membershipid') != '') {
        this.basicplan();
      }
    });
  }
  companyFormsData: companyFormsModel = {
    id: '',
    companyName: '',
    brnNumber: '',
    taxNumber: '',
    emailId: '',
    phoneNumber: '',
    contactPerson: '',
    address: '',
    status: '',
    OraganizationId: 1,
    isLock: 1,
    planId: ''
  }

  basicplan() {
    var companyid = Number(localStorage.getItem('id'));
    this._companyService.getById(companyid).subscribe((res: any) => {
      this.companyFormsData.id = companyid;
      this.companyFormsData.companyName = res.companyName;
      this.companyFormsData.brnNumber = res.brnNumber;
      this.companyFormsData.taxNumber = res.taxNumber;
      this.companyFormsData.emailId = res.emailId;
      this.companyFormsData.phoneNumber = res.phoneNumber;
      this.companyFormsData.contactPerson = res.contactPerson;
      this.companyFormsData.address = res.address;
      this.companyFormsData.isLock = res.isLock;
      this.companyFormsData.status = res.status;
      this.companyFormsData.planId = Number(localStorage.getItem('membershipid'));
    })
    console.log(this.companyFormsData);
    var interval = setInterval(() => {
      if (this.companyFormsData.companyName != '' && this.companyFormsData.planId == Number(localStorage.getItem('membershipid'))) {
        clearInterval(interval);
        this._companyService.editCompnay(companyid, this.companyFormsData).subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.notifier.notify('success', res.message + ' company updated successfully.');
            // this.router.navigate(["/webadmin/company"]);

            var a = 1;
            var interval = setInterval(() => {
              if (a == 2) {
                clearInterval(interval);
                this.getVideoList();
              } else {
                a++;
              }

            }, 500);
            return;
          }
          this.notifier.notify('error', res.message);
        })
      }

    }, 500);

  }

  openDeleteDialog() {
    // this.userId = id;
    this.dialog.open(this.deleteDialog, {
      panelClass: 'delete-modal',
      width: '363px'
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  confirmDelete() {
    const data = {
      _id: this.userId,
    };
  }

  openDialog1(): void {
    const dialogRef = this.dialog.open(UploadVideoComponent, {
      width: '750px',
      panelClass: 'upload-video',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }
  openFilterDialog(): void {
    this.dialog.open(this.mediaFilter, {
      width: '290px',
      panelClass: 'media-filter',
    });

  }
  openViewMedia(): void {
    const dialogRef = this.dialog.open(ViewMediaComponent, {
      width: '666px',
      panelClass: 'view-media',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }
  openViewVideo(): void {
    const dialogRef = this.dialog.open(ViewVideoComponent, {
      width: '600px',
      panelClass: 'view-video',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }
  openViewAudio(): void {
    const dialogRef = this.dialog.open(AudioModalComponent, {
      width: '600px',
      panelClass: 'view-audio',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }

  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment, dp: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    dp.close();
    this.year = ctrlValue.year();
    this.getVideoList();

  }

  getCourseList() {
    this.userservice.getAllCourseList(this.filterModel).subscribe((response: ResponseModel) => {
      if (response && response.statusCode == 200) {
        this.CourseNameList = response.data;
        this.metaData = response.meta;
      }
      else {
        this.CourseNameList = [];
        this.metaData = new Metadata();
      }

    });
  }


}

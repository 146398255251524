import { Component, ViewChild, OnInit, Input, OnChanges } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { SharedService } from '../shared.service';
import { NavItem, SidebarInfo } from '../models';
import { SubDomainService } from 'src/app/subDomain.service';

@Component({
    selector: 'app-layout-sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    providers:[SubDomainService]
})

export class SidebarComponent implements OnInit, OnChanges {
    @Input() sidebarInfo: SidebarInfo;
    @ViewChild('sidenav') public sidenav: MatSidenav;
    isSideBarOpen= true;
    organizationModel:string;
    navItems: NavItem[] = [];
    constructor(private subDomainService : SubDomainService,private sharedService: SharedService) {
        this.subDomainService.subjectlogo.subscribe(res=>{
  
            if(res)
            {setTimeout(() => {
              this.organizationModel=sessionStorage.getItem("logo");
            }, 500);
      
       
      }
          });
         if(sessionStorage.getItem("logo"))
          this.organizationModel=sessionStorage.getItem("logo");
    }
    ngAfterViewChecked()
    {
      if(sessionStorage.getItem("logo"))
     {
      this.organizationModel=sessionStorage.getItem("logo");
    }
  }
    ngOnChanges(changes) {
        
        const sidebarInfo = changes.sidebarInfo || null;
        if (sidebarInfo && sidebarInfo.currentValue) {
            this.navItems = sidebarInfo.currentValue.navigations;
        }
    }

    ngOnInit() {
        if(sessionStorage.getItem("logo"))
        this.organizationModel=sessionStorage.getItem("logo");
        this.sharedService.setSidenav(this.sidenav);
        this.addremoveCustomClasses(true);
    }

    toggleSidebar(){
        this.isSideBarOpen = ! this.isSideBarOpen;
        this.addremoveCustomClasses(this.isSideBarOpen);
    }

    addremoveCustomClasses(isOpen){
       
        const elerefSidebar = document.getElementsByClassName('mat-drawer');
        const elerefContent = document.getElementsByClassName('mat-drawer-content');
        const elerefHeader = document.getElementsByClassName('main-header');

        let sidebar = elerefSidebar && elerefSidebar.length > 0 ? elerefSidebar[0] as HTMLElement : null;
        let content = elerefContent && elerefContent.length > 0 ? elerefContent[0] as HTMLElement : null;
        let header = elerefHeader && elerefHeader.length > 0 ? elerefHeader[0] as HTMLElement : null;

        if(isOpen) {
            if(sidebar){
                sidebar.classList.add('custom-nav-sidebar-open')
                sidebar.classList.remove('custom-nav-sidebar-close')
            }
            if(content){
                content.classList.add('custom-draw-content-open')
                content.classList.remove('custom-draw-content-close')
            }
            if(header){
                header.classList.add('custom-header-content-open')
                header.classList.remove('custom-header-content-close')
            }
        } else {
            if(sidebar){
                sidebar.classList.remove('custom-nav-sidebar-open')
                sidebar.classList.add('custom-nav-sidebar-close')
            }
            if(content){
                content.classList.remove('custom-draw-content-open')
                content.classList.add('custom-draw-content-close')
            }
            if(header){
                header.classList.remove('custom-header-content-open')
                header.classList.add('custom-header-content-close')
            }
        }

      
    }
}

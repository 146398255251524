import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vital-line-chart',
  templateUrl: './vital-line-chart.component.html',
  styleUrls: ['./vital-line-chart.component.css']
})
export class VitalLineChartComponent implements OnInit {
  @Input() heartLineChartData: any[];
  @Input() heartLineChartLabels: any[];
  public lineChartType: string = 'line';
  constructor() { 
    this.heartLineChartData= [];
    this.heartLineChartLabels= [];
  }

  ngOnInit() {
    console.log("lineChartData",this.heartLineChartData);
    console.log("lineChartLabels",this.heartLineChartLabels);

  }

}


export class ViewRatingModel {
    id: number;
    cunsultantName:string;
    corporateUserName:string; 
    rating: number; 
    review: string;
    description:string;
    isPublic:boolean;
}

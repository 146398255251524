import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FilterVideoModel, MediaFilterModel } from '../../../core/modals/common-model';

@Component({
  selector: 'app-media-filter',
  templateUrl: './media-filter.component.html',
  styleUrls: ['./media-filter.component.css']
})
export class MediaFilterComponent implements OnInit {

    filterModel: MediaFilterModel;
  filterVideoModel: FilterVideoModel;

  constructor(public dialogRef: MatDialogRef<MediaFilterComponent>,) {

    this.filterModel = new MediaFilterModel();
    this.filterVideoModel = new FilterVideoModel();
   }
  UserTypeList= [
    { id: 1, type:'Staff Training'  },
    { id: 2, type: 'Executive' }
  ];
  
  MediaList= [
    { id: 1, type:'Podcast'  },
    { id: 2, type: 'Video' }
  ];

 ModuleList=[
  { id: 1, type:'Module One'  },
  { id: 2, type: 'Module Two' },
  { id: 3, type:'Module Three'  },
  { id: 4, type: 'Module Four' }
 ]
 
  ngOnInit() {
  }

  // showResults(){
  //   this.filterModel.CategoryType=this.CategoryType;
  //   this.filterModel.CourseType=this.CourseType;
  //   this.filterModel.PartId=this.PartId;
  //   this.getVideoList()
  // }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

}

import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as dwv from 'dwv';
import { CommonService } from 'src/app/platform/modules/core/services';
import { environment } from 'src/environments/environment';


// get element
dwv.gui.getElement = dwv.gui.base.getElement;

// Image decoders (for web workers)
dwv.image.decoderScripts = {
  "jpeg2000": 'assets/dwv/decoders/pdfjs/decode-jpeg2000.js',
  'jpeg-lossless': 'assets/dwv/decoders/rii-mango/decode-jpegloss.js',
  'jpeg-baseline': 'assets/dwv/decoders/pdfjs/decode-jpegbaseline.js',
  "rle": 'assets/dwv/decoders/dwv/decode-rle.js'
};
@Component({
  selector: 'app-dicom-viewer',
  templateUrl: './dicom-viewer.component.html',
  styleUrls: ['./dicom-viewer.component.scss']
})
export class DicomViewerComponent implements OnInit {
  @Output() filesUploaded = new EventEmitter<{ fileName: string, file: File, data: string[] }[]>();
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild("fileUpload") fileUpload: ElementRef<HTMLInputElement>;

  uploadedFiles: { fileName: string, file: File, data: string[] }[] = [];

  filterList: string[] = ["Threshold", "Sharpen", "Sobel"];
  shapeList: { text: string, value: string }[] = [
    { text: "Arrow", value: "Arrow" },
    { text: "Ruler", value: "Ruler" },
    { text: "Angle", value: "Protractor" },
    { text: "Rectangle", value: "Rectangle" },
    { text: "Roi", value: "Roi" },
    { text: "Ellipse", value: "Ellipse" },
    { text: "Free hand", value: "FreeHand" },
  ];
  drawLineColor: string = "#ffff00";
  position: { i: number, j: number, k: number, value: number } = { i: 0, j: 0, k: 0, value: 0 };
  zoom: { scale: number, cx: number, cy: number } = { scale: 0, cx: 0, cy: 0 };
  presetNames: string[] = [];
  selectedPresetName: string = null;
  selectedColourMap: string = "plain";
  minThreshold: number = 0;
  maxThreshold: number = 255;

  colourMaps = {
    "plain": dwv.image.lut.plain,
    "invplain": dwv.image.lut.invPlain,
    "rainbow": dwv.image.lut.rainbow,
    "hot": dwv.image.lut.hot,
    "hotiron": dwv.image.lut.hot_iron,
    "pet": dwv.image.lut.pet,
    "hotmetalblue": dwv.image.lut.hot_metal_blue,
    "pet20step": dwv.image.lut.pet_20step
  };

  colourList = [
    { text: 'Plain', value: 'plain' },
    { text: 'Inverted', value: 'invplain' },
    { text: 'Rainbow', value: 'rainbow' },
    { text: 'Hot', value: 'hot' },
    { text: 'Hot iron', value: 'hotiron' },
    { text: 'Pet', value: 'pet' },
    { text: 'Hot metal blue', value: 'hotmetalblue' },
    { text: 'Pet 20 step', value: 'pet20step' },
  ];

  filters = ["Sharpen", "Sobel" /*, "Threshold"*/];

  tools = {
    Scroll: {},
    WindowLevel: {},
    ZoomAndPan: {},
    Draw: {
      options: this.shapeList.map(x => x.value),
      type: 'factory',
      events: ['draw-create', 'draw-change', 'draw-move', 'draw-delete']
    },
    Livewire: {
      events: ["draw-create", "draw-change", "draw-move", "draw-delete"]
    },
    Filter: {
      options: this.filterList,
      type: "instance",
      events: ["filter-run", "filter-undo"]
    },
    // Floodfill: {
    //   events: ["draw-create", "draw-change", "draw-move", "draw-delete"]
    // }
  };

  toolNames: { text: string, value: string }[] = [
    { text: 'Scroll', value: 'Scroll' },
    { text: 'Window level', value: 'WindowLevel' },
    { text: 'Zoom and pan', value: 'ZoomAndPan' },
    { text: 'Draw', value: 'Draw' },
    { text: 'Livewire', value: 'Livewire' },
    { text: 'Filter', value: 'Filter' },
  ];

  frameInfo: IFrameInformationVM = {
    PatientID: null,
    PatientName: null,
    PatientSex: null,
    PatientBirthDate: null,
    InstitutionName: null,
    StudyDate: null,
    StudyTime: null,
    Modality: null,
    SeriesNumber: null,
    InstanceNumber: null, // AcquisitionNumber|SamplesPerPixel|PixelRepresentation|RescaleSlope
    PatientPosition: null,
    SliceThickness: null,
    SliceLocation: null,
    Rows: null,
    Columns: null,
    ReconstructionDiameter: null,
    WindowCenter: null,
    WindowWidth: null,
    RescaleIntercept: null,
  };

  selectedTool = 'Select Tool';
  optionTool = 'Select Tool';
  selectedDrawTool = "Ruler";
  selectedFilterTool = "Sharpen";
  loadProgress = 0;
  dataLoaded = false;
  metaData: any[] = [];
  currentFrame: number = null;
  currentSlice: number = null;
  totalSlices: number = null;
  isPlaying: boolean = false;
  isShowTags: boolean = false;
  isLocalFile: boolean = false;

  private dwvApp: any;

  // drop box class name
  private dropboxClassName = 'dropBox';
  private borderClassName = 'dropBoxBorder';
  private hoverClassName = 'hover';
  fileName: string;
  filesUploaded1: any;
  uploadedFiles1: string;
  dynamicFile: File;
  fileInfo: any;
  URL: string;
  sasToken: string;
  enableSideMenuButton: boolean = false;
  showBackButton: boolean = false;
  hideAllMainMenu: boolean = false;
  token: string; //this variable will store the token value.
  fileUrl: string;
  octetFile: any;

  constructor(
    private dialogModalRef: MatDialogRef<DicomViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
  ) {
    if(data.url){
      this.isLocalFile = true;
      this.fileUrl = data.url;
    }
    else{
      this.fileUrl = data;
    }
  }

  ngOnInit() {
    // create app
    this.dwvApp = new dwv.App();

    // initialise app
    this.dwvApp.init({
      containerDivId: 'dwv',
      tools: this.tools
    });

    // handle load events
    let nReceivedError = null;
    let nReceivedAbort = null;

    this.dwvApp.addEventListener('load-start', (/*event*/) => {
      this.dataLoaded = false;
      nReceivedError = 0;
      nReceivedAbort = 0;
    });

    this.dwvApp.addEventListener('load-progress', (event) => {
      this.loadProgress = event.loaded;
    });

    this.dwvApp.addEventListener('load', (/*event*/) => {
      this.presetNames = this.dwvApp.getViewController().getWindowLevelPresetsNames();

      if (this.presetNames && this.presetNames.length > 0)
        this.selectedPresetName = this.presetNames[0];

      // set dicom tags
      this.metaData = dwv.utils.objectToArray(this.dwvApp.getMetaData());
      this.getValuesFromMetadata();

      // set the selected tool
      let selectedTool = 'Scroll';
      if (this.dwvApp.isMonoSliceData() &&
        this.dwvApp.getImage().getNumberOfFrames() === 1) {
        selectedTool = 'ZoomAndPan';
      }

      this.onChangeTool(selectedTool);

      // hide dropBox
      this.hideDropbox();

      // set data loaded flag
      this.dataLoaded = true;

      this.totalSlices = this.dwvApp.getImage().getGeometry().getSize().getNumberOfSlices() - 1;
      this.currentSlice = this.dwvApp.getViewController().getCurrentPosition().k;

      this.currentFrame = this.dwvApp.getViewController().getCurrentFrame();
    });

    this.dwvApp.addEventListener('load-end', (/*event*/) => {
      if (nReceivedError) {
        this.loadProgress = 0;
        alert('Received errors during load. Check log for details.');
      }
      if (nReceivedAbort) {
        this.loadProgress = 0;
        alert('Load was aborted.');
      }
    });

    this.dwvApp.addEventListener('error', (event) => {
      console.error(event.error);
      ++nReceivedError;
    });

    this.dwvApp.addEventListener('abort', (/*event*/) => {
      ++nReceivedAbort;
    });

    // handle key events
    this.dwvApp.addEventListener('keydown', (event) => {
      this.dwvApp.defaultOnKeydown(event);
    });

    // handle slice-change events
    this.dwvApp.addEventListener('slice-change', (event) => {
      this.currentSlice = this.dwvApp.getViewController().getCurrentPosition().k;
    });

    // handle frame-change events
    this.dwvApp.addEventListener('frame-change', (event) => {
      this.currentFrame = this.dwvApp.getViewController().getCurrentFrame();
    });

    // handlecolor-change events
    this.dwvApp.addEventListener('color-change', (event: { type: string, wc: string, ww: string }) => {
      this.frameInfo.WindowCenter = event.wc.toString();
      this.frameInfo.WindowWidth = event.ww.toString();
    });

    // handle wl-center-change events
    this.dwvApp.addEventListener('wl-center-change', (event: { type: string, wc: string, ww: string }) => {
      this.frameInfo.WindowCenter = event.wc.toString();
      this.frameInfo.WindowWidth = event.ww.toString();
    });

    // handle wl-width-change events
    this.dwvApp.addEventListener('wl-width-change', (event: { type: string, wc: string, ww: string }) => {
      this.frameInfo.WindowCenter = event.wc.toString();
      this.frameInfo.WindowWidth = event.ww.toString();
    });

    // handle position-change events
    this.dwvApp.addEventListener('position-change', (event: { type: string, i: number, j: number, k: number, value: number }) => {
      this.position = event;
    });

    // handle zoom-change events
    this.dwvApp.addEventListener('zoom-change', (event: { type: string, scale: number, cx: number, cy: number }) => {
      this.zoom = event;
      let scale = (event.scale || 0);
      scale = Math.round(scale * 100) / 100;
      this.zoom.scale = scale;
    });

    // handle window resize
    window.addEventListener('resize', this.dwvApp.onResize);

    // setup drop box
    this.setupDropbox();

    // possible load from location
    dwv.utils.loadFromUri(window.location.href, this.dwvApp);


    if (this.fileUrl) {
      //this.createFile();
      this.onUplaod1();
    }
  }

  private _onUplaod1 = () => {
    if (this.fileUrl) {
      if(this.isLocalFile){
        this.dwvApp.loadURLs([this.fileUrl], [], true);
      }
      else{
        var accessToken = localStorage.getItem("access_token")
        var _URl = environment.api_url + '/SecondOpinion/GetFile?url=' + this.fileUrl + '&token=' + accessToken;
        // load file with URL and token.
        this.dwvApp.loadURLs([_URl], [{ BusinessToken: localStorage.getItem("business_token") }], false);
        this.hideDropbox();
      }      
    }
  };

  public get onUplaod1() {
    return this._onUplaod1;
  }
  public set onUplaod1(value) {
    this._onUplaod1 = value;
  }

  onUplaod = () => {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {

      this.dwvApp.loadFiles(fileUpload.files);
      this.hideDropbox();
      this.uploadedFiles = [];
      for (let x = 0; x < fileUpload.files.length; x++) {
        this.uploadedFiles.push({
          fileName: fileUpload.files[x].name,
          file: fileUpload.files[x],
          data: []
        });
      }
      this.filesUploaded.emit(this.uploadedFiles);
      // fileUpload.value = '';
    };
    fileUpload.click();
  }

  /**
   * Handle a change tool event.
   * @param tool The new tool name.
   */
  onChangeTool = (tool: string) => {
    if (this.dwvApp) {
      this.selectedTool = tool;
      this.optionTool = tool;
      this.dwvApp.setTool(tool);

      if (tool === 'WindowLevel') {
        this.dwvApp.setWindowLevelPreset(this.selectedPresetName);
        this.dwvApp.getViewController().setColourMap(this.colourMaps[this.selectedColourMap]);
        this.showBackButton = true;
        //  this.hideAllMainMenu=true;
      }

      if (tool === 'Filter') {
        this.showBackButton = true;
        //      this.hideAllMainMenu=true;
      }

      if (tool === 'Draw') {
        this.onChangeShape(this.tools.Draw.options[0]);
        this.onChangeShape(this.selectedDrawTool);
        this.showBackButton = true;
        //    this.hideAllMainMenu=true;
      }
    }
  }

  hideBackButton() {
    this.showBackButton = false;
    this.hideAllMainMenu = false;
  }

  /**
   * Handle a change draw shape event.
   * @param shape The new shape name.
   */
  private onChangeShape = (shape: string) => {
    if (this.dwvApp && this.selectedTool === 'Draw') {
      this.dwvApp.setDrawLineColour(this.drawLineColor);
      this.dwvApp.setDrawShape(shape);
    }
  }

  /**
   * Handle a reset event.
   */
  onReset = () => {
    if (this.dwvApp) {
      this.dwvApp.deleteDraws();
      this.dwvApp.resetDisplay();
      // this.minThreshold = 0;
      // this.maxThreshold = 255;
      // this.onThreshold();
    }
  }

  onUndo = () => {
    if (this.dwvApp) {
      this.dwvApp.undo();
    }
  }

  onRedo = () => {
    if (this.dwvApp) {
      this.dwvApp.redo();
    }
  }

  /**
   * Open the DICOM tags dialog.
   */
  openTagsDialog = () => {
    // this.isShowTags = !this.isShowTags;

    const initialState = {
      metaData: this.metaData
    };

    // this.bsModalService
    //   .show(DicomMetaDataComponent, Object.assign({}, { initialState, class: 'modal-lg' }));

    // const obj = JSON.parse("{\"version\":\"0.3\",\"window-center\":128,\"window-width\":256,\"position\":{\"i\":0,\"j\":0,\"k\":0},\"scale\":1,\"scaleCenter\":{\"x\":0,\"y\":0},\"translation\":{\"x\":0,\"y\":0},\"drawings\":{\"attrs\":{\"listening\":true,\"visible\":true},\"className\":\"Layer\",\"children\":[{\"attrs\":{\"name\":\"position-group\",\"id\":\"slice-0_frame-0\",\"visible\":true},\"className\":\"Group\",\"children\":[{\"attrs\":{\"name\":\"ruler-group\",\"visible\":true,\"id\":\"vvagn6n382e\",\"draggable\":true},\"className\":\"Group\",\"children\":[{\"attrs\":{\"points\":[420,129,176,118],\"stroke\":\"#121211\",\"strokeWidth\":1.8028169014084507,\"name\":\"shape\"},\"className\":\"Line\"},{\"attrs\":{\"points\":[419.59403966200404,138.00493840645686,420.40596033799596,119.99506159354496],\"stroke\":\"#121211\",\"strokeWidth\":1.8028169014084507,\"name\":\"shape-tick0\"},\"className\":\"Line\"},{\"attrs\":{\"points\":[175.594039662004,127.0049384064564,176.405960337996,108.99506159354314],\"stroke\":\"#121211\",\"strokeWidth\":1.8028169014084507,\"name\":\"shape-tick1\"},\"className\":\"Line\"},{\"attrs\":{\"x\":176,\"y\":103,\"name\":\"label\"},\"className\":\"Label\",\"children\":[{\"attrs\":{\"fontSize\":10.816901408450704,\"fontFamily\":\"Verdana\",\"fill\":\"#121211\",\"name\":\"text\",\"text\":\"{length}\"},\"className\":\"Text\"},{\"attrs\":{\"width\":47.80570983886719,\"height\":10.816901408450704},\"className\":\"Tag\"}]}]}]}]},\"drawingsDetails\":{\"vvagn6n382e\":{\"textExpr\":\"{length}\",\"longText\":\"\",\"quant\":{}}}}");
    // new dwv.State().apply(this.dwvApp, obj);

    // this.dialog.open(TagsDialogComponent,
    //   {
    //     width: '80%',
    //     height: '90%',
    //     data: {
    //       title: 'DICOM Tags',
    //       value: this.metaData
    //     }
    //   }
    // );
  }

  // onReport = () => {
  //   const initialState = {
  //     uploadedFiles: this.uploadedFiles
  //   };

  //   this.bsModalService
  //     .show(DicomReportComponent, Object.assign({}, { initialState, class: 'modal-lg' }));
  // }

  onExport = (): void => {
    const imageData: ImageData = this.dwvApp.getImageData();
    const canvas = document.createElement("canvas");
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    const ctx = canvas.getContext("2d");
    ctx.putImageData(imageData, 0, 0);
    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.setAttribute('download', 'Download-Image.png');
    link.setAttribute('href', dataURL.replace("image/png", "image/octet-stream"));
    link.click();

    const stateData = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.dwvApp.getState()));
    const dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute("href", stateData);
    dlAnchorElem.setAttribute("download", "state.json");
    dlAnchorElem.click();
  }

  onPlay = (): void => {
    if (this.dwvApp) {
      this.dwvApp.getViewController().play();
      this.isPlaying = true;
    }
  }

  /** async createFile() {
     if (this.fileName) {
         var headers={
          'AccountName':'pacsstorage',
          'AccountKey':`08NLGiSuCvBgntwWhip42YCCxPBSxRg8wNZPiD81HLe/48TANHPK/CD9xHYA5Dwru9yhiP+nLhA70RR60/gomQ==`      
        }
       let response = await fetch(this.fileName);
       let data = await response.blob();
       let metadata = {
         type: 'image/jpeg'
       };
       let file = new File([data], "test.jpg", metadata);
       this.dynamicFile = file;
       this.onUplaod1();
     }
   }*/

  onStop = (): void => {
    if (this.dwvApp) {
      this.dwvApp.getViewController().stop();
      this.isPlaying = false;
    }
  }

  onSharpen = (): void => {
    if (this.dwvApp) {
      // this.dwvApp.restoreOriginalImage();
      new dwv.tool.filter.Sharpen(this.dwvApp).run();
    }
  }

  onThreshold = (): void => {
    if (this.dwvApp) {
      this.dwvApp.restoreOriginalImage();
      const threshold = new dwv.tool.filter.Threshold(this.dwvApp);
      threshold.activate(true);
      threshold.run({ min: this.minThreshold, max: this.maxThreshold });
    }
  }

  /* openNav() {
      document.getElementById("mySidepanel").style.width = "250px";
    }
    
    closeNav() {
      document.getElementById("mySidepanel").style.width = "0";
    }
  */
  onSobel = (): void => {
    if (this.dwvApp) {
      //this.dwvApp.restoreOriginalImage();
      new dwv.tool.filter.Sobel(this.dwvApp).run();
    }
  }

  // drag and drop [begin] -----------------------------------------------------

  /**
   * Setup the data load drop box: add event listeners and set initial size.
   */
  private setupDropbox = () => {
    // start listening to drag events on the layer container
    const layerContainer = this.dwvApp.getElement('layerContainer');

    if (layerContainer) {
      layerContainer.addEventListener('dragover', this.onDragOver);
      layerContainer.addEventListener('dragleave', this.onDragLeave);
      layerContainer.addEventListener('drop', this.onDrop);
    }

    // set the initial drop box size
    const box = this.dwvApp.getElement(this.dropboxClassName);

    if (box) {
      const size = this.dwvApp.getLayerContainerSize();
      // const dropBoxSize = 2 * size.height / 3;
      const dropBoxSize = size.height * 1.0;
      box.setAttribute('style', 'width:' + dropBoxSize + 'px;height:' + dropBoxSize + 'px');
    }
  }

  /**
   * Handle a drag over.
   * @param event The event to handle.
   */
  private onDragOver = (event: DragEvent) => {
    // prevent default handling
    event.stopPropagation();
    event.preventDefault();

    // update box border
    const box = this.dwvApp.getElement(this.borderClassName);
    if (box && box.className.indexOf(this.hoverClassName) === -1) {
      box.className += ' ' + this.hoverClassName;
    }
  }

  /**
   * Handle a drag leave.
   * @param event The event to handle.
   */
  private onDragLeave = (event: DragEvent) => {
    // prevent default handling
    event.stopPropagation();
    event.preventDefault();

    // update box class
    const box = this.dwvApp.getElement(this.borderClassName + ' hover');
    if (box && box.className.indexOf(this.hoverClassName) !== -1) {
      box.className = box.className.replace(' ' + this.hoverClassName, '');
    }
  }

  /**
   * Hide the data load drop box.
   */
  private hideDropbox = () => {
    // remove box
    const box = this.dwvApp.getElement(this.dropboxClassName);
    this.enableSideMenuButton = true;

    if (box) {
      box.parentNode.removeChild(box);
    }
  }

  /**
   * Handle a drop event.
   * @param event The event to handle.
   */
  private onDrop = (event: DragEvent) => {
    // prevent default handling
    event.stopPropagation();
    event.preventDefault();

    // load files
    this.dwvApp.loadFiles(event.dataTransfer.files);
    // hide drop box
    this.hideDropbox();
  }

  // drag and drop [end] -------------------------------------------------------

  private getValuesFromMetadata = (): void => {
    const keys = Object.keys(this.frameInfo);

    keys.forEach(key => {
      const item = (this.metaData || []).filter(x => x.name === key)[0];

      this.frameInfo[key] = 'NA';

      if (item)
        this.frameInfo[key] = item.value || 'NA';
    });
  }

  windowLevelSubType(item) {
    this.selectedColourMap = item.value;
    this.dwvApp.setWindowLevelPreset("Default0");
    this.dwvApp.getViewController().setColourMap(this.colourMaps[this.selectedColourMap]);
  }

  drawSubType(item) {
    this.selectedDrawTool = item.value;
    this.onChangeShape(this.selectedDrawTool);
  }

  // Close the dialog 
  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }

}

export interface IFrameInformationVM {
  PatientID?: string,
  PatientName?: string,
  PatientSex?: string,
  PatientBirthDate?: string,
  InstitutionName?: string,
  StudyDate?: string,
  StudyTime?: string,
  Modality?: string,
  SeriesNumber?: string,
  InstanceNumber?: string, // AcquisitionNumber|SamplesPerPixel|PixelRepresentation|RescaleSlope
  PatientPosition?: string,
  SliceThickness?: string,
  SliceLocation?: string,
  Rows?: string,
  Columns?: string,
  ReconstructionDiameter?: string,
  WindowCenter?: string,
  WindowWidth?: string,
  RescaleIntercept?: string,
}


import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
const signalR = require("@aspnet/signalr");
// import * as signalR from '@aspnet/signalr/dist/browser/signalr.js';
// import signalR from '@aspnet/signalr';

export class HubConnection {
  hubConnection: any;
  constructor() {
    //this.hubConnection = new HubConnection();
    this.createHubConnection = this.createHubConnection.bind(this);
  }

  createHubConnection = (access_token) => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.api_url}/chathub`, {
        accessTokenFactory: () => access_token,
      })
      .configureLogging(signalR.LogLevel.Error)
      .build();

    this.hubConnection.serverTimeoutInMilliseconds = 600000; // 10 minutes
    console.log("Hub Connected");
    //  return this.hubConnection.start();
    return this.hubConnection.start()
      .then(() => console.log('SignalR connection established'))
      .catch(err => console.error('Error while establishing SignalR connection:', err));
  };

  getHubConnection() {
    console.log('this.hubConnection - getHubConnection', this.hubConnection);
    //console.log(this.hubConnection);
    return this.hubConnection;
  }

  restartHubConnection() {
    console.log('restartHubConnection');

    let self = this;
    return new Promise((resolve, reject) => {
      console.log('restartHubConnection - Promise');

      if (self.isDisconnected()) {
        self.hubConnection
          .start()
          .then((hubConnection) => {
            resolve(hubConnection);
          })
          .catch((err) => {
            //console.log("signalr Error", err);
            console.log('restartHubConnection - signalr Error', err);
            reject(err);
          });
      }
    });
  }
  //   getHandler() {
  //     return this.sb;
  //   }

  isConnected() {
    console.log('hubConnection - isConnected');
    return (
      this.hubConnection && this.hubConnection.connection.connectionState === 1
    );
  }

  isDisconnected() {
    console.log('hubConnection - isDisconnected');

    return (
      this.hubConnection && this.hubConnection.connection.connectionState === 2
    );
  }

  // ConnectToServerWithUserId(userId) {
  //     return this.hubConnection.invoke('Connect', userId);
  // }
  ConnectToServerWithUserId(userId: number, roomId: number) {
    console.log('hubConnection - ConnectToServerWithUserId', userId, roomId);

    var room = this.hubConnection.invoke("Connect", userId, roomId);

    return room;
  }
  ConnectWithAccessToken() {
    console.log('hubConnection - ConnectWithAccessToken');

    var room = this.hubConnection.invoke("ConnectWithAccessToken");
    return room;
  }
  ConnectWithBussinessToken(userId: number) {
    console.log('hubConnection - ConnectWithBussinessToken', userId);

    const room = this.hubConnection.invoke("ConnectWithBussinessToken", userId)
      .then(res => {
        console.log('hubConnection - ConnectWithBussinessToken - res', res);
        return res;
      });

    console.log('hubConnection - ConnectWithBussinessToken - room', room);

    return room;
  }
}

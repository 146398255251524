import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AgencyRoutingModule } from "./agency-routing.module";
import { SharedModule } from "../../../shared/shared.module";
import { MatDialogModule, MatMenuModule } from "@angular/material";
import { AuthListComponent } from "./dashboard/auth-list/auth-list.component";
import { AgencyPermissionGuard } from "./agency_routing_permission.guard";
import { ScrollbarModule } from "ngx-scrollbar";
import { RatingModule } from 'ng-starrating';
import { ReviewsRatingsComponent } from './reviews-ratings/reviews-ratings.component';
import { ViewRatingsComponent } from './view-ratings/view-ratings.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormioModule } from "angular-formio";
import { ChartsModule } from "ng2-charts";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { PlatformMaterialModule } from "../../platform.material.module";
import { ProvideReviewRatingComponent } from "./provide-review-rating/provide-review-rating.component";
import { UsersModule } from "./users/users.module";
import { PerformanceComponent } from './performance/performance.component';
import { BarChartComponent } from "./performance/bar-chart/bar-chart.component";
import { ManagePodcastComponent } from './manage-podcast/manage-podcast.component';
import { ManageCoursesComponent } from './manage-courses/manage-courses.component';
import { AddCourseComponent } from './manage-courses/add-course/add-course.component';
import { ManageCompaniesComponent } from './manage-companies/manage-companies.component';
import { AddCompanyModalComponent } from './manage-companies/add-company-modal/add-company-modal.component';
import { ManageQuizComponent } from './manage-quiz/manage-quiz.component';
import { QuizCompaniesComponent } from './manage-quiz/quiz-companies/quiz-companies.component';
import { MembersParticipatingComponent } from './manage-quiz/members-participating/members-participating.component';
import { QuizResultComponent } from './manage-quiz/quiz-result/quiz-result.component';
import { AddNewQuizComponent } from './manage-quiz/add-new-quiz/add-new-quiz.component';
import { QuestionnaireComponent } from './manage-quiz/questionnaire/questionnaire.component';
import { ManageVideosModule } from "./manage-videos/manage-videos.module";
import { AdminRewardPointComponent } from "./manage-company/admin-reward-point/admin-reward-point.component";
import { ClientsService } from "../client-portal/clients.service";
import { DownloadCertificateComponent } from "../client-portal/reward-points/download-certificate/download-certificate.component";
@NgModule({
    imports: [
        CommonModule,
        AgencyRoutingModule,
        SharedModule,
        MatMenuModule,
        ScrollbarModule,
        RatingModule,
        MatDialogModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatDialogModule,
        PlatformMaterialModule,
        ChartsModule,
        NgxMatSelectSearchModule,
        CommonModule,
        //ScrollbarModule,
        FormioModule,
        UsersModule,
        ManageVideosModule
    ],
    providers: [AgencyPermissionGuard,ClientsService],
    entryComponents:[ViewRatingsComponent, AddCourseComponent, AddCompanyModalComponent, ProvideReviewRatingComponent,AdminRewardPointComponent,DownloadCertificateComponent],
    declarations: [
        DashboardComponent,
        AuthListComponent,
        ReviewsRatingsComponent,
        ViewRatingsComponent,
        ProvideReviewRatingComponent,
        PerformanceComponent,
        BarChartComponent,
        ManageCoursesComponent,
        AddCourseComponent,
        ManageCompaniesComponent,
        AddCompanyModalComponent,
        ManageQuizComponent,
        QuizCompaniesComponent,
        MembersParticipatingComponent,
        QuizResultComponent,
        AddNewQuizComponent,
        AdminRewardPointComponent
        // QuestionnaireComponent,
        //DiagnosisModalComponent
    ]
    //exports: [DiagnosisModalComponent],
    //entryComponents: [DiagnosisModalComponent]
})
export class AgencyPortalModule { }
